import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import { Form } from 'react-final-form';

import i18n from 'translation/i18n';
import {PRIORITIES} from 'utils/options';

import { Spacer, Button } from 'atoms';
import { TextInputField, SelectField } from 'components/global/final-form';
import { ModalContent } from 'templates';

// const activeText = {
//   false: i18n.t('inactive'),
//   true: i18n.t('active')
// };

const RequestTestForm = (props) => {
  const { onSubmit, initialValues } = props;
  const {submitting} = useSelector(state => state.tests);

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues} style={{ height: '100%'}}>
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <ModalContent>
            {!initialValues?.id &&(
              <>
                <TextInputField name="name" label={i18n.t('name')} placeholder={i18n.t('name')} />
                <Spacer height={8}/>
              </>
            )}
            <TextInputField name="service.price" label={i18n.t('test_price')}  placeholder={i18n.t('price')} />
            <Spacer height={8}/>
            <SelectField
              name="service.priority"
              label={i18n.t('test_priority')}
              options={PRIORITIES}
              placeholder={i18n.t('choose_priority')}
              disabled
            />

            <Spacer height={24}/>
            {/* <Flex itemsCenter justifyCenter>
              <Switch
                defaultChecked={values.type?.status}
                onChange={(val) => form.change('service.activate', val)}
              />
              <Spacer width={8} />
              <Text typography="paragraph16">
                {activeText[values.service?.activate]}
              </Text>
            </Flex> */}

          </ModalContent>
          <Spacer height={30}/>
          <Spacer height={1} width="100%" style={{ backgroundColor: 'var(--brand-divider-white)' }} />
          <ModalContent>
            <Button
              label={i18n.t(initialValues?.id ? 'update_test' : 'send_request')}
              disabled={submitting}
              type="submit"
            />
          </ModalContent>
        </form>
      )}
    </Form>
  );
};

RequestTestForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape(),
};

export default RequestTestForm;
