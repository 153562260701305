import React from 'react';

const SubscriptionsInfo = () => {
  return (
    <>
        Subscriptions Info
    </>
  );
};

export default SubscriptionsInfo;
