import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';

import {removeOrdersFromBatch} from 'redux/orders/actionCreators';

import {Card} from 'atoms';
import BatchOrderFilters from './BatchOrderFilters';
import BatchOrderTable from './BatchOrderTable';

const BatchOrder = () => {
  const dispatch = useDispatch();
  const {id: batchId} = useParams();
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleSelectionChange = (orderIds) => {
    const showBtn = orderIds.length > 0;
    setShowRemoveBtn(showBtn);
    setSelectedOrderIds(orderIds);
  };

  const handleRemove = () => {
    dispatch(removeOrdersFromBatch(batchId, selectedOrderIds));
  };

  return (
    <Card styles={{margin: '2rem'}}>
      <BatchOrderFilters showRemoveBtn={showRemoveBtn} onRemove={handleRemove}/>
      <BatchOrderTable onSelectionChange={handleSelectionChange}/>
    </Card>
  );
};

export default BatchOrder;
