import React from 'react';
import {Redirect, Switch, useHistory} from 'react-router-dom';
import {isPatientSignup} from 'utils/labUtils';

import routes from 'utils/routeHelpers/patient';

import PrivateRoute from './PrivateRoutes';

const PatientRoutes = () => {
  const {push} = useHistory();

  React.useEffect(() => {
    if (isPatientSignup) {
      push('/book-test');
    }
  }, []);

  return (
    <div className="patient-page">
      <Switch>
        {
          routes.map(({
            path,
            component,
          }, index) => (
            <PrivateRoute
              key={index}
              path={path}
              component={component}
            />
          ))
        }
        <Redirect to='/tests'/>
      </Switch>
    </div>
  );
};
export default PatientRoutes;
