import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {IconButton, Menu, MenuItem} from '@mui/material';

import i18n from 'translation/i18n';
import {ORDER_STATUS} from 'models';

import {Spacer, Text} from 'atoms';
import {ReactComponent as MenuIcon} from 'assets/icons/common/more-vertical.svg';
import ReasonModal from './ReasonModal';
import './styles.scss';

const OrderStatusTransition = ({
  options,
  children,
  menuPosition,
  showCancel,
  onMenuClick
}) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [showReasonModal, setShowReasonModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option) => {
    if ([ORDER_STATUS.REJECT, ORDER_STATUS.CANCEL].includes(option)) {
      setSelectedOption(option);
      setShowReasonModal(true);
    } else {
      onMenuClick({option});
    }
    handleClose();
  };

  const handleReasonClose = (reason) => {
    if (reason) {
      onMenuClick({option: selectedOption, reason});
    }
    setSelectedOption('');
    setShowReasonModal(false);
  };

  return (
    <>
      <div
        id="order-status-transition"
        aria-haspopup="true"
        aria-controls={open ? 'order-status-transition-popover' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {children || <IconButton><MenuIcon/></IconButton>}
      </div>
      <Menu
        id="order-status-transition-popover"
        className={`position-${menuPosition}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'order-status-transition'}}
      >
        {(!options && !showCancel) &&
          <Text typography="caption13" color="#BBBDC9" weight="bold">
            {i18n.t('no_further_changes')}
          </Text>
        }
        {options && (
          <div>
            <Text typography="caption13" color="#BBBDC9" weight="bold">
              {i18n.t('mark_as')}
            </Text>
            {options.map(option => (
              <MenuItem key={option} onClick={() => handleMenuClick(option)}>
                <Text typography="paragraph14" weight="bold">{i18n.t(`status_${option}`)}</Text>
              </MenuItem>
            ))}
          </div>
        )}
        {showCancel && (
          <div>
            {options && <Spacer className="divider" height={1} width="12rem"/>}
            <MenuItem className="cancel-order" onClick={() => handleMenuClick(ORDER_STATUS.CANCEL)}>
              <Text
                typography="paragraph14"
                weight="bold"
                color="var(--text-color-button-danger)"
              >
                {i18n.t('cancel_order')}
              </Text>
            </MenuItem>
          </div>
        )}
      </Menu>
      <ReasonModal
        showModal={showReasonModal}
        status={selectedOption}
        onClose={handleReasonClose}
      />
    </>
  );
};

OrderStatusTransition.defaultProps = {
  onMenuClick: () => {},
  menuPosition: 'left',
  showCancel: false
};

OrderStatusTransition.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string),
  children: PropTypes.node,
  menuPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
  showCancel: PropTypes.bool,
  onMenuClick: PropTypes.func
};

export default OrderStatusTransition;
