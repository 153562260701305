import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {Avatar} from '@mui/material';
import {DataTable, Flex, Text, Spacer} from 'atoms';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {labStatusTextColor} from 'utils/labUtils';
import TempLogo from 'assets/icons/logo/tashkhes-logo-blue.svg';
import {LabTableActions} from '.';

const LabTable = (props) => {
  const {fetching} = useSelector(state => state.labs);
  const {list, onRowClick, onActionClick} = props;

  const nameField = (name, logo) => {
    return (
      <Flex itemsCenter>
        <Avatar alt={name} src={logo || TempLogo} sx={{width: '20px', height: '20px'}}/>
        <Spacer width={8}/>
        <Text typography="paragraph14" weight="bold">
          {name}
        </Text>
      </Flex>
    );
  };

  const columns = [
    {
      field: 'name_i18n',
      headerName: i18n.t('lab_name'),
      minWidth: 250,
      flex: 1,
      renderCell: ({row, field}) =>  nameField(valueGetter(row, field), row.logo_url),
    },
    {
      field: 'cr_number',
      headerName: i18n.t('cr_number'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'admin_service_provider.0.email',
      headerName: i18n.t('email'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'admin_service_provider.0.mobile',
      headerName: i18n.t('mobile'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 150,
      renderCell: ({row, field}) => (
        <Text typography="paragraph14" weight="bold" color={labStatusTextColor[valueGetter(row, field)]}>
          {i18n.t(valueGetter(row, field))}
        </Text>
      ),
    },
    {
      field: 'actions',
      headerName: i18n.t('actions'),
      minWidth: 100,
      flex: 1,
      renderCell: ({row}) =>  <LabTableActions data={row} onOptionClick={onActionClick}/>,
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={list}
      onRowClick={onRowClick}
      loading={fetching}
    />
  );
};

LabTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
  onActionClick: PropTypes.func,
};

export default LabTable;
