import SelfTestBooking from 'components/reservation/SelfTestBooking';
import {Tests, TestDetails} from 'container/patients/Tests';

const routes = [
  {
    path: '/tests/:id',
    component: TestDetails,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/book-test',
    component: SelfTestBooking,
  },
];

export default routes;
