import React from 'react';
import i18n from 'translation/i18n';
import {BATCH_ORDER_STATUS, ORDER_STATUS} from 'models';
import {getAuthUser} from 'redux/auth/actionCreators';
import {CLINIC_LABEL, PATIENT_LABEL, PATIENT_SIGNUP_BOOKING} from 'utils/constants';
import {valueGetter} from 'utils/uiHelpers';
import {ReactComponent as LocationIcon} from 'assets/icons/login-flow/gps-gray.svg';
import {
  composeValidators,
  maxLength,
  minLength,
  required,
  number,
} from './FormValidations';

const initialPrice = {
  tax: 0,
  sub_total: 0,
  total: 0,
  discount_amount: 0
};

export const getStatusColor = (status) => {
  switch (status) {
  case ORDER_STATUS.CREATED: return 'var(--brand-warning)';
  case ORDER_STATUS.PAID: return 'var(--brand-success)';
  case ORDER_STATUS.CONFIRM: return 'var(--brand-confirm-text)';
  case ORDER_STATUS.PROCESS: return 'var(--brand-process-text)';
  case BATCH_ORDER_STATUS.DONE:
  case ORDER_STATUS.READY: return 'var(--brand-ready-text)';
  case ORDER_STATUS.REJECT:
  case ORDER_STATUS.CANCEL: return 'var(--text-color-button-danger)';
  case ORDER_STATUS.COLLECT:
  default: return 'var(--brand-collect-text)';
  }
};

export const formattedPrice = (value, currency) => {
  value = Number.isNaN(parseFloat(value)) ? 0 : value;
  const price = parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  return `${price} ${i18n.t(currency)}`;
};

export const getListForSelect = (list = []) => (
  list.map((li) => {
    return {...li, value: li.id, label: (li.name || li.name_i18n)};
  })
);

export const getDataByID = (list = [], id) => (
  list.map((li) => {
    if (li.id === id) {
      return {value: li.id, label: li.name_i18n};
    }
    return 0;
  })
);

export const getPriceFromPayments = (payments) =>
  payments.reduce((price, payment) => ({
    tax: Number(price.tax) + Number(payment.tax),
    sub_total: Number(price.sub_total) + Number(payment.sub_total),
    total: Number(price.total) + Number(payment.amount_paid),
    discount_amount: Number(price.discount_amount) + Number(payment.discount_amount),
  }), initialPrice);

export const getPricesByPriority = (list, priority) =>
  list.map((li) => li.priority === priority ? li : null).filter(Boolean);

export const isStepNotValid = (values, stepNum, stepsValidation) => {
  const validations = stepsValidation[stepNum];
  const isEmptyField = validations.map((validate) => {
    const paValue = values[validate.parent];
    if (!paValue || !paValue[validate.child]) {
      return 1;
    }
    return null;
  }).filter(Boolean);
  return isEmptyField.length > 0;
};

export const reservationNextStatus = {
  [ORDER_STATUS.CREATED]: [ORDER_STATUS.PAID],
  [ORDER_STATUS.PAID]: [ORDER_STATUS.CONFIRM]
};

export const orderNextStatus = {
  [ORDER_STATUS.REJECT]: [ORDER_STATUS.CONFIRM],
  [ORDER_STATUS.CONFIRM]: [ORDER_STATUS.COLLECT],
  [ORDER_STATUS.COLLECT]: [ORDER_STATUS.PROCESS, ORDER_STATUS.REJECT]
};

export const orderTrackerStatus = (currentStatus) => {
  switch (currentStatus) {
  case ORDER_STATUS.CANCEL:
    return [
      ORDER_STATUS.CREATED,
      ORDER_STATUS.PAID,
      ORDER_STATUS.CONFIRM,
      ORDER_STATUS.CANCEL
    ];
  case ORDER_STATUS.REJECT:
    return [
      ORDER_STATUS.CREATED,
      ORDER_STATUS.PAID,
      ORDER_STATUS.CONFIRM,
      ORDER_STATUS.COLLECT,
      ORDER_STATUS.PROCESS,
      ORDER_STATUS.REJECT
    ];
  default:
    return [
      ORDER_STATUS.CREATED,
      ORDER_STATUS.PAID,
      ORDER_STATUS.CONFIRM,
      ORDER_STATUS.COLLECT,
      ORDER_STATUS.PROCESS,
      ORDER_STATUS.READY
    ];
  }
};

export const canCancelOrder = (status) =>
  [ORDER_STATUS.CREATED, ORDER_STATUS.PAID, ORDER_STATUS.CONFIRM].includes(status);

export const getLastChars = (label, numOfChars = 2) => {
  let subString = '';
  [...Array(Number(numOfChars))].map((_, index) => {
    const last = Number(label?.length) - (Number(numOfChars) - index);
    if (last > 0) {
      subString += label?.charAt(last) || '';
    }
    return subString;
  });
  return subString;
};

export const isPatient = () => {
  const user = getAuthUser();
  return valueGetter(user, 'role_label') === PATIENT_LABEL;
};

export const isClinicUser = () => {
  const user = getAuthUser();
  return valueGetter(user, 'role_label') === CLINIC_LABEL;
};

export const isPatientSignup = localStorage.getItem(PATIENT_SIGNUP_BOOKING);

export const profileInfo = (disabled, data = {}) => {

  return {
    general: [
      {
        name: 'name_en',
        label: i18n.t('name_en'),
        placeholder: i18n.t('name_en'),
        validate: required,
        disabled,
        value: data.name_en,
      },
      {
        name: 'name_ar',
        label: i18n.t('name_ar'),
        placeholder: i18n.t('name_ar'),
        validate: required,
        disabled,
        value: data.name_ar,
      },
      {
        name: 'bank_name',
        label: i18n.t('bank_name'),
        placeholder: i18n.t('bank_name'),
        validate: required,
        disabled,
        value: data.bank_name,
      },
      {
        name: 'iban_number',
        label: i18n.t('iban'),
        placeholder: i18n.t('iban'),
        validate: required,
        disabled,
        value: data.iban_number,
      },
      {
        name: 'vat_number',
        label: i18n.t('vat_number'),
        placeholder: i18n.t('vat_number'),
        validate: composeValidators(required, number, maxLength(10), minLength(10)),
        maxLength: 10,
        disabled,
        value: data.vat_number,
      },
      {
        name: 'cr_number',
        label: i18n.t('cr_number'),
        placeholder: i18n.t('cr_number'),
        validate: composeValidators(required, number, maxLength(10), minLength(10)),
        maxLength: 10,
        disabled,
        value: data.cr_number,
      },
    ],

    location: [
      {
        name: 'address',
        label: i18n.t('address'),
        placeholder: i18n.t('address'),
        validate: required,
        disabled,
        value: data.address,
      },
      {
        name: 'location',
        label: i18n.t('location'),
        placeholder: i18n.t('location'),
        // validate: required,
        endIcon: <LocationIcon />,
        disabled,
        value: data.location,
      },
    ],

    privacy: [
      {
        name: 'admin_service_provider.0.email',
        label: i18n.t('email'),
        placeholder: i18n.t('email'),
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('email')
        },
        disabled: true,
        value: data.admin_service_provider ? data.admin_service_provider[0]?.email : '',
      },
      !data.is_admin_role && {
        name: 'admin_service_provider.0.password',
        label: i18n.t('password'),
        placeholder: i18n.t('password'),
        type: 'password',
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('password')
        },
        disabled: true,
        value: data.admin_service_provider ? data.admin_service_provider[0]?.password : '',
      },
      {
        name: 'admin_service_provider.0.mobile',
        label: i18n.t('mobile_number'),
        placeholder: i18n.t('mobile_number'),
        action: !disabled && {
          text: 'change',
          onClick: (action) => action('mobile_number')
        },
        disabled: true,
        value: data.admin_service_provider ? data.admin_service_provider[0]?.mobile : '',
      },
    ].filter(Boolean),
    lab_statistics: [
      {
        name: 'reserved_orders',
        label: i18n.t('reserved_orders'),
        placeholder: i18n.t('reserved_orders'),
        disabled,
        value: data.reserved_orders,
      },
      {
        name: 'confirmed_orders',
        label: i18n.t('confirmed_orders'),
        placeholder: i18n.t('confirmed_orders'),
        disabled,
        value: data.confirmed_orders,
      },
      {
        name: 'total_income',
        label: i18n.t('total_income'),
        placeholder: i18n.t('total_income'),
        disabled,
        value: data.total_income,
      },
    ]
  };
};

export const printPDF = content => {
  const blobURL = URL.createObjectURL(content);
  const iframe =  document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    });
  };
};

// Labs Management UI-Helpers:
export const newLabStepTitles = {
  0 : i18n.t('plan'),
  1: i18n.t('create_lab_1'),
  2: i18n.t('create_lab_2'),
  3: i18n.t('payment'),
};

export const activatingText = {
  false : i18n.t('activate'),
  true: i18n.t('deactivate'),
};

export const activatingTextColor = {
  false: 'secondary',
  true: 'danger',
};

export const labStatusTextColor = {
  pending: 'var(--brand-process-text)',
  accepted: 'var(--brand-success)',
  rejected: 'var(--brand-danger)',
  activated: 'var(--brand-success)',
  active: 'var(--brand-success)',
  deactivated: 'var(--brand-warning)',
  inactive: 'var(--brand-warning)',
};

export const labStatusTagColor = {
  pending: 'var(--brand-warning-bg-1)',
  accepted: 'var(--brand-success-bg)',
  rejected: 'var(--brand-danger-bg)',
  activated: 'var(--brand-success-bg)',
  active: 'var(--brand-success-bg)',
  deactivated: 'var(--brand-warning-bg-1)',
  inactive: 'var(--brand-warning-bg-1)',
};

export const getOtpFromValues = (values) => {
  const otp = [...Array(6)].map((ـ,i) => values[`code-${i}`]);
  return otp.join('');
};
