import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {getReservations} from 'redux/lab/actionCreators';
import {dateToQueryFormat} from 'utils/date';
import {Card} from 'atoms';
import {ListFilters} from 'components/hoc';
import {ReservationFilters} from './ReservationFilters';
import {ReservationTable} from './ReservationTable';

import './styles.scss';

export const ReservationList = () => {
  const dispatch = useDispatch();

  const loadReservations = (params = {}) => {
    dispatch(getReservations(params));
  };

  useEffect(() => {
    loadReservations();
  });

  const handleFilterChange = filter => {
    const params = {
      search: filter.search,
      status: filter.status,
      priority: filter.priority,
      from_date: filter.date ? dateToQueryFormat(filter.date) : null
    };
    loadReservations(params);
  };

  return (
    <Card styles={{margin: '2rem'}}>
      <ListFilters Wrapped={ReservationFilters} onChange={handleFilterChange}/>
      <ReservationTable/>
    </Card>
  );
};
