import React from 'react';
import {useDispatch} from 'react-redux'; // useSelector
import {useHistory} from 'react-router-dom';
import i18n from 'translation/i18n';
import {isStepNotValid, isPatient, isPatientSignup} from 'utils/labUtils';
import {LoginTemplate, ModalTemplate, ModalHeader, ModalContent} from 'templates';
import NewPatientForm from 'components/reservation/NewPatient/NewPatientForm';
import {Alert} from 'atoms';
// import {testsLoad} from 'redux/tests/actionCreators';
import {patientBookTest, patientOptionsLoad} from 'redux/patients/actionCreators';
import {setAuthUser, getAuthUser} from 'redux/auth/actionCreators';
import {PLAN_VALUE, PATIENT_SIGNUP_BOOKING} from 'utils/constants';
import {SOLLUTION} from 'models';
import TestPlan from '../../public/TestPlan';
// import {
//   resetPassword,
// } from 'redux/auth/actionCreators';

const steps = {
  1: [
    {parent: 'patient', child: 'name_en'},
    {parent: 'patient', child: 'name_ar'},
    {parent: 'patient', child: 'gender'}
  ],
  2: [
    {parent: 'patient', child: 'nationality_id'},
    {parent: 'patient', child: 'date_of_birth'},
    {parent: 'patient', child: 'address'},
    {parent: 'patient', child: 'id_type'},
    {parent: 'patient', child: 'personal_id'}
  ],
  3: [
    {parent: 'patient', child: 'occupation'},
    {parent: 'order', child: 'reason_for_testing'},
    {parent: 'order', child: 'priority'}
  ],
  4: [],
  5: [{parent: 'appointment', child: 'time'}],
};

const SelfTestBooking = () => {
  const dispatch = useDispatch();
  const {location, push} = useHistory();

  const {state} = location;
  const [activeStep, setActiveStep] = React.useState(1);
  const [showAlert, setShowAlert] = React.useState(false);

  const onSubmit = (values) => {
    setShowAlert(false);
    const isEmptyField = isStepNotValid(values, activeStep, steps);
    if (isEmptyField) {
      setShowAlert(true);
      return false;
    }
    if (activeStep < 5) {
      return setActiveStep(activeStep + 1);
    }
    return dispatch(patientBookTest(values))
      .then(({action}) => {
        const userData = getAuthUser();
        setAuthUser({...userData, ...action.payload.data.order.patient_detail.user});
        setActiveStep(1);
        if (isPatientSignup) {
          localStorage.removeItem(PATIENT_SIGNUP_BOOKING);
          push('/tests');
          window.location.reload();
        }
        push('/tests');
      });
  };

  const handleOnPlanChanged = (plan) => {
    if (plan.value === SOLLUTION.PCR_SOLUTION) {
      setActiveStep(1);
    }
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    if (state?.step) {
      setActiveStep(state.step);
    }
    if (isPatient() && !localStorage.getItem(PLAN_VALUE)) setActiveStep(0);
    dispatch(patientOptionsLoad());
  }, []);

  return (
    <LoginTemplate logo={<span/>}>
      {(activeStep <= 0) ?
        (<TestPlan onDone={handleOnPlanChanged}/>)
        :
        (
          <ModalTemplate noPadding>
            <ModalContent>
              <ModalHeader title={i18n.t('book_test')} onBack={activeStep === 1 ? null : () => onBack()}/>
            </ModalContent>
            <NewPatientForm onSubmit={onSubmit} activeStep={activeStep}/>
          </ModalTemplate>
        )}
      <Alert
        message={i18n.t('all_fields_are_required')}
        variant="danger"
        placement="bottom"
        show={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </LoginTemplate>
  );
};

export default SelfTestBooking;
