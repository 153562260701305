import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import {Tag, Text} from 'atoms';

import {getStatusColor} from 'utils/labUtils';
import {duration} from 'utils/date';
import i18n from 'translation/i18n';
import './styles.scss';

export const NewRequests = () => {
  const {push} = useHistory();
  const [orders, setOrders] = useState([]);
  const {statistics} = useSelector(state => state.lab);

  useEffect(() => {
    const value = statistics?.new_orders || [];
    setOrders(value);
  }, [statistics]);

  return (
    <>
      <Text typography="subtitle" weight="bold">{i18n.t('overview_requests_title')}</Text>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('overview_requests_subtitle')}
        </Text>
        <Text
          typography="paragraph14"
          color="var(--brand-text-primary)"
          style={{fontWeight: 600}}
          onClick={() => push('/reservations')}
        >
          {i18n.t('view_all')}
        </Text>
      </Box>
      <TableContainer className="new-requests-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t('patient')}</TableCell>
              <TableCell>{i18n.t('tests')}</TableCell>
              <TableCell>{i18n.t('status')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow
                key={index}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell>
                  <Text typography="paragraph14" weight="bold">{order.patient_detail?.name}</Text>
                  <Text typography="caption12" weight="light" color="#C5C7CD">{order.patient_detail?.mobile}</Text>
                </TableCell>
                <TableCell>
                  <Text typography="paragraph14" weight="bold">{order.service.name}</Text>
                  <Text typography="caption12" weight="light" color="#C5C7CD">{duration(order.updated_at)}</Text>
                </TableCell>
                <TableCell>
                  <Tag text={i18n.t(`status_${order.status}`)} color={getStatusColor(order.status)}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

