import React from 'react';
import PropTypes from 'prop-types';
import FieldInfoCard from './FieldInfoCard';

const FieldInfoCardList = ({list, actionCallback, ...rest}) => {
  return (
    list?.map((li, index) => (
      <FieldInfoCard
        key={index} 
        name={li.name} 
        label={li.label}
        placeholder={li.placeholder} 
        action={li.action || {}}
        disabled={li.disabled}
        endIcon={li.endIcon}
        validate={li.validate}
        maxLength={li.maxLength}
        type={li.type ? li.type : 'text'}
        actionCallback={actionCallback}
        value={li.value}
        {...rest}
      />
    ))
  );
};

FieldInfoCardList.propTypes = {
  list: PropTypes.arrayOf(Object),
  actionCallback: PropTypes.func,
};

export default FieldInfoCardList;
