import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Orders, SingleOrderDetails, BatchOrderDetails} from 'container/labs/Orders';

const OrdersRoutes = () => (
  <Switch>
    <Route path="/orders/batch/:id" exact component={BatchOrderDetails}/>
    <Route path="/orders/batch" exact component={Orders}/>
    <Route path="/orders/single/:id" exact component={SingleOrderDetails}/>
    <Route path="/orders/single" exact component={Orders}/>
    <Redirect to="/orders/single"/>
  </Switch>
);

export default OrdersRoutes;
