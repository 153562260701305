import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Profile from 'container/labs/Profile';

const ProfileRoutes = () => (
  <Switch>
    <Route path="/lab-profile" component={Profile}/>
  </Switch>
);

export default ProfileRoutes;
