import React from 'react';
import {useSelector} from 'react-redux';

import {InfoCard} from 'templates';
import {Grid} from '@mui/material';
import {getAuthUser} from 'redux/auth/actionCreators';
import {USER_ROLE} from 'models';
import styles from '../styles.module.css';

const InfoListCards = () => {
  const {service_show} = useSelector(state => state.tests);
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const service = isAdmin ? service_show : service_show.service;

  const liInfo = [
    {label: 'test_name', value: service?.name},
    !isAdmin && {label: 'test_code', value: service?.code},
    isAdmin && {label: 'priority', value: service_show?.name},
    service_show.amount && {label: 'test_price', value: service_show?.amount},
    !isAdmin && {label: 'test_type', value: service?.test_type},
    !isAdmin && {label: 'reference_range', value: service?.reference_range},
    !isAdmin &&  {label: 'type_of_specimen', value: service?.specimen_type},
  ].filter(Boolean);

  return (
    <Grid container spacing='20px' className={styles.infoCardsContainer}>
      {liInfo.map((li, index) => (
        <Grid item key={index} xs={4}>
          <InfoCard titleKey={li.label} value={li.value} key={index}/>
        </Grid>
      ))}
    </Grid>
  );
};

export default InfoListCards;
