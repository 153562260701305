import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import PropTypes from 'prop-types';
import {IconButton} from '@mui/material';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor, orderNextStatus} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {ORDER_STATUS, TEST_RESULT} from 'models';
import {getSingleOrders, rejectOrder, sendOrderResult, updateOrderStatus} from 'redux/orders/actionCreators';

import {DataTable, Flex, Tag, Text} from 'atoms';
import OrderStatusTransition from 'components/global/OrderStatusTransition';
import {ReactComponent as Share} from 'assets/icons/common/share.svg';

const SingleOrderTable = ({onSelectionChange}) => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {singleOrders, fetching} = useSelector(state => state.orders);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleMenuClick = (orderId, {option, reason}) => {
    if (option === ORDER_STATUS.REJECT) {
      dispatch(rejectOrder(orderId, reason))
        .then(() => dispatch(getSingleOrders()));
    } else {
      dispatch(updateOrderStatus(orderId, option))
        .then(() => dispatch(getSingleOrders()));
    }
  };

  const handleSendResult = (event, orderId) => {
    dispatch(sendOrderResult(orderId));
    event.stopPropagation();
  };

  const handleCellClick = ({field}, event) => {
    if (field === '__check__') {
      event.stopPropagation();
    }
  };

  const handleRowClick = ({id}) => {
    push(`/orders/single/${id}`);
  };

  const handleSelectionModelChange = (orderIds) => {
    setSelectedStatus('');
    if (orderIds.length) {
      const {status} = singleOrders.find(order => order.id === orderIds[0]);
      setSelectedStatus(status);
    }
    setSelectedOrderIds(orderIds);
  };

  const handleRowSelectable = ({row}) => {
    if (!selectedStatus) {
      return true;
    }
    return row.status === selectedStatus;
  };

  useEffect(() => {
    onSelectionChange(selectedOrderIds, selectedStatus);
  }, [selectedOrderIds]);

  const columns = [
    {
      field: 'patient_detail.name',
      headerName: i18n.t('patient'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'patient_detail.user.mobile',
      headerName: i18n.t('phone'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 120,
      flex: 1
    },
    {
      field: 'service.name',
      headerName: i18n.t('test'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'patient_detail.priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'appointment.time',
      headerName: i18n.t('date'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => format(value, DISPLAY_DATE_FORMAT)
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'test_detail.overall_result',
      headerName: i18n.t('results'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => {
        if (!value) return null;
        const color = value === TEST_RESULT.PENDING ? '--brand-text-black-opacity-50' : '--brand-text-black';
        return <Text typography="paragraph14" weight="bold" color={`var(${color})`}>{i18n.t(`result_${value}`)}</Text>;
      }
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({row}) => (
        <Flex justifyEnd flexGrowEqual>
          {row.status === ORDER_STATUS.READY && (
            <IconButton onClick={e => handleSendResult(e, row.id)}>
              <Share/>
            </IconButton>
          )}
          <OrderStatusTransition
            options={orderNextStatus[row.status]}
            onMenuClick={payload => handleMenuClick(row.id, payload)}
          />
        </Flex>
      )
    },
  ];

  return (
    <DataTable
      checkboxSelection
      columns={columns}
      rows={singleOrders}
      loading={fetching}
      isRowSelectable={handleRowSelectable}
      onCellClick={handleCellClick}
      onRowClick={handleRowClick}
      onSelectionModelChange={handleSelectionModelChange}
    />
  );
};

SingleOrderTable.propTypes = {
  onSelectionChange: PropTypes.func
};

export default SingleOrderTable;
