import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';

import {getPatientOrder} from 'redux/orders/actionCreators';

import {PatientOrderInfo, OrderSummary} from 'components/orders';

const TestDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientOrder(id));
  });

  return (
    <Grid container spacing={4} p={4}>
      <Grid item xs={9}><PatientOrderInfo/></Grid>
      <Grid item xs={3}><OrderSummary/></Grid>
    </Grid>
  );
};

export default TestDetails;
