import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {ORDER_STATUS} from 'models';
import {createBatchOrder, getSingleOrders, rejectOrder, updateOrderStatus} from 'redux/orders/actionCreators';
import {dateToQueryFormat} from 'utils/date';

import {Alert, Card, Text} from 'atoms';
import {ListFilters} from 'components/hoc';
import SingleOrderFilters from './SingleOrderFilters';
import SingleOrderTable from './SingleOrderTable';

const SingleOrderList = () => {
  const dispatch = useDispatch();
  const [showAlert, setShowAlert] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);

  const handleFilterChange = filter => {
    const params = {
      search: filter.search,
      priority: filter.priority,
      result: filter.result,
      status: filter.status,
      from_date: filter.date ? dateToQueryFormat(filter.date) : null
    };
    dispatch(getSingleOrders(params));
  };

  const handleSelectionChange = (orderIds, status) => {
    setSelectedStatus(status);
    setSelectedOrderIds(orderIds);
  };

  const handleChangeStatus = ({option, reason}) => {
    const promises = selectedOrderIds.map(orderId => {
      if (option === ORDER_STATUS.REJECT) {
        return dispatch(rejectOrder(orderId, reason));
      }
      return dispatch(updateOrderStatus(orderId, option));
    });
    Promise.all(promises)
      .then(() => dispatch(getSingleOrders()));
  };

  const handleCreateBatch = () => {
    dispatch(createBatchOrder(selectedOrderIds))
      .then(() => setShowAlert(true));
  };

  useEffect(() => {
    dispatch(getSingleOrders());
  }, []);

  return (
    <Card styles={{margin: '2rem'}}>
      <ListFilters
        selectedStatus={selectedStatus}
        Wrapped={SingleOrderFilters}
        onChange={handleFilterChange}
        onChangeStatus={handleChangeStatus}
        onCreateBatch={handleCreateBatch}
      />
      <SingleOrderTable onSelectionChange={handleSelectionChange}/>
      <Alert
        customMessage={
          <Text typography="paragraph14">
            {i18n.t('batch_created')},
            <a href="/orders/batch" style={{marginLeft: '0.5rem', color: 'var(--brand-text-black)'}}>
              {i18n.t('go_to_batches')}
            </a>
          </Text>
        }
        variant="success"
        placement="top"
        show={showAlert}
        onClose={() => setShowAlert(false)}
      />
    </Card>
  );
};

export default SingleOrderList;
