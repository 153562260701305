const Overview = {
  // *
      
  // A
  additional_info: 'Additional info',

  // B
  bank_name: 'Bank name',
  
  // C
  change_number: 'Change number',
  current_password: 'Current password', 
  change_password: 'Change password',
  change_email: 'Change email',

  // D
      
  // E
  edit_lab_info: 'Edit lab info',
  enter_your_new_number: 'Enter your new number',
  enter_your_password: 'Enter your password',
  enter_your_email: 'Enter your email',

  // F
      
  // G
  general_info: 'General info',
  
  // H
      
  // I
      
  // J
      
  // K
      
  // L
  lab_dirictor_name: 'Lab dirictor name',
  location_info: 'Location info',
  lab_profile: 'Lab profile',

  // M
  mobile_number: 'Mobile number',
      
  // N
  new_number: 'New number',

  // O
      
  // P
  privacy_info:  'Privacy info',
  profile: 'Profile',

  // Q
      
  // R
  re_enter_new_password: 'Re-enter new password',
  
  // S
  sample_of_printed_paper: 'Sample of printed paper',
  
  // T
      
  // U
  upload_signature: 'Upload signature',

  // V
      
  // W
      
  // X
      
  // Y
      
  // Z
      
};
      
export default Overview;
      