export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_PENDING = 'GET_LOCATION_PENDING';
export const GET_LOCATION_FULFILLED = 'GET_LOCATION_FULFILLED';
export const GET_LOCATION_REJECTED = 'GET_LOCATION_REJECTED';

export const REGISTER_LAB = 'REGISTER_LAB';
export const REGISTER_LAB_PENDING = 'REGISTER_LAB_PENDING';
export const REGISTER_LAB_FULFILLED = 'REGISTER_LAB_FULFILLED';
export const REGISTER_LAB_REJECTED = 'REGISTER_LAB_REJECTED';

export const ADMIN_LOGIN = 'ADMIN_LOGIN';
export const ADMIN_LOGIN_PENDING = 'ADMIN_LOGIN_PENDING';
export const ADMIN_LOGIN_FULFILLED = 'ADMIN_LOGIN_FULFILLED';
export const ADMIN_LOGIN_REJECTED = 'ADMIN_LOGIN_REJECTED';

export const LAB_LOGIN = 'LAB_LOGIN';
export const LAB_LOGIN_PENDING = 'LAB_LOGIN_PENDING';
export const LAB_LOGIN_FULFILLED = 'LAB_LOGIN_FULFILLED';
export const LAB_LOGIN_REJECTED = 'LAB_LOGIN_REJECTED';

export const PATIENT_SIGNUP = 'PATIENT_SIGNUP';
export const PATIENT_SIGNUP_PENDING = 'PATIENT_SIGNUP_PENDING';
export const PATIENT_SIGNUP_FULFILLED = 'PATIENT_SIGNUP_FULFILLED';
export const PATIENT_SIGNUP_REJECTED = 'PATIENT_SIGNUP_REJECTED';

export const CLINIC_REGISTER = 'CLINIC_REGISTER';
export const CLINIC_REGISTER_PENDING = 'CLINIC_REGISTER_PENDING';
export const CLINIC_REGISTER_FULFILLED = 'CLINIC_REGISTER_FULFILLED';
export const CLINIC_REGISTER_REJECTED = 'CLINIC_REGISTER_REJECTED';

export const PATIENT_LOGIN = 'PATIENT_LOGIN';
export const PATIENT_LOGIN_PENDING = 'PATIENT_LOGIN_PENDING';
export const PATIENT_LOGIN_FULFILLED = 'PATIENT_LOGIN_FULFILLED';
export const PATIENT_LOGIN_REJECTED = 'PATIENT_LOGIN_REJECTED';

export const VERIFY_LOGIN = 'VERIFY_LOGIN';
export const VERIFY_LOGIN_PENDING = 'VERIFY_LOGIN_PENDING';
export const VERIFY_LOGIN_FULFILLED = 'VERIFY_LOGIN_FULFILLED';
export const VERIFY_LOGIN_REJECTED = 'VERIFY_LOGIN_REJECTED';

export const RESEND_CODE = 'RESEND_CODE';
export const RESEND_CODE_PENDING = 'RESEND_CODE_PENDING';
export const RESEND_CODE_FULFILLED = 'RESEND_CODE_FULFILLED';
export const RESEND_CODE_REJECTED = 'RESEND_CODE_REJECTED';

export const GET_RESET_EMAIL = 'GET_RESET_EMAIL';
export const GET_RESET_EMAIL_PENDING = 'GET_RESET_EMAIL_PENDING';
export const GET_RESET_EMAIL_FULFILLED = 'GET_RESET_EMAIL_FULFILLED';
export const GET_RESET_EMAIL_REJECTED = 'GET_RESET_EMAIL_REJECTED';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_FULFILLED = 'RESET_PASSWORD_FULFILLED';
export const RESET_PASSWORD_REJECTED = 'RESET_PASSWORD_REJECTED';

export const LOGGED_USER = 'LOGGED_USER';
export const LOGGED_USER_PENDING = 'LOGGED_USER_PENDING';
export const LOGGED_USER_FULFILLED = 'LOGGED_USER_FULFILLED';
export const LOGGED_USER_REJECTED = 'LOGGED_USER_REJECTED';

export const REMOVE_AUTH_USER = 'REMOVE_AUTH_USER';
