const Tests = {
  // *

  // A
  all_tests: 'جميع الفحوصات',
  activation: 'تفعيل',
  active: 'مفعل',
  add_card: 'إضافة بطاقة',
  apple_pay: 'آبل بي',

  // B
  book_test: 'حجز فحص',

  // C
  choose_your_test: 'اختر نوع الفحص', 

  // D

  // E

  // F

  // G

  // H

  // I
  inactive: 'غير مفعل',

  // J

  // K

  // L

  // M
  mada: 'مدى',

  // N

  // O

  // P
  price: 'السعر',
  price_per_patient: 'السعر لكل مريض',

  // Q

  // R
  request_a_test: 'طلب فحص',
  request_test: 'طلب فحص',
  reference_range: 'المرجعيه',

  // S
  search_by_test_name: 'البحث بإسم الفحص',
  send_request: 'ارسال الطلب',
  sat: 'SAT',
  summary: 'عام',

  // T
  tests: 'الفحوصات',
  tests_list_subtitle: 'عن الفحوصات',
  test_name: 'اسم الفحص',
  test_type: 'نوع الفحص',
  test_price: 'سعر الفحص',
  test_priority: 'اولوية الاختبار',
  type: 'النوع',
  test_information: 'معلومات الفحص',
  test_code: 'كود الفحص',
  type_of_specimen: 'نوع العينه',
  test_description: 'وصف الفحص',
  test_status: 'حالة الفحص',
  transaction: 'معاملة',
  test_results_has_not_generated_yet: 'The test results has not generated yet!',

  // U
  update_test: 'تحديث الفحص',
  update_price: 'تحديث السعر',

  // V

  // W

  // X

  // Y

  // Z

};

export default Tests;
