import React from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {getBatchOrders, updateBatchOrderResult} from 'redux/orders/actionCreators';

import {DataTable, Tag} from 'atoms';
import OrderResultTransition from 'components/global/OrderResultTransition';

const BatchOrderListTable = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {batchOrders, fetching} = useSelector(state => state.orders);

  const handleMenuClick = (batchId, result) => {
    dispatch(updateBatchOrderResult(batchId, result))
      .then(() => {
        dispatch(getBatchOrders());
      });
  };

  const handleRowClick = ({id}) => {
    push(`/orders/batch/${id}`);
  };

  const columns = [
    {
      field: 'serial_number',
      headerName: i18n.t('batch'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => `${i18n.t('batch', {batchNumber: value})}`
    },
    {
      field: 'created_at',
      headerName: i18n.t('created_at'),
      minWidth: 150,
      flex: 1,
      renderCell: ({value}) => format(value, DISPLAY_DATE_FORMAT)
    },
    {
      field: 'created_by.name_i18n',
      headerName: i18n.t('created_by'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'order_count',
      headerName: i18n.t('no_of_patients'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({row}) => (
        <OrderResultTransition
          menuPosition="left"
          result={row.result}
          status={row.status}
          onMenuClick={(result) => handleMenuClick(row.id, result)}
        />
      )
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={batchOrders}
      loading={fetching}
      onRowClick={handleRowClick}
    />
  );
};

export default BatchOrderListTable;
