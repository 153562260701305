import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import i18n from 'translation/i18n';
import {DataTable, Button, Text} from 'atoms';
import {format} from 'utils/date';
import {valueGetter} from 'utils/uiHelpers';
import {labStatusTextColor} from 'utils/labUtils';
import {SUBSCRIPTION_STATUS} from 'models';

const SubscriptionTable = (props) => {
  const {fetching} = useSelector(state => state.labs);
  const {list, onRowClick} = props;

  const columns = [
    {
      field: 'service_provider.name_i18n',
      headerName: i18n.t('lab_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'subscription.name',
      headerName: i18n.t('current_subscription'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 250,
      flex: 1,
      renderCell: ({row, field}) => (
        <Text typography="paragraph14" weight="bold" color={labStatusTextColor[valueGetter(row, field)]}>
          {i18n.t(valueGetter(row, field))}
        </Text>
      ),
    },
    {
      field: 'subscription.duration',
      headerName: i18n.t('duration'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'expiry',
      headerName: i18n.t('expire_date'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => format(valueGetter(row, field), 'dd MMM - yyyy')
    },
    {
      field: 'actions',
      headerName: i18n.t('actions'),
      minWidth: 150,
      renderCell: ({row}) => {
        return (
          row.status === SUBSCRIPTION_STATUS.INACTIVE
            ? <Button id="renew_subscription" label={i18n.t('renew')} variant="secondary" size="small"/>
            : <span />
        );
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={list}
      onRowClick={onRowClick}
      loading={fetching}
    />
  );
};

SubscriptionTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
};

export default SubscriptionTable;
