import React from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import {ORDER_STATUS} from 'models';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor, orderNextStatus} from 'utils/labUtils';
import {DISPLAY_DATE_TIME_FORMAT, format} from 'utils/date';
import {getOrder, rejectOrder, updateOrderResult, updateOrderStatus} from 'redux/orders/actionCreators';

import {Flex, Tag, Text} from 'atoms';
import {InfoCard, InfoCards} from 'templates';
import OrderStatusTransition from 'components/global/OrderStatusTransition';
import OrderResultTransition from 'components/global/OrderResultTransition';

const OrderTestInfo = ({orderInfo}) => {
  const dispatch = useDispatch();
  const orderId = valueGetter(orderInfo, 'id');
  const status = valueGetter(orderInfo, 'status');
  const result = valueGetter(orderInfo, 'test_detail.overall_result');

  const items = [
    {key: 'reservation_id', value: valueGetter(orderInfo, 'reservation_id')},
    {key: 'priority', value: i18n.t(valueGetter(orderInfo, 'patient_detail.priority'))},
    {key: 'test_name', value: valueGetter(orderInfo, 'service.name')},
    {key: 'reservation_date', value: format(valueGetter(orderInfo, 'appointment.time'), DISPLAY_DATE_TIME_FORMAT)}
  ];

  const handleResultChange = (option) => {
    dispatch(updateOrderResult(orderId, option))
      .then(() => {
        dispatch(getOrder(orderId));
      });
  };

  const handleStatusChange = ({option, reason}) => {
    if (option === ORDER_STATUS.REJECT) {
      dispatch(rejectOrder(orderId, reason))
        .then(() => dispatch(getOrder(orderId)));
    } else {
      dispatch(updateOrderStatus(orderId, option))
        .then(() => dispatch(getOrder(orderId)));
    }
  };

  return (
    <>
      <Text className="section-title" typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t('test_information')}
      </Text>
      <Grid container px={3} py={2} spacing={3}>
        <InfoCards items={items}/>
        <Grid item xs={4}>
          <InfoCard titleKey="result" isCustom>
            <Flex itemsCenter>
              <Text typography="paragraph16" weight="bold">
                {result ? i18n.t(`result_${result}`) : '—'}
              </Text>
              <OrderResultTransition
                result={result}
                status={status}
                onMenuClick={handleResultChange}
              >
                <Text
                  style={{marginLeft: '1.5rem'}}
                  typography="caption13"
                  weight="bold"
                  color="var(--brand-text-primary)"
                >
                  {i18n.t('change')}
                </Text>
              </OrderResultTransition>
            </Flex>
          </InfoCard>
        </Grid>
        <Grid item xs={4}>
          <InfoCard titleKey="status" isCustom>
            <Flex itemsCenter>
              <Tag text={i18n.t(`status_${status}`)} color={getStatusColor(status)}/>
              <OrderStatusTransition
                options={orderNextStatus[status]}
                menuPosition="right"
                onMenuClick={handleStatusChange}
              >
                <Text
                  style={{marginLeft: '1.5rem'}}
                  typography="caption13"
                  weight="bold"
                  color="var(--brand-text-primary)"
                >
                  {i18n.t('change')}
                </Text>
              </OrderStatusTransition>
            </Flex>
          </InfoCard>
        </Grid>
      </Grid>
    </>
  );
};

OrderTestInfo.propTypes = {
  orderInfo: PropTypes.shape()
};

export default OrderTestInfo;
