import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  profile: {}
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.PROFILE_SHOW_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.PROFILE_UPDATE_PENDING:
  case actions.CHANGE_EMAIL_PENDING:
  case actions.CHANGE_MOBILE_PENDING:
  case actions.VERIFY_CHANGE_MOBILE_PENDING:
  case actions.CHANGE_PASSWORD_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.PROFILE_SHOW_FULFILLED: {
    return {
      ...state,
      profile: action.payload.data.profile,
      fetching: false,
    };
  }

  case actions.PROFILE_UPDATE_FULFILLED: 
  case actions.CHANGE_EMAIL_FULFILLED:
  case actions.CHANGE_MOBILE_FULFILLED:
  case actions.VERIFY_CHANGE_MOBILE_FULFILLED:
  case actions.CHANGE_PASSWORD_FULFILLED: {
    Toastr.success(action.payload.data.message);

    return {
      ...state,
      data: action.payload.data,
      submitting: false,
    };
  }

  case actions.PROFILE_SHOW_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    
    return {
      ...state,
      fetching: false,
    };

  case actions.PROFILE_UPDATE_REJECTED:
  case actions.CHANGE_EMAIL_REJECTED:
  case actions.CHANGE_MOBILE_REJECTED:
  case actions.VERIFY_CHANGE_MOBILE_REJECTED:
  case actions.CHANGE_PASSWORD_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    
    return {
      ...state,
      submitting: false,
    };
    
  default:
    return state;
  }
}