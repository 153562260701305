import React from 'react';
import {Box} from '@mui/material';
import {DataGrid} from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import styles from './DataTable.module.css';

const DataTable = ({className, columns, rows, pageSize, ...rest}) => {
  return (
    <Box height="calc(100vh - 15rem)" width="100%">
      <DataGrid
        className={clsx(styles.dataTable, className)}
        classes={styles}
        columns={columns}
        rows={rows}
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableSelectionOnClick
        rowHeight={70}
        pageSize={pageSize}
        {...rest}
      />
    </Box>
  );
};

DataTable.defaultProps = {
  pageSize: 25,
  checkboxSelection: false
};

DataTable.propTypes = {
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.shape()),
  rows: PropTypes.arrayOf(PropTypes.shape()),
  pageSize: PropTypes.number,
  checkboxSelection: PropTypes.bool
};

export default DataTable;
