import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import i18n from 'translation/i18n';
import {updateAdminServiceProviders} from 'redux/labs/actionCreators';
import {Flex, Button, Spacer} from 'atoms';
import {activatingText, activatingTextColor} from 'utils/labUtils';
import {LAB_STATUS, ACTIVE_STATUS} from 'models';

const LabDetailsActions = (props) => {
  const dispatch = useDispatch();
  const {data, onDone} = props;
  const [activeValue, setActiveValue] = React.useState(false);

  const acceptLab = () => { 
    dispatch(updateAdminServiceProviders({status:  LAB_STATUS.ACTIVATED}, data.id))
      .then(() => onDone());
  };
  
  const rejectLab = () => { 
    dispatch(updateAdminServiceProviders({status: LAB_STATUS.REJECTED}, data.id))
      .then(() => onDone());
  };

  // const deleteLab = () => { };

  const handleActivation = (type) => {
    const status = type.activated ? LAB_STATUS.ACTIVATED : LAB_STATUS.DEACTIVATED;
    dispatch(updateAdminServiceProviders({status}, data.id))
      .then(() => {
        setActiveValue(type.activated);
        return onDone();
      });
  };

  React.useEffect(() => {
    if(data.status !== undefined){
      setActiveValue(data.status === ACTIVE_STATUS.ACTIVATED);
    }
  }, [data.status]);

  return (
    <Flex>
      {data.status && (
        <>
          {data?.status === LAB_STATUS.PENDING && (
            <Flex justifyBetween>
              <Button
                size="small"
                label={i18n.t('accept')}
                variant="secondary"
                onClick={() => acceptLab()}
              />
              <Spacer width={16}/>
              <Button
                size="small"
                label={i18n.t('reject')}
                variant="danger"
                onClick={() => rejectLab()}
              />
            </Flex>
          )}
          <Spacer width={8}/>
          {(![LAB_STATUS.PENDING, LAB_STATUS.REJECTED].includes(data?.status)) && (
            <Flex justifyCenter style={{width: '100%', padding: '1px 8px'}}>
              <Button
                size="small"
                label={activatingText[activeValue]}
                variant={activatingTextColor[activeValue]}
                onClick={() => {
                  const activated = !activeValue;
                  return handleActivation({activated});
                }}
              />
            </Flex>
          )}
          <Spacer width={8}/>
          {/* <Flex justifyCenter style={{width: '100%'}}>
            <Button
              size="small"
              label={i18n.t('delete')}
              variant="danger"
              onClick={() => deleteLab()}
            />
          </Flex> */}
        </>
      )}
    </Flex>
  );
};

LabDetailsActions.propTypes = {
  data: PropTypes.shape(),
  onDone: PropTypes.func,
};
  
export default LabDetailsActions;
