import React from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {ORDER_TYPE} from 'models';
import {ORDER_TYPES} from 'utils/options';

import LabHeader from 'components/global/LabHeader';
import {BatchOrderList, SingleOrderList} from 'components/orders';

const Orders = () => {
  const {push} = useHistory();
  const {pathname} = useLocation();
  const type = pathname === '/orders/single' ? ORDER_TYPE.SINGLE : ORDER_TYPE.BATCH;

  const handleFilterChange = value => {
    push(`/orders/${value}`);
  };

  return (
    <>
      <LabHeader
        title="orders"
        selectedOption={type}
        filterOptions={ORDER_TYPES}
        onFilterChange={handleFilterChange}
      />
      {type === ORDER_TYPE.SINGLE ? <SingleOrderList/> : <BatchOrderList/>}
    </>
  );
};

export default Orders;
