import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import PropTypes from 'prop-types';
import {Toastr} from 'utils/Toastr';

const FileInput = (props) => {
  const {onDone, multiple, disabled, className} = props;

  const onDrop = useCallback((acceptedFiles) => {
    const fileList = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => Toastr.error('file_reading_was_aborted');
      reader.onerror = () => Toastr.error('file_reading_has_failed');
      reader.readAsDataURL(file);

      reader.onload = () => {
        const fileInfo = {
          file_file_name: file.name,
          type: file.type,
          size: `${Math.round(file.size / 1000)} kB`,
          file_size: Math.round(file.size / 1000),
          file: reader.result,
        };
        fileList.push(fileInfo);
        
        if (fileList.length === acceptedFiles.length) {
          if (multiple) onDone(fileList);
          else onDone(fileList[0]);
        }
      };
    });
  }, []);

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return (
    <div {...getRootProps()} className={className}>
      <input {...getInputProps()} disabled={disabled}/>
      {isDragActive && <p>Drop the files here ...</p> }
    </div>
  );
};

FileInput.propTypes = {
  onDone: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default FileInput;
