import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import {TextInput} from 'atoms';

import {ReactComponent as Search} from 'assets/icons/common/search-gray.svg';
import './style.scss';

export const SearchInput = ({onChange, ...rest}) => {
  return (
    <TextInput
      inputClass="search-input"
      startIcon={<Search/>}
      inputStyle={{borderRadius: '2rem'}}
      onChange={debounce((e) => onChange(e.target.value), 250)}
      {...rest}
    />
  );
};

SearchInput.defaultProps = {
  onChange: () => {}
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func
};
