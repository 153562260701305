import React from 'react';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {TEST_RESULT} from 'models';

import {DataTable, Tag, Text} from 'atoms';

const PatientOrderTable = () => {
  const {push} = useHistory();
  const {patientOrders, fetching} = useSelector(state => state.orders);

  const handleRowClick = ({id}) => {
    push(`/tests/${id}`);
  };

  const columns = [
    {
      field: 'patient_detail.name',
      headerName: i18n.t('patient'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 120,
      flex: 1
    },
    {
      field: 'service.name',
      headerName: i18n.t('test'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'patient_detail.priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'appointment.time',
      headerName: i18n.t('date'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => format(value, DISPLAY_DATE_FORMAT)
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'test_detail.overall_result',
      headerName: i18n.t('results'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => {
        if (!value) return null;
        const color = value === TEST_RESULT.PENDING ? '--brand-text-black-opacity-50' : '--brand-text-black';
        return <Text typography="paragraph14" weight="bold" color={`var(${color})`}>{i18n.t(`result_${value}`)}</Text>;
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={patientOrders}
      loading={fetching}
      onRowClick={handleRowClick}
    />
  );
};

export default PatientOrderTable;
