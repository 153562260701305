import React from 'react';
import PropTypes from 'prop-types';

import {
  ProgressBar,
  Spacer,
  Button,
  Text,
  Flex,
} from 'atoms';
import i18n from 'translation/i18n';
import { ModalContent } from 'templates';
import CurrentStep from './CurrentStep';

const titles = [{
  title: i18n.t('test_details'),
  steps: [1,2,3],
},
{
  title: i18n.t('bill_and_collection'),
  steps: [4,5],
}];

const StepsHeader = ({ activeStep }) => {
  return (
    <>
      <Flex justifyBetween style={{ padding: '0 29px' }}>
        {titles.map((title, index) => (
          <Text
            typography="paragraph14"
            weight={title.steps.includes(activeStep) ? 'bold' : 'meduim'}
            color={title.steps.includes(activeStep) ? 'var(--brand-text-black)' : 'var(--brand-text-black-opacity-40)'}
            key={index}
          >
            {title.title}
          </Text>
        ))}
      </Flex>
      <Spacer height={16} />
      <ProgressBar value={(0.20 * activeStep)} height={2} />
    </>
  );
};

StepsHeader.propTypes = {
  activeStep: PropTypes.number,
};

const StepsTemplate = ({activeStep}) => {

  return (
    <>
      <StepsHeader activeStep={activeStep}/>
      <ModalContent>
        <CurrentStep activeStep={activeStep} />
      </ModalContent>
      <Spacer height={20} />
      <Spacer height={1} width='100%' style={{ backgroundColor: 'var(--brand-divider-white)' }} />
      <ModalContent>
        <Button
          label={activeStep === 5 ? i18n.t('confirm_reservation') : i18n.t('next')}
          type="submit"
        />
      </ModalContent>
    </>
  );
};

StepsTemplate.propTypes = {
  activeStep: PropTypes.number,
};

export default StepsTemplate;
