import React from 'react';
import PropTypes from 'prop-types';
import {IconButton, Menu, MenuItem} from '@mui/material';

import i18n from 'translation/i18n';
import {TEST_RESULT, ORDER_STATUS, BATCH_ORDER_STATUS} from 'models';

import {Text} from 'atoms';
import {ReactComponent as MenuIcon} from 'assets/icons/common/more-vertical.svg';
import './styles.scss';

const OrderResultTransition = ({
  children,
  result,
  status,
  menuPosition,
  onMenuClick
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const options = Object.values(TEST_RESULT).filter(option => option !== result);

  const showTransition = [
    ORDER_STATUS.PROCESS,
    ORDER_STATUS.READY,
    BATCH_ORDER_STATUS.DONE
  ].includes(status);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (option) => {
    onMenuClick(option);
    handleClose();
  };

  return (
    <>
      {showTransition && (
        <div
          id="order-result-transition"
          aria-haspopup="true"
          aria-controls={open ? 'order-result-transition-popover' : undefined}
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          {children || <IconButton><MenuIcon/></IconButton>}
        </div>
      )}
      <Menu
        id="order-result-transition-popover"
        className={`position-${menuPosition}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'order-result-transition'}}
      >
        {status === BATCH_ORDER_STATUS.DONE ? (
          <Text typography="caption13" color="#BBBDC9" weight="bold">
            {i18n.t('no_further_changes')}
          </Text>
        ) : (
          <div>
            <Text typography="caption13" color="#BBBDC9" weight="bold">
              {i18n.t('mark_as')}
            </Text>
            {options.map(option => (
              <MenuItem key={option} onClick={() => handleMenuClick(option)}>
                <Text typography="paragraph14" weight="bold">
                  {i18n.t(`result_${option}`)}
                </Text>
              </MenuItem>
            ))}
          </div>
        )}
      </Menu>
    </>
  );
};

OrderResultTransition.defaultProps = {
  menuPosition: 'right',
  onMenuClick: () => {}
};

OrderResultTransition.propTypes = {
  children: PropTypes.node,
  result: PropTypes.string,
  status: PropTypes.string,
  menuPosition: PropTypes.oneOf(['left', 'right', 'bottom']),
  onMenuClick: PropTypes.func
};

export default OrderResultTransition;
