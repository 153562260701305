export const OVERVIEW_STATS = 'OVERVIEW_STATS';
export const OVERVIEW_STATS_PENDING = 'OVERVIEW_STATS_PENDING';
export const OVERVIEW_STATS_FULFILLED = 'OVERVIEW_STATS_FULFILLED';
export const OVERVIEW_STATS_REJECTED = 'OVERVIEW_STATS_REJECTED';

export const RESERVATION_LIST = 'RESERVATION_LIST';
export const RESERVATION_LIST_PENDING = 'RESERVATION_LIST_PENDING';
export const RESERVATION_LIST_FULFILLED = 'RESERVATION_LIST_FULFILLED';
export const RESERVATION_LIST_REJECTED = 'RESERVATION_LIST_REJECTED';

export const RESERVATION_INFO = 'RESERVATION_INFO';
export const RESERVATION_INFO_PENDING = 'RESERVATION_INFO_PENDING';
export const RESERVATION_INFO_FULFILLED = 'RESERVATION_INFO_FULFILLED';
export const RESERVATION_INFO_REJECTED = 'RESERVATION_INFO_REJECTED';

export const USER_LIST = 'USER_LIST';
export const USER_LIST_PENDING = 'USER_LIST_PENDING';
export const USER_LIST_FULFILLED = 'USER_LIST_FULFILLED';
export const USER_LIST_REJECTED = 'USER_LIST_REJECTED';

export const USER_OPTIONS = 'USER_OPTIONS';
export const USER_OPTIONS_PENDING = 'USER_OPTIONS_PENDING';
export const USER_OPTIONS_FULFILLED = 'USER_OPTIONS_FULFILLED';
export const USER_OPTIONS_REJECTED = 'USER_OPTIONS_REJECTED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_PENDING = 'CREATE_USER_PENDING';
export const CREATE_USER_FULFILLED = 'CREATE_USER_FULFILLED';
export const CREATE_USER_REJECTED = 'CREATE_USER_REJECTED';

export const GET_USER = 'GET_USER';
export const GET_USER_PENDING = 'GET_USER_PENDING';
export const GET_USER_FULFILLED = 'GET_USER_FULFILLED';
export const GET_USER_REJECTED = 'GET_USER_REJECTED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_PENDING = 'UPDATE_USER_PENDING';
export const UPDATE_USER_FULFILLED = 'UPDATE_USER_FULFILLED';
export const UPDATE_USER_REJECTED = 'UPDATE_USER_REJECTED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_PENDING = 'DELETE_USER_PENDING';
export const DELETE_USER_FULFILLED = 'DELETE_USER_FULFILLED';
export const DELETE_USER_REJECTED = 'DELETE_USER_REJECTED';
