import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {get} from 'lodash';

import i18n from 'translation/i18n';
import {getUser, getUsers, updateUser} from 'redux/lab/actionCreators';

import {Modal} from 'atoms';
import {ModalHeader} from 'templates';
import {ACTIVE_STATUS} from 'models';
import {UserForm} from './UserForm';

export const EditUser = ({userId, onClose}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const [user, setUser] = useState({status: false});

  useEffect(() => {
    dispatch(getUser(userId))
      .then(resp => {
        const data = get(resp, 'value.data.user', {});
        setUser({
          ...data,
          role: data.role_label,
          status: data.status_label === ACTIVE_STATUS.ACTIVATED
        });
      });
  }, []);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const handleSubmit = (userInfo) => {
    const payload = {
      user: {
        ...userInfo,
        status: userInfo.status ? 'activated' : 'deactivated',
      }
    };
    dispatch(updateUser(userInfo.id, payload))
      .then(() => {
        dispatch(getUsers());
        handleClose();
      });
  };

  return (
    <Modal
      header={<ModalHeader title={i18n.t('edit_user')}/>}
      isOpen={showModal}
      onClose={handleClose}
      width={350}
    >
      <UserForm isEdit onSubmit={handleSubmit} user={user}/>
    </Modal>
  );
};

EditUser.propTypes = {
  userId: PropTypes.string,
  onClose: PropTypes.func
};
