import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-final-form';

import StepsTemplate from './steps/StepsTemplate';

const NewPatientForm = ({onSubmit, activeStep}) => {
  return (
    <Form onSubmit={onSubmit} style={{ height: '100%'}}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <StepsTemplate activeStep={activeStep}/>
        </form>
      )}
    </Form>
  );
};

NewPatientForm.propTypes = {
  onSubmit: PropTypes.func,
  activeStep: PropTypes.number,
};

export default NewPatientForm; 