import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {DataTable, Text, Flex, Switch, Spacer} from 'atoms';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {labStatusTextColor} from 'utils/labUtils';
import {ACTIVE_STATUS} from 'models';
// import TestTableActions from './TestTableActions';

const TestsTable = (props) => {
  const {fetching} = useSelector(state => state.tests);
  const {list, onRowClick} = props;

  const columns = [
    {
      field: 'service.name',
      headerName: i18n.t('test_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'name',
      headerName: i18n.t('priority'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'service.test_type',
      headerName: i18n.t('test_type'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 150,
      renderCell: ({row, field}) => {
        const isActive = [ACTIVE_STATUS.ACTIVE, ACTIVE_STATUS.ACTIVATED]
          .includes(valueGetter(row, field));
        return (
          <Flex itemsCenter>
            <Text typography="paragraph14" weight="bold" color={labStatusTextColor[valueGetter(row, field)]}>
              {i18n.t(valueGetter(row, field))}
            </Text>
            <Spacer width={8}/>
            <Switch defaultChecked={isActive} id="activeSwitch"/>
          </Flex>
        );
      },
    },
    // {
    //   field: 'action',
    //   headerName: i18n.t('action'),
    //   minWidth: 150,
    //   renderCell: ({row}) => {
    //     return (
    // <TestTableActions data={row} onDone={onDone}/>
    //     );
    //   }
    // },
  ];
    
  return (
    <DataTable
      columns={columns}
      rows={list}
      onRowClick={onRowClick}
      loading={fetching}
    />
  );
};

TestsTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
};

export default TestsTable;
