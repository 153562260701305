import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {useDispatch, useSelector} from 'react-redux';
import {CardContainer, ModalContent} from 'templates';
import {Spacer, Text, Divider, Flex, Switch, Card} from 'atoms';
import {ReactComponent as MoneyIcon} from 'assets/icons/reservations/cash-money.svg';
import {testUpdate} from 'redux/tests/actionCreators';
import {formattedPrice} from 'utils/labUtils';
import {ACTIVE_STATUS} from 'models';
import RequestTest from '../RequestTest/RequestTest';
import styles from '../styles.module.css';

const TestSummaryCard = (props) => {
  const dispatch = useDispatch();
  const {onDone, isAdmin} = props;
  const {service_show} = useSelector(state => state.tests);
  const {amount} = service_show;

  const service = isAdmin ? service_show : service_show.service;
  const status = isAdmin ? service_show.status : service_show.status;

  const updateService = (service_id, serviceData) => (
    dispatch(testUpdate(service_id, {service: serviceData}))
  );

  return (
    <CardContainer title={i18n.t('summary')} className={styles.summaryCard} noPadding>
      <ModalContent padding="30px 24px">
        <Text typography="caption12" weight='600' color="var(--brand-text-gray)" textCase="capitalize">
          {i18n.t('test_description')}
        </Text>
        <Spacer height={6}/>
        <Text typography="paragraph14" weight='bold' color="var(--brand-text-black)">
          {service.description}
        </Text>
      </ModalContent>
      <Divider />
      <ModalContent padding="30px 24px">
        <Flex justifyBetween>
          <Text typography="paragraph14" weight='bold' color="var(--brand-text-black)" textCase="capitalize">
            {i18n.t('test_status')}
          </Text>
          <Switch 
            defaultChecked={status === ACTIVE_STATUS.ACTIVE} 
            onChange={(activate) => 
              updateService(service.id, {
                ...(
                  isAdmin
                    ? {name: service_show.name}
                    : {priority: service_show.priority}
                ), activate})
            }
          />
        </Flex>
        <Spacer height={24}/>
        {!isAdmin &&(
          <>
            <Text typography="paragraph14" weight='bold' color="var(--brand-text-black)" textCase="capitalize">
              {i18n.t('price_per_patient')}
            </Text>
            <Spacer height={8}/>
            <Card className={styles.infoLiCard}>
              <Flex justifyBetween itemsCenter>
                <Flex itemsCenter>
                  <MoneyIcon />
                  <Spacer width={11}/>
                  <Text typography="paragraph14" color="var(--brand-text-black)">
                    {formattedPrice(amount, 'sar')}
                  </Text>
                  <Spacer width={6}/>
                </Flex>
                <RequestTest
                  linkButton
                  initialValues={{
                    service: {...(isAdmin
                      ? {name: service_show.name}
                      : {priority: service_show.priority}
                    ), status, price: amount},
                    name: service.name,
                    id: service.id
                  }}
                  onDone={onDone}
                />
              </Flex>
            </Card>
          </>
        )}
      </ModalContent>
    </CardContainer>
  );
};

TestSummaryCard.propTypes = {
  onDone: PropTypes.func,
  isAdmin: PropTypes.bool,
};

export default TestSummaryCard;
