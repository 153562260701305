import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import {Tag, Text} from 'atoms';
import {ReactComponent as Menu} from 'assets/icons/common/more-vertical.svg';

import {getStatusColor} from 'utils/labUtils';
import i18n from 'translation/i18n';
import './styles.scss';

export const OverdueTests = () => {
  const [orders, setOrders] = useState([]);
  const {statistics} = useSelector(state => state.lab);

  useEffect(() => {
    const value = statistics?.overdue_orders || [];
    setOrders(value);
  }, [statistics]);

  return (
    <>
      <Text typography="subtitle" weight="bold">{i18n.t('overview_overdue_title')}</Text>
      <Box display="flex" justifyContent="space-between" pr={1}>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('overview_overdue_subtitle')}
        </Text>
        <Text typography="paragraph14" color="var(--brand-text-primary)" style={{fontWeight: 600}}>
          {i18n.t('view_all')}
        </Text>
      </Box>
      <TableContainer className="overdue-tests-table">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{i18n.t('tests')}</TableCell>
              <TableCell>{i18n.t('status')}</TableCell>
              <TableCell>{i18n.t('overdue')}</TableCell>
              <TableCell align="right"/>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => (
              <TableRow
                key={index}
                sx={{'&:last-child td, &:last-child th': {border: 0}}}
              >
                <TableCell>
                  <Text typography="paragraph14" weight="bold">{order.service.name}</Text>
                  <Text typography="caption12" weight="light" color="#C5C7CD">{order.patient_detail?.name}</Text>
                </TableCell>
                <TableCell>
                  <Tag text={i18n.t(`status_${order.status}`)} color={getStatusColor(order.status)}/>
                </TableCell>
                <TableCell>
                  <Text typography="paragraph14" weight="bold">{order.overdue_by} Days</Text>
                  <Text typography="caption12" weight="light" color="#C5C7CD">for collecting</Text>
                </TableCell>
                <TableCell align="right">
                  <Menu/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

