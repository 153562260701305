import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { DatePicker  } from 'atoms';
import { isRtl } from 'utils/uiHelpers';

const DatePickerField = ({
  disabled,
  name,
  onChange,
  validate,
  popperPlacement,
  ...rest
}) => (
  <Field name={name} validate={validate}>
    {({ input, meta }) => (
      <DatePicker
        events={[]}
        {...rest}
        {...input}
        selected={input.value && new Date(input.value)}
        status={(meta.touched && meta.error) && 'error'}
        statusMessage={meta.touched && meta.error}
        popperPlacement={popperPlacement || 'auto'}
        locale={isRtl ? 'ar' : 'en'}
        onChange={(val) => {
          input.onChange(val);
          if (onChange) {
            onChange(val);
          }
        }}
        disabled={disabled}
      />
    )}
  </Field>
);

DatePickerField.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  popperPlacement: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
};

DatePickerField.defaultProps = {
  size: 'large',
};

export default DatePickerField;
