const Auth = {
  // *

  // A
  address: 'العنوان',
  all_pcr_features: 'جميع ميزات  الـ بي سي آر',
  advanced_reports: 'تقارير متقدمة',

  // B

  // C
  cvv: 'رمز التحقق من البطاقة',
  choose_the_best_plan_for_your_business: 'اختر أفضل خطة لعملك',
  custom_link: 'رابط مخصص',
  clinic_management: 'إدارة العيادات',
  create_lab: 'انشاء مختبر',
  change_logo: 'تغيير الشعار',
  commercial_registration: 'السجل التجاري',
  cr_number: 'رقم السجل التجاري',
  clinic_address: 'عنوان العيادة',
  card_number: 'رقم البطاقة',

  // D
  dont_have_an_account: 'ليس لديك حساب؟',
  day_free_trial_no_payments_required: 'نسخة تجريبية مجانية لمدة 14 يومًا ، دون الحاجة إلى دفع أي مبالغ',
  digits: '16 ارقام',

  // E
  email_notifications: 'اشعارات البريد الالكتروني',
  email_address: 'البريد الإلكتروني',
  expiry: 'الإنتهاء',
  enter_your_email_and_password_below: 'أدخل بريدك الإلكتروني وكلمة المرور أدناه',
  enter_your_email_to_reset_your_password: 'أدخل بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك',
  enter_the_code_sent_to: 'أدخل الرمز الذي تم إرساله إلى',
  enter_your_new_password_and_verify_it_in_order_to_reset_it: 'أدخل كلمة المرور الجديدة الخاصة بك وقم بتأكيدها ، من أجل إعادة تعيينها.',

  // F
  full_solution: 'فحص كامل',
  flexible_pricing_without_monthly_fees_and_a_5_fee_per_transaction: 'أسعار مرنة بدون رسوم شهرية ورسوم 5٪ لكل معاملة',
  forgot_password: 'نسيت كلمة المرور؟',

  // G
  get_early_access: 'احصل على الدخول الأول',

  // H
  have_an_account: 'هل لديك حساب?',

  // I

  // J
  iban: 'رقم الحساب المصرفي الدولي',

  // K

  // L
  location: 'الموقع',
  log_in: 'تسجيل الدخول',
  lab_address: 'عنوان المختر',
  login_as_a_patient_for: 'تسجيل الدخول كمريض في {{lab}}',

  // M

  // N
  next: 'التالي',
  new_password: 'كلمة المرور الجديدة',
  verify_new_password: 'التحقق من كلمة المرور الجديدة',
  name: 'الاسم',
  name_en: 'الاسم [ انجليزي ]',
  name_ar: 'الاسم [ عربي ]',
  name_on_card: 'الاسم في البطاقة',

  // O

  // P
  plans_and_pricing: 'الخطط والتسعير',
  pcr_plan: 'خطة بي سي آر',
  phone: 'الهاتف',
  phone_number: 'رقم الهاتف',
  pick_a_location: 'اختر موقعاً',
  payment: 'الدفع',
  password: 'كلمة المرور',

  // Q

  // R
  reservation_management: 'إدارة الحجوزات',
  resend_code: 'إعادة الإرسال',
  reset_password: 'إعادة تعيين كلمة المرور',

  // S
  send_results_sms: 'ارسال النتائج عبر الـ إس إم إس',
  subscribe: 'اشتراك',
  signup: 'تسجيل الدخول',
  signup_as_a_patient_for: 'التسجيل كمريض في {{lab}}',
  subscription_created_successfully: 'Subscription created successfully',

  // T
  test_management: 'ادارة الفحوصات',

  // U
  user_management: 'ادارة المستخدمين',

  // V
  vat: 'ضريبة القيمة المضافة',
  vat_number: 'رقم ضريبة القيمة المضافة',
  verify: 'تأكيد',

  // W
  we_have_sent_an_email_to_you_please_check_your_inbox_to_be_able_to_create_a_new_password: 'لقد قمنا بإرسال بريد إلكتروني لك. يرجى التحقق من بريدك الوارد لتتمكن من إنشاء كلمة مرور جديدة',
  we_have_sent_an_email_to_admin_please_wait_for_your_lab_to_be_activated: 'We have sent an email to the admin, please wait for your lab to be activated!',
  // X

  // Y

  // Z

};

export default Auth;
