import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {DataTable, Switch} from 'atoms';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {formattedPrice} from 'utils/labUtils';
import {ACTIVE_STATUS} from 'models';

const TestsTable = (props) => {
  const {fetching} = useSelector(state => state.tests);
  const {list, onRowClick} = props;

  const columns = [
    {
      field: 'service.name',
      headerName: i18n.t('test_name'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'priority',
      headerName: i18n.t('test_priority'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => i18n.t(valueGetter(row, field))
    },
    {
      field: 'amount',
      headerName: i18n.t('test_price'),
      minWidth: 250,
      flex: 1,
      valueGetter: ({row, field}) => formattedPrice(valueGetter(row, field), 'saudi_riyal')
    },
    {
      field: 'status',
      headerName: i18n.t('activation'),
      minWidth: 150,
      renderCell: ({row, field}) => {
        const isActive = valueGetter(row, field) === ACTIVE_STATUS.ACTIVE;
        return (
          <Switch defaultChecked={isActive} id="activeSwitch"/>
        );
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      rows={list}
      onRowClick={onRowClick}
      loading={fetching}
      checkboxSelection
    />
  );
};

TestsTable.propTypes = {
  list: PropTypes.arrayOf(Object),
  onRowClick: PropTypes.func,
};

export default TestsTable;
