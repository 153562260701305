import React from 'react';
import PropTypes from 'prop-types';
import {Text} from 'atoms';
import styles from './templates.module.css';

const CardSectionHeader = ({
  uppercased,
  padding,
  title,
  style,
}) => {
  return (
    <div className={styles.cardSectionHeader} style={{padding, ...style}}>
      <Text typography="caption12" weight="bold" textCase={uppercased ? 'uppercase' : 'capitalize'}>
        {title}
      </Text>
    </div>
  );
};

CardSectionHeader.propTypes = {
  uppercased: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.shape(),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CardSectionHeader;
