import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Clinics from 'container/labs/Clinics';

const ClinicsRoutes = () => (
  <Switch>
    <Route path="/clinics"  component={Clinics}/>
  </Switch>
);

export default ClinicsRoutes;
