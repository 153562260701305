const Common = {
  // *

  // A
  apply: 'Apply',
  accept: 'Accept',
  activate: 'Activate',
  actions: 'Actions',

  // B
  back: 'Back',

  // C
  cancel_order: 'Cancel Order',
  card: 'Card',
  cash: 'Cash',
  change: 'Change',
  clear: 'Clear',
  clinic: 'Clinic',
  copy_link: 'Copy Link',
  copy_success: 'Copied successfully!',

  // D
  delete: 'Delete',
  deactivate: 'Deactivate',

  // E
  email_msg: 'Text should be of email format',

  // F
  female: 'Female',
  filter: 'Filter',

  // G
  gender: 'Gender',

  // H

  // I
  internet_error_message: 'It seems something wrong with you internet connection!',

  // J

  // K

  // L
  logout: 'Logout',

  // M
  male: 'Male',
  mark_as: 'Mark As',
  min_length: 'min length {{min}}',
  max_length: 'max length {{max}}',
  must_be_more_than: 'must be more than {{min}}',
  must_be_less_than: 'must be less than {{max}}',
  mobile: 'Mobile',

  // N
  negative: 'Negative',
  no_further_changes: 'No Further Changes',
  number_msg: 'Must contain numbers only',
  number: 'Number',

  // O

  // P
  paid: 'Paid',
  payment_mode_text: 'Paid by {{mode}}',
  pending: 'Pending',
  pick_date: 'Pick a date',
  positive: 'Positive',
  priority: 'Priority',
  print_results: 'Print Results',

  // Q

  // R
  required: 'Required',
  reject: 'Reject',

  // S
  sar_month: 'SAR / Month',
  saudi_riyal: 'SAR',
  save: 'Save',
  sort: 'Sort',
  status: 'Status',
  status_cancelled: 'Cancelled',
  status_collected: 'Collected',
  status_confirmed: 'Confirmed',
  status_created: 'Unpaid',
  status_done: 'Done',
  status_paid: 'Paid',
  status_processing: 'Processing',
  status_ready: 'Ready',
  status_rejected: 'Rejected',
  select_date:'Select date',
  should_start_with: 'Should start with {{start}}',
  submit: 'Submit',

  // T
  no_active_tests_tootip_title: 'There is no active tests!, please set at least one test to active in order to allow you adding a patient.',

  // U

  // V
  view_all: 'View all',
  view_details: 'View details',

  // W

  // X

  // Y

  // Z

};

export default Common;
