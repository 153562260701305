import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {Reservations, ReservationDetails} from 'container/labs/Reservation';

const ReservationRoutes = () => (
  <Switch>
    <Route path="/reservations/:id" exact component={ReservationDetails}/>
    <Route path="/reservations" exact component={Reservations}/>
  </Switch>
);

export default ReservationRoutes;
