import React from 'react';

import {isPatientSignup} from 'utils/labUtils';

import PatientHeader from 'components/global/PatientHeader';
import PatientRoutes from 'routers/PatientRoutes';

import './styles.scss';

const PatientLayout = () => {
  return (
    <div className="patient-layout">
      {!isPatientSignup && <PatientHeader/>}
      <PatientRoutes/>
    </div>
  );
};

export default PatientLayout;
