import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils';

import * as actions from './actions';

export function getAdminServiceProviders(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/service_providers`;
  const method = 'GET';

  return {
    type: actions.GET_ADMIN_SERVICE_PROVIDERS,
    payload: handleAPI(url, params, method),
  };
}

export function getServiceProviderSubscriptions(params = {}) {
  const url = `/${API_VERSION_ONE}/admin/lab_subscriptions`;
  const method = 'GET';

  return {
    type: actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS,
    payload: handleAPI(url, params, method),
  };
}

export function getAdminServiceProviderInfo(id) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${id}`;
  const method = 'GET';

  return {
    type: actions.GET_ADMIN_SERVICE_PROVIDER_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function updateAdminServiceProviders(data, id) {
  const url = `/${API_VERSION_ONE}/admin/service_providers/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_ADMIN_SERVICE_PROVIDERS,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateServiceProviderSubscription(lab_subscription, id) {
  const url = `/${API_VERSION_ONE}/admin/lab_subscriptions/${id}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION,
    payload: handleAPI(url, {}, method, {lab_subscription}),
  };
}
