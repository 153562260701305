import React from 'react';
// import PropTypes from 'prop-types';
// import {useSelector} from 'react-redux';
import i18n from 'translation/i18n';
import {CardContainer, CardSectionHeader, ModalContent} from 'templates';
import {Spacer, Flex, Text} from 'atoms';
import BackButton from 'components/global/BackButton';
import styles from '../styles.module.css';
import InfoListCards from './InfoListCards';
// import {TestDetailsActions} from '../Admin';

const TestInformationCard = () => {
  // const {service_show} = useSelector(state => state.tests);
  return (
    <CardContainer className={styles.infoCard} noPadding>
      <ModalContent padding="32px 24px">
        <BackButton/>
        <Spacer height={16}/>
        <Flex justifyBetween>
          <Text
            typography="subtitle"
            weight="bold"
            color="var(--brand-text-black)"
            textCase="capitalize"
          >
            {i18n.t('test_details')} 
          </Text>
          {/* <TestDetailsActions data={service_show} onDone={onDone}/> */}
        </Flex>
      </ModalContent>

      <CardSectionHeader title={i18n.t('test_information')} uppercased/>
      <InfoListCards/>
      <Spacer height={10}/>
    </CardContainer>
  );
};

TestInformationCard.propTypes = {
  // onDone: PropTypes.func,
};

export default TestInformationCard;
