import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';

import { 
  Modal,
} from 'atoms';
import { ModalHeader } from 'templates';
import {ACTIVE_STATUS} from 'models';
import RequestTestForm from './RequestTestForm';

const RequestTestModal = (props) => {
  const {initialValues, open, onClose, onSubmit} = props;

  return (
    <Modal
      header={<ModalHeader title={ i18n.t(initialValues?.id ? 'update_test' : 'request_test')} />}
      isOpen={open}
      onClose={onClose}
      width={350}
    >
      <RequestTestForm 
        onSubmit={onSubmit} 
        initialValues={{
          ...initialValues, 
          service: {
            ...initialValues?.service,
            activate: initialValues?.service?.status === ACTIVE_STATUS.ACTIVE,
          }}}
      />
    </Modal>
  );
};

RequestTestModal.propTypes = {
  initialValues: PropTypes.shape(),
  open: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default RequestTestModal; 