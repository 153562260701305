import React from 'react';
import {withRouter} from 'react-router-dom';

import AdminLayout from 'layouts/AdminLayout';
import LabLayout from 'layouts/LabLayout';
import PatientLayout from 'layouts/PatientLayout';

import 'App.css';
import {ACCESS_TOKEN, SLUG} from 'utils/constants';
import {getAuthUser} from 'redux/auth/actionCreators';
import {USER_ROLE} from 'models';

// Routes
import PublicRoutes from './PublicRoutes';

const RootRouter = () => {
  const isLogged = !!localStorage?.[ACCESS_TOKEN];
  const slug = !!localStorage?.[SLUG];
  const user = getAuthUser();
  const isAdmin = user?.role_label === USER_ROLE.ADMIN
    || user?.role_label === USER_ROLE.SUPER_ADMIN;

  const loggedLayout = {
    lab_admin: <LabLayout/>,
    clinic: <LabLayout/>,
    patient: <PatientLayout/>,
    super_admin: <AdminLayout/>,
  };

  return (
    ((isAdmin || slug) && isLogged)
      ? (loggedLayout[user.role_label] || <span/>)
      : <PublicRoutes/>
  );
};

export default withRouter(RootRouter);
