import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';
import { isEmpty } from 'lodash';

import {
  RadioBox,
  Spacer,
  Flex,
  Text,
} from 'atoms';
import {PriceSummary} from 'templates/OrderSummaryTemplate';
import {getPricesByPriority, getLastChars, isPatient} from 'utils/labUtils';

import {ReactComponent as CashMoneySelected} from 'assets/icons/reservations/cash-money-selected.svg';
import {ReactComponent as CashMoney} from 'assets/icons/reservations/cash-money.svg';
import {ReactComponent as CardPosSelected} from 'assets/icons/reservations/card-pos-selected.svg';
import {ReactComponent as CardPos} from 'assets/icons/reservations/card-pos.svg';
import {ReactComponent as MadaCard} from 'assets/icons/reservations/mada-card.svg';
import {ReactComponent as ApplePay} from 'assets/icons/reservations/apple-pay-card.svg';
import {ReactComponent as FilledCircle} from 'assets/icons/reservations/filled-circle.svg';
import AddCardModal from './AddCardModal';

const Step4 = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const [payMethod, setPayMethod] = React.useState('cash');

  const {change} = useForm();
  const {values} = useFormState();
  const {prices} = useSelector(state => state.patient);
  const price = getPricesByPriority(prices, values.order?.priority)[0];
  const pay = values.payment;

  const onPaymentChanged = (vals) => {
    const payment = {...values.payment, ...vals.payment};
    change('payment.card_number', payment.card_number);
    change('payment.name_on_card', payment.name_on_card);
    change('payment.expiry', payment.expiry);
    change('payment.cvv', payment.cvv);
    change('payment.type', payMethod);
    setOpenModal(false);
  };

  React.useEffect(() => {
    if (payMethod === 'cash') {
      change('payment', {});
      change('payment.type', payMethod);
    } 
    // for Mada and Apple pay
    // else {
    //   change('payment.card_number', '');
    //   change('payment.name_on_card', '');
    //   change('payment.expiry', '');
    //   change('payment.cvv', '');
    //   change('payment.type', payMethod);
    // }
  }, [payMethod]);

  React.useEffect(() => {
    if (values.payment?.type && values.payment?.type !== 'cash') {
      setPayMethod('transaction');
    } else {
      setPayMethod('cash');
    }
  }, []);

  React.useEffect(() => {
    if(!isEmpty(price)) {
      change('payment.tax', price.tax);
      change('payment.sub_total', price.sub_total);
      change('payment.amount', price.total);
      change('payment.discount_amount', 0);
    }
  }, [pay?.tax, pay?.sub_total, pay?.amount, pay?.discount_amount]);

  return (
    <>
      <Spacer height={8}/>
      <PriceSummary price={price}/>
      <Spacer height={48}/>

      <Text typography="paragraph14" color="var(--brand-text-gray-light)" weight="bold">
        {i18n.t('payment_method').toUpperCase()}
      </Text>
      <Spacer height={6}/>

      <RadioBox
        name="payment.type"
        label={i18n.t('cash')}
        variant="large"
        checked={payMethod === 'cash'}
        startIcon={payMethod === 'cash' ? <CashMoneySelected/> : <CashMoney/>}
        onChange={() => setPayMethod('cash')}
      />
      <Spacer height={20}/>
      <RadioBox
        name="payment.type"
        label={i18n.t('transaction')}
        variant="large"
        checked={payMethod === 'transaction'}
        startIcon={payMethod === 'transaction' ? <CardPosSelected/> : <CardPos/>}
        onChange={() => setPayMethod('transaction')}
        actionButton={isPatient() ? {
          label: i18n.t(values.payment?.card_number ? 'change' : 'add_card'),
          value: values.payment?.card_number 
            ? 
            <Flex itemsCenter>
              <FilledCircle/>
              <Spacer width={4}/>
              <FilledCircle/>
              <Spacer width={4}/>
              {getLastChars(values.payment?.card_number)}
            </Flex> 
            : '',
          onClick: () =>  setOpenModal(true),
        } : {}}
      />
      {isPatient() && (
        <>
          <Spacer height={20}/>
          <RadioBox
            name="payment.type"
            label={i18n.t('mada')}
            variant="large"
            checked={payMethod === 'mada'}
            startIcon={<MadaCard/>}
            onChange={() => setPayMethod('mada')}
          />
          <Spacer height={20}/>
          <RadioBox
            name="payment.type"
            label={i18n.t('apple_pay')}
            variant="large"
            checked={payMethod === 'apple_pay'}
            startIcon={<ApplePay/>}
            onChange={() => setPayMethod('apple_pay')}
          />
          <AddCardModal 
            open={openModal} 
            onClose={() => setOpenModal(false)} 
            initialValues={{payment: {...values.payment}}}
            onSubmit={onPaymentChanged}
          />
        </>
      )}
      <Spacer height={40}/>
    </>
  );
};

export default Step4;
