import React from 'react';
import {Grid, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {ORDER_STATUSES, PRIORITIES} from 'utils/options';
import {ORDER_STATUS} from 'models';
import {Switch, Text} from 'atoms';
import NewPatient from 'components/reservation/NewPatient/NewPatient';
import {getReservations} from 'redux/lab/actionCreators';
import {SearchInput, SelectFilter} from 'components/global/filters';

export const ReservationFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const dispatch = useDispatch();

  const selectFilterConfigs = [
    {key: 'status', options: ORDER_STATUSES, placeholderKey: 'status'},
    {key: 'priority', options: PRIORITIES, placeholderKey: 'priority'}
  ];
  const dateFilterConfigs = [
    {key: 'date', placeholderKey: 'date'}
  ];

  const handleUnpaidSwitch = (value) => {
    const status = value ? ORDER_STATUS.CREATED : null;
    generateOtherFilters('status', status);
  };

  const loadReservations = (params = {}) => {
    dispatch(getReservations(params));
  };

  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('reservation_list_title')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('reservation_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t('reservation_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      <Grid item xs={2}>
        <Stack direction="row" alignItems="center">
          <Text typography="paragraph14" style={{marginRight: '0.75rem'}}>
            {i18n.t('view_unpaid_only')}
          </Text>
          <Switch onChange={handleUnpaidSwitch}/>
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <SelectFilter
          selectFilterConfigs={selectFilterConfigs}
          dateFilterConfigs={dateFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      <Grid item xs={2}>
        <NewPatient onDone={loadReservations}/>
      </Grid>
    </Grid>
  );
};

ReservationFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};
