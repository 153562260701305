import {get} from 'lodash';

import {LOCALE} from 'utils/constants';

export const isRtl = (localStorage.getItem(LOCALE) === 'ar');

export const valueGetter = (obj, path) => get(obj, path, '');
  
export const goNextFieldByKeyUp = (event, maxLength = 1, byEnter) => {
  const eventKey = event.key.toLowerCase();
  if(eventKey === 'escape' || eventKey === 'backspace'){
    const {form} = event.target;
    const index = [...form].indexOf(event.target);
    if(index > 0){
      form.elements[index - 1].focus();
    }
    return event.preventDefault();
  }

  if (event.target.value.length === maxLength || (byEnter && eventKey === 'enter')) {
    const {form} = event.target;
    const index = [...form].indexOf(event.target);
    form.elements[index + 1].focus();
    return event.preventDefault();
  }
  return null;
};

export const allFileTypes = 'image/*, .doc, .docx, .pdf, .xls, .xlsx, .csv';
