export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PATIENT_PENDING = 'ADD_PATIENT_PENDING';
export const ADD_PATIENT_FULFILLED = 'ADD_PATIENT_FULFILLED';
export const ADD_PATIENT_REJECTED = 'ADD_PATIENT_REJECTED';

export const GET_PATIENT_INVOICE = 'GET_PATIENT_INVOICE';
export const GET_PATIENT_INVOICE_PENDING = 'GET_PATIENT_INVOICE_PENDING';
export const GET_PATIENT_INVOICE_FULFILLED = 'GET_PATIENT_INVOICE_FULFILLED';
export const GET_PATIENT_INVOICE_REJECTED = 'GET_PATIENT_INVOICE_REJECTED';

export const PATIENT_BOOK_TEST = 'PATIENT_BOOK_TEST';
export const PATIENT_BOOK_TEST_PENDING = 'PATIENT_BOOK_TEST_PENDING';
export const PATIENT_BOOK_TEST_FULFILLED = 'PATIENT_BOOK_TEST_FULFILLED';
export const PATIENT_BOOK_TEST_REJECTED = 'PATIENT_BOOK_TEST_REJECTED';

export const PATIENT_OPTIONS_LOAD = 'PATIENT_OPTIONS_LOAD';
export const PATIENT_OPTIONS_LOAD_PENDING = 'PATIENT_OPTIONS_LOAD_PENDING';
export const PATIENT_OPTIONS_LOAD_FULFILLED = 'PATIENT_OPTIONS_LOAD_FULFILLED';
export const PATIENT_OPTIONS_LOAD_REJECTED = 'PATIENT_OPTIONS_LOAD_REJECTED';

export const GET_PRICES_LOAD = 'GET_PRICES_LOAD';
export const GET_PRICES_LOAD_PENDING = 'GET_PRICES_LOAD_PENDING';
export const GET_PRICES_LOAD_FULFILLED = 'GET_PRICES_LOAD_FULFILLED';
export const GET_PRICES_LOAD_REJECTED = 'GET_PRICES_LOAD_REJECTED';
