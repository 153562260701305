import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {useForm, useFormState} from 'react-final-form';

import {required} from 'utils/FormValidations';
import {getListForSelect} from 'utils/labUtils';
import {SYMPTOMS} from 'utils/options';

import {Spacer, CustomSelect} from 'atoms';
import {SelectField} from 'components/global/final-form';

const Step3 = () => {
  const {occupations, reason_for_testings} = useSelector(state => state.patient);
  const {prices} = useSelector(state => state.patient);
  const {change} = useForm();
  const {values} = useFormState();
  const priorities = prices?.map(pr => { 
    return { label: i18n.t(pr.priority), value: pr.priority };
  });

  const onChangeSelection = (data) => {
    change('order.sign_and_symptoms', data.value);
  };

  React.useEffect(() => {
    change('order.sign_and_symptoms', 'signs');
  }, []);

  return (
    <>
      <SelectField
        name="order.reason_for_testing"
        label={i18n.t('reason_of_testing')}
        placeholder={i18n.t('reason_of_testing')}
        options={getListForSelect(reason_for_testings)}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={20}/>
      <SelectField
        name="order.priority"
        label={i18n.t('test_priority')}
        placeholder={i18n.t('test_priority')}
        options={priorities}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={20}/>
      <SelectField
        name="patient.occupation"
        label={i18n.t('occupation')}
        placeholder={i18n.t('occupation')}
        options={getListForSelect(occupations)}
        validate={required}
        maxMenuHeight={320}
      />
      <Spacer height={20}/>
      <CustomSelect
        label={i18n.t('signs_and_symptoms')}
        onChange={onChangeSelection}
        options={SYMPTOMS}
        selected={values.order?.sign_and_symptoms}
      />
      <Spacer height={120}/>
    </>
  );
};

export default Step3;
