export const PROFILE_SHOW = 'PROFILE_SHOW';
export const PROFILE_SHOW_PENDING = 'PROFILE_SHOW_PENDING';
export const PROFILE_SHOW_FULFILLED = 'PROFILE_SHOW_FULFILLED';
export const PROFILE_SHOW_REJECTED = 'PROFILE_SHOW_REJECTED';

export const PROFILE_UPDATE = 'PROFILE_UPDATE';
export const PROFILE_UPDATE_PENDING = 'PROFILE_UPDATE_PENDING';
export const PROFILE_UPDATE_FULFILLED = 'PROFILE_UPDATE_FULFILLED';
export const PROFILE_UPDATE_REJECTED = 'PROFILE_UPDATE_REJECTED';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';
export const CHANGE_EMAIL_PENDING = 'CHANGE_EMAIL_PENDING';
export const CHANGE_EMAIL_FULFILLED = 'CHANGE_EMAIL_FULFILLED';
export const CHANGE_EMAIL_REJECTED = 'CHANGE_EMAIL_REJECTED';

export const CHANGE_MOBILE = 'CHANGE_MOBILE';
export const CHANGE_MOBILE_PENDING = 'CHANGE_MOBILE_PENDING';
export const CHANGE_MOBILE_FULFILLED = 'CHANGE_MOBILE_FULFILLED';
export const CHANGE_MOBILE_REJECTED = 'CHANGE_MOBILE_REJECTED';

export const VERIFY_CHANGE_MOBILE = 'VERIFY_CHANGE_MOBILE';
export const VERIFY_CHANGE_MOBILE_PENDING = 'VERIFY_CHANGE_MOBILE_PENDING';
export const VERIFY_CHANGE_MOBILE_FULFILLED = 'VERIFY_CHANGE_MOBILE_FULFILLED';
export const VERIFY_CHANGE_MOBILE_REJECTED = 'VERIFY_CHANGE_MOBILE_REJECTED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_PENDING = 'CHANGE_PASSWORD_PENDING';
export const CHANGE_PASSWORD_FULFILLED = 'CHANGE_PASSWORD_FULFILLED';
export const CHANGE_PASSWORD_REJECTED = 'CHANGE_PASSWORD_REJECTED';
