import React from 'react';

import LabHeader from 'components/global/LabHeader';
import {UserList} from 'components/users';

export const Users = () => {
  return (
    <>
      <LabHeader title="users"/>
      <UserList/>
    </>
  );
};
