import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Grid} from '@mui/material';
import {getAdminServiceProviderInfo} from 'redux/labs/actionCreators';
import {LabGeneralInfo, LabAdditionInfo} from '.';

const LabInfo = () => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const reloadData = () => {
    dispatch(getAdminServiceProviderInfo(id));
  };

  React.useEffect(()=>{
    reloadData();
  }, []);

  return (
    <Grid container spacing={4} sx={{margin: 0}}>
      <Grid item xs={8}>
        <LabGeneralInfo onDone={reloadData}/>
      </Grid>
      <Grid item xs={3.5}>
        <LabAdditionInfo/>
      </Grid>
    </Grid>
  );
};

export default LabInfo;
