import React from 'react';
import PropTypes from 'prop-types';
import {Flex, Spacer, Text, ImageUploader} from 'atoms';
import {CardContainer} from 'templates';
import styles from './templates.module.css';

// variant : large - simple
const CustomImageUploader = (props) => {
  const {
    onDone,
    multiple, 
    disabled, 
    imgSrc,
    altLabel,
    actionLabel,
    variant,
    uploaderType,
    avatarWidth,
    avatarHeight,
    containerStyle,
    ...rest
  } = props;

  return (
    <>
      {variant === 'large' ? 
        (
          <CardContainer style={containerStyle}>
            <label>
              <Flex flexCol justifyCenter itemsCenter className={styles.avataContainer}>
                <ImageUploader
                  maxHeight={80}
                  maxWidth={80}
                  src={imgSrc}
                  alt={altLabel || 'ALT'}
                  onDone={onDone}
                  multiple={multiple}
                  disabled={disabled}
                  uploaderType={uploaderType}
                  {...rest}
                />
                <Spacer height={20}/>
                <Text typography="caption12" link>
                  {actionLabel}
                </Text>
              </Flex>
            </label>
          </CardContainer>
        )
        :
        (
          <label>
            <ImageUploader
              width={80}
              height={80}
              src={imgSrc}
              alt={altLabel || 'ALT'}
              onDone={onDone}
              multiple={multiple}
              uploaderType={uploaderType}
              disabled={disabled}
              {...rest}
            />
          </label>
        )
      }
    </>
  );
};

CustomImageUploader.defaultProps = {
  multiple: false,
  disabled: false,
  variant: 'large',
  onDone: () => {},
};

CustomImageUploader.propTypes = {
  containerStyle: PropTypes.shape(),
  variant: PropTypes.string,
  uploaderType: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  imgSrc: PropTypes.string,
  altLabel: PropTypes.string,
  actionLabel: PropTypes.string,
  onDone: PropTypes.func,
  avatarWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  avatarHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CustomImageUploader;
