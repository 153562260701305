import React from 'react';

import LabHeader from 'components/global/LabHeader';
import {TestList} from 'components/tests/index';

const Tests = () => {
  return (
    <>
      <LabHeader title="tests"/>
      <TestList/>
    </>
  );
};

export default Tests;
