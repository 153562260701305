import {handleAPI, API_VERSION_ONE} from 'utils/apiUtils';
import {isClinicUser} from 'utils/labUtils';
import {CLINIC_LABEL, LAB_LABEL} from 'utils/constants';

import * as actions from './actions';

const baseUrl = () => {
  const module = isClinicUser() ? CLINIC_LABEL : LAB_LABEL;
  return `/${API_VERSION_ONE}/${module}`;
};

export function addPatient(data) {
  const url = `/${API_VERSION_ONE}/lab/patients`;
  const method = 'POST';

  return {
    type: actions.ADD_PATIENT,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getPatientInvoice(patientId) {
  const url = `/${API_VERSION_ONE}/lab/patients/${patientId}/invoice`;
  const method = 'GET';

  return {
    type: actions.GET_PATIENT_INVOICE,
    payload: handleAPI(url, {}, method, null, {}, { responseType: 'blob' })
  };
}

export function getPatientBarcode(patientId) {
  const url = `/${API_VERSION_ONE}/lab/patients/${patientId}/barcode`;
  const method = 'GET';

  return {
    type: actions.GET_PATIENT_INVOICE,
    payload: handleAPI(url, {}, method)
  };
}

export function patientBookTest(data) {
  const url = `/${API_VERSION_ONE}/patient/orders/book`;
  const method = 'POST';

  return {
    type: actions.PATIENT_BOOK_TEST,
    payload: handleAPI(url, {}, method, data),
  };
}

export function labPatientOptionsLoad() {
  const url = `${baseUrl()}/patients/options`;
  const method = 'GET';

  return {
    type: actions.PATIENT_OPTIONS_LOAD,
    payload: handleAPI(url, {}, method, {}),
  };
}

export function patientOptionsLoad() {
  const url = `/${API_VERSION_ONE}/patient/options`;
  const method = 'GET';

  return {
    type: actions.PATIENT_OPTIONS_LOAD,
    payload: handleAPI(url, {}, method, {}),
  };
}

export function getLabPricesLoad(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/services/price`;
  const method = 'GET';

  return {
    type: actions.GET_PRICES_LOAD,
    payload: handleAPI(url, params, method, {}),
  };
}

export function getPatientPricesLoad(params = {}) {
  const url = `/${API_VERSION_ONE}/patient/service_price`;
  const method = 'GET';

  return {
    type: actions.GET_PRICES_LOAD,
    payload: handleAPI(url, params, method, {}),
  };
}
