import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';

import {CardContainer} from 'templates';
import {ReservationOrderInfoActions} from 'components/labs';
import {ReservationTestInfo} from './ReservationTestInfo';
import {ReservationPatientInfo} from './ReservationPatientInfo';
import './styles.scss';

export const ReservationInfo = () => {
  const {reservationInfo} = useSelector(state => state.lab);

  return (
    <CardContainer
      className="reservation-info-container"
      title={i18n.t('reservation_info_title')}
      showBackButton
      noPadding
      cardActions={
        <ReservationOrderInfoActions
          reservationId={valueGetter(reservationInfo, 'id')}
          orderId={valueGetter(reservationInfo, 'order.id')}
          status={valueGetter(reservationInfo, 'order.status')}
        />
      }
    >
      <ReservationTestInfo reservationInfo={reservationInfo}/>
      <ReservationPatientInfo reservationInfo={reservationInfo}/>
    </CardContainer>
  );
};
