import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {
  composeValidators,
  shouldStartWith,
  maxLength,
  minLength,
  email,
  number,
  required
} from 'utils/FormValidations';

import {ModalContent} from 'templates';
import {Button, Flex, Spacer, Switch, Text} from 'atoms';
import {SelectField, TextInputField} from 'components/global/final-form';

export const UserForm = ({onSubmit, user, isEdit}) => {
  const [roles, setRoles] = useState([]);
  const {userRoles} = useSelector(state => state.lab);

  useEffect(() => {
    const mappedRoles = userRoles.map(role => ({value: role, label: i18n.t(role)}));
    setRoles(mappedRoles);
  }, [userRoles]);

  return (
    <Form onSubmit={onSubmit} initialValues={user} style={{height: '100%'}}>
      {({handleSubmit, values, form}) => (
        <form onSubmit={handleSubmit}>
          <ModalContent>
            <TextInputField
              name="name_en"
              label={i18n.t('user_name_en')}
              placeholder={i18n.t('user_name_en')}
              validate={required}
            />
            <Spacer height={20}/>
            <TextInputField
              name="name_ar"
              label={i18n.t('user_name_ar')}
              placeholder={i18n.t('user_name_ar')}
              validate={required}
            />
            <Spacer height={20}/>
            <TextInputField
              name="email"
              label={i18n.t('email')}
              placeholder={i18n.t('email_address')}
              validate={composeValidators(required, email)}
            />
            <Spacer height={20}/>
            <TextInputField
              name="mobile"
              type="number"
              label={i18n.t('phone_number')}
              placeholder={i18n.t('phone')}
              validate={
                composeValidators(required, shouldStartWith(5), number, maxLength(9), minLength(9))
              }
            />
            <Spacer height={20}/>
            {!isEdit && (
              <>
                <TextInputField
                  name="password"
                  type="password"
                  label={i18n.t('password')}
                  placeholder={i18n.t('password')}
                  validate={required}
                />
                <Spacer height={20}/>
              </>
            )}
            <SelectField
              name="role"
              label={i18n.t('role')}
              placeholder={i18n.t('choose_role')}
              options={roles}
              validate={required}
            />
            {isEdit && (
              <>
                <Spacer height={28}/>
                <Flex itemsCenter justifyBetween>
                  <Text typography="caption12" weight="bold" color="var(--brand-text-gray-light)">
                    {i18n.t('user_activation').toUpperCase()}
                  </Text>
                  <Switch
                    checked={values.status}
                    onChange={(val) => form.change('status', val)}
                  />
                </Flex>
              </>
            )}
          </ModalContent>
          <Spacer height={20}/>
          <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
          <ModalContent>
            <Button
              label={i18n.t(isEdit ? 'save' : 'send_invitation')}
              onClick={() => onSubmit(values)}
            />
          </ModalContent>
        </form>
      )}
    </Form>
  );
};

UserForm.defaultProps = {
  isEdit: false,
  user: {}
};

UserForm.propTypes = {
  isEdit: PropTypes.bool,
  onSubmit: PropTypes.func,
  user: PropTypes.shape()
};
