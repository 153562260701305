import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';
import {DISPLAY_DATE_TIME_FORMAT, format} from 'utils/date';

import {DataTable, Tag} from 'atoms';

const BatchOrderTable = ({onSelectionChange}) => {
  const {batchDetails, fetching} = useSelector(state => state.orders);
  const [orders, setOrders] = useState([]);

  const handleSelectionModelChange = (orderIds) => {
    onSelectionChange(orderIds);
  };

  useEffect(() => {
    setOrders(batchDetails.orders);
  }, [batchDetails]);


  const columns = [
    {
      field: 'patient_name',
      headerName: i18n.t('patient'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => `${i18n.t('batch')} ${value}`
    },
    {
      field: 'collection_time',
      headerName: i18n.t('collection_time'),
      minWidth: 200,
      flex: 1,
      renderCell: ({value}) => format(value, DISPLAY_DATE_TIME_FORMAT)
    },
    {
      field: 'reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'status',
      headerName: i18n.t('status'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'result',
      headerName: i18n.t('result'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    }
  ];

  return (
    <DataTable
      checkboxSelection
      columns={columns}
      rows={orders}
      loading={fetching}
      onSelectionModelChange={handleSelectionModelChange}
    />
  );
};

BatchOrderTable.propTypes = {
  onSelectionChange: PropTypes.func
};

export default BatchOrderTable;
