import React from 'react';
import {useSelector, useDispatch} from 'react-redux';

import i18n from 'translation/i18n';

import {Text, Flex, Button} from 'atoms';
import {CardContainer} from 'templates';
import {printTestResults} from 'redux/tests/actionCreators';
import {ReactComponent as Printer} from 'assets/icons/common/printer.svg';
import ToolTipHandler from 'components/global/ToolTipHandler';
import OrderTestInfo from './OrderTestInfo';
import OrderPatientInfo from './OrderPatientInfo';
import '../styles.scss';

const PatientOrderInfo = () => {
  const dispatch = useDispatch();
  const {orderDetails} = useSelector(state => state.orders);
  const {submitting} = useSelector(state => state.tests);

  const onPrintClick = () => {
    dispatch(printTestResults(orderDetails.id))
      .then(({action}) => (
        window.open(action.payload.data.order.test_detail.report_url, '_blank')
      ));
  };

  return (
    <CardContainer
      className="order-info-container"
      customHeader={(
        <Flex itemsCenter justifyBetween>
          <Text typography="subtitle" weight='bold' color="var(--brand-text-black)" textCase="capitalize">
            {i18n.t('patient_order_info_title')}
          </Text>

          <ToolTipHandler title={i18n.t('test_results_has_not_generated_yet')} show={!orderDetails.test_detail?.report_url}>
            <Button 
              label={i18n.t('print_results')}
              startIcon={<Printer />} 
              onClick={onPrintClick}
              disabled={submitting || !orderDetails.test_detail?.report_url}
              fitContent
            />
          </ToolTipHandler>
        </Flex>
      )}
      noPadding
      showBackButton
    >
      <OrderTestInfo orderInfo={orderDetails}/>
      <OrderPatientInfo orderInfo={orderDetails}/>
    </CardContainer>
  );
};

export default PatientOrderInfo;
