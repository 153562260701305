import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Avatar, Menu, MenuItem} from '@mui/material';

import i18n from 'translation/i18n';
import {removeAuthUser, getAuthUser} from 'redux/auth/actionCreators';

import {Text} from 'atoms';
import {USER_ROLE} from 'models';
import './styles.scss';

const UserInfo = (props) => {
  const {hideProfileOption} = props;
  const {push, location} = useHistory();
  const userInfo = getAuthUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [user, setUser] = useState({});
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    removeAuthUser();
    switch (user.role_label) {
    case USER_ROLE.PATIENT:
      return push('/patient/login');

    case USER_ROLE.CLINIC:
      return push('/clinic/login');

    case USER_ROLE.SUPER_ADMIN:
      return push('/admin/login');

    default:
      return push('/login');
    }
  };

  const onProfileClick = () => {
    const isInProfile = location.pathname.endsWith('lab-profile');
    if(!isInProfile){
      push('/lab-profile');
    }
  };

  useEffect(() => {
    setUser(userInfo);
  }, []);

  return (
    <div className="user-info">
      <Text typography="paragraph14" onClick={onProfileClick}>
        {user.name_i18n}
      </Text>
      <Avatar
        id="profile-pic"
        sx={{marginLeft: '1rem'}}
        aria-label="more"
        aria-controls={open ? 'profile-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      />
      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{'aria-labelledby': 'profile-pic'}}
      >
        {!hideProfileOption && (
          <MenuItem onClick={onProfileClick}>
            <Text typography="paragraph14" weight="bold">{i18n.t('profile')}</Text>
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          <Text typography="paragraph14" weight="bold">{i18n.t('logout')}</Text>
        </MenuItem>
      </Menu>
    </div>
  );
};

UserInfo.propTypes = {
  hideProfileOption: PropTypes.bool,
};

export default UserInfo;
