import React from 'react';

import {ShareLink} from 'templates';

const LabShare = () => {
  return (
    <>
      <ShareLink titleKey="invite_clinic_to_lab" link="https://example.linktolab/"/>
      <ShareLink titleKey="invite_patient_to_lab" link="https://example.linktopatient/"/>
    </>
  );
};

export default LabShare;
