import store from 'redux/store';
import {useHistory} from 'react-router-dom';
import {ACCESS_TOKEN} from 'utils/constants';
import {removeAuthUser, getAuthUser} from 'redux/auth/actionCreators';
import {USER_ROLE} from 'models';

/*
  this function for handle global error response from axios
*/

function errorResponseHandler(error) {
  const { response } = error;
  const user = getAuthUser();
  const {push} = useHistory();

  if (response) {
    switch (response.status) {
    case 401:
      store.dispatch(removeAuthUser())
        .then(()  => {
          if(user.role_label === USER_ROLE.PATIENT){
            push('/patient/login');
          }else{
            push('/login');
          }
        });
      break;

    case 403:
      if (localStorage.getItem(ACCESS_TOKEN)) {
        window.location.href = '/';
      }
      break;

    default:
      break;
    }
  }

  return Promise.reject(error);
}

export default errorResponseHandler;
