import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import i18n from 'translation/i18n';
import {
  Alert,
  Modal,
  Button,
} from 'atoms';
import {ModalHeader} from 'templates';
import { isStepNotValid } from 'utils/labUtils';
import ToolTipHandler from 'components/global/ToolTipHandler';

import { ReactComponent as AddIcon } from 'assets/icons/common/add-square.svg';
import {addPatient, labPatientOptionsLoad} from 'redux/patients/actionCreators';
import {testsLoad} from 'redux/tests/actionCreators';
import {ACTIVE_STATUS} from 'models';
import NewPatientForm from './NewPatientForm';

const steps = {
  1: [{parent: 'patient', child: 'name_en'},{parent: 'patient', child: 'name_ar'}, {parent: 'patient', child: 'gender'}, {parent: 'patient', child: 'mobile'},{parent: 'patient', child: 'email'}],
  2: [{parent: 'patient', child: 'nationality_id'},{parent: 'patient', child: 'date_of_birth'}, {parent: 'patient', child: 'address'}, {parent: 'patient', child: 'id_type'},{parent: 'patient', child: 'personal_id'}],
  3: [{parent: 'patient', child: 'occupation'},{parent: 'order', child: 'reason_for_testing'}, {parent: 'order', child: 'priority'}],
  4: [],
  5: [{parent: 'appointment', child: 'time'}],
};

const NewPatient = ({ onDone }) => {
  const dispatch = useDispatch();
  const {services, fetching} = useSelector(state => state.tests);
  const [activeStep, setActiveStep] = React.useState(1);
  const [showModal, setShowModal] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);
  const activeTests = services.filter(service => service.status === ACTIVE_STATUS.ACTIVE);

  const onSubmit = (values) => {
    setShowAlert(false);
    const isEmptyField = isStepNotValid(values, activeStep, steps);
    if(isEmptyField){
      setShowAlert(true);
      return false;
    }
    if (activeStep < 5) {
      return setActiveStep(activeStep + 1);
    }
    return dispatch(addPatient(values))
      .then(({ action }) => {
        setShowModal(false);
        if (onDone && action.payload.data.success) {
          setActiveStep(1);
          onDone();
        }
      });
  };

  const onBack = () => {
    setActiveStep(activeStep - 1);
  };

  React.useEffect(() => {
    dispatch(testsLoad());
    dispatch(labPatientOptionsLoad());
  }, []);

  return (
    <>
      <ToolTipHandler 
        show={!activeTests.length}
        title={i18n.t('no_active_tests_tootip_title')}
      >
        <Button
          label={i18n.t('add_patient')}
          startIcon={<AddIcon />}
          onClick={() => setShowModal(true)}
          disabled={fetching || !activeTests.length}
        />
      </ToolTipHandler>
      <Modal
        header={<ModalHeader title={i18n.t('add_patient')} onBack={activeStep === 1 ? null : () => onBack() } />}
        isOpen={showModal}
        onClose={() => {
          setActiveStep(1);
          setShowModal(false);
          setShowAlert(false);
        }}
        width={350}
        disableCloseOut
      >
        <NewPatientForm
          onSubmit={onSubmit}
          activeStep={activeStep}
        />
        <Alert
          message={i18n.t('all_fields_are_required')}
          variant="danger"
          placement="bottom"
          show={showAlert}
          onClose={() => setShowAlert(false)}
        />
      </Modal>
    </>
  );
};

NewPatient.propTypes = {
  onDone: PropTypes.func,
};

export default NewPatient;
