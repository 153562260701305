import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {Grid} from '@mui/material';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {
  CardContainer,
  CardSectionHeader,
  FieldInfoCardList,
  ModalContent,
  CustomImageUploader,
} from 'templates';
import {Flex, Text, Spacer} from 'atoms';
import {profileInfo} from 'utils/labUtils';
import TempLogo from 'assets/icons/logo/tashkhes-logo-blue.svg';
import BackButton from 'components/global/BackButton';
import {LabDetailsActions} from '.';

const LabGeneralInfo = (props) => {
  const {onDone} = props;
  const {service_provider} = useSelector(state => state.labs);
  const [labLogo, setLabLogo] = React.useState(null);
  const info = !isEmpty(service_provider) 
    ? profileInfo(true, {...service_provider, is_admin_role: true}) : {};

  React.useEffect(()=> {
    if(!isEmpty(service_provider)){
      setLabLogo(service_provider.logo_url);
    }
  },[service_provider.logo_url]);

  return (
    <CardContainer noPadding style={{width: '100%'}}>
      <ModalContent padding="32px 24px">
        <BackButton/>
        <Spacer height={16}/>
        <Flex justifyBetween itemsCenter>
          <Flex itemsCenter>
            <CustomImageUploader
              imgSrc={labLogo || TempLogo}
              altLabel="Stamp"
              disabled
              variant="simple"
            />
            <Spacer width={24}/>
            <Text typography="title" weight="bold">
              {service_provider.name_i18n || i18n.t('lab_name')}
            </Text>
          </Flex>
          <>
            <LabDetailsActions data={service_provider} onDone={onDone}/>
          </>
        </Flex>
      </ModalContent>

      <CardSectionHeader title={i18n.t('general_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          {!isEmpty(info) && <FieldInfoCardList list={info.general} as="text"/>}
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('location_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          {!isEmpty(info) &&  <FieldInfoCardList list={info.location} as="text"/> }
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('privacy_info')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          {!isEmpty(info) && <FieldInfoCardList list={info.privacy} as="text"/> }
        </Grid>
      </ModalContent>
      <CardSectionHeader title={i18n.t('lab_statistics')} uppercased/>
      <ModalContent>
        <Grid container spacing='16px' sx={{padding: '16px'}}>
          {!isEmpty(info) && <FieldInfoCardList list={info.lab_statistics} as="text"/> }
        </Grid>
      </ModalContent>
    </CardContainer>
  );
};

LabGeneralInfo.propTypes = {
  onDone: PropTypes.func,
};

export default LabGeneralInfo;
