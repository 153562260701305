import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Users from 'container/labs/Users';

const UsersRoutes = () => (
  <Switch>
    <Route path="/users"  component={Users}/>
  </Switch>
);

export default UsersRoutes;
