import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {getUserOptions, getUsers} from 'redux/lab/actionCreators';
import {Card} from 'atoms';
import {ListFilters} from 'components/hoc';
import {UserFilters} from './UserFilters';
import {UserTable} from './UserTable';

export const UserList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(getUserOptions());
  });

  const handleFilterChange = (filters) => {
    const params = {
      search: filters.search,
      status: filters.status,
      role: filters.role
    };
    dispatch(getUsers(params));
  };

  return (
    <Card styles={{margin: '2rem'}}>
      <ListFilters Wrapped={UserFilters} onChange={handleFilterChange}/>
      <UserTable/>
    </Card>
  );
};
