import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  prices: []
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.PATIENT_BOOK_TEST_PENDING:
  case actions.ADD_PATIENT_PENDING:
  case actions.GET_PRICES_LOAD_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.PATIENT_OPTIONS_LOAD_PENDING:
  case actions.GET_PATIENT_INVOICE_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.PATIENT_BOOK_TEST_FULFILLED:
  case actions.ADD_PATIENT_FULFILLED:
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      submitting: false,
    };

  case actions.GET_PRICES_LOAD_FULFILLED:
    return {
      ...state,
      ...action.payload.data,
      submitting: false,
    };

  case actions.PATIENT_OPTIONS_LOAD_FULFILLED:
  case actions.GET_PATIENT_INVOICE_FULFILLED:
    return {
      ...state,
      ...action.payload.data,
      fetching: false,
    };

  case actions.PATIENT_BOOK_TEST_REJECTED:
  case actions.ADD_PATIENT_REJECTED:
  case actions.GET_PRICES_LOAD_REJECTED:
    Toastr.error(handleResponseErr(action.payload));

    return {
      ...state,
      submitting: false,
    };

  case actions.PATIENT_OPTIONS_LOAD_REJECTED:
  case actions.GET_PATIENT_INVOICE_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      fetching: false,
    };

  default:
    return state;
  }
}
