import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as CloseIcon } from 'assets/icons/common/cross-rounded.svg';
import { Spacer } from 'atoms';
import { useOnClickOutside } from './helper';

import Styles from './Modal.module.css';

const Modal = ({
  disableCloseOut,
  showCloseButton,
  trigger,
  header,
  footer,
  content,
  variant,
  children,
  isOpen,
  onClose,
  width,
  minWidth,
}) => {
  const [open, setOpen] = React.useState(false);
  const [moveOut, setMoveOut] = React.useState(false);
  const paper = React.useRef();

  const handleModalToggle = () => {
    if (open) {
      setMoveOut(true);
      setTimeout(() => {
        setOpen(false);
        setMoveOut(false);
        onClose();
      }, 500);
    } else {
      setOpen(true);
    }
  };

  const handleOnclose = () => {
    if (onClose) {
      onClose();
      setOpen(!open);
    } else {
      handleModalToggle();
    }
  };

  React.useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useOnClickOutside(paper, handleModalToggle, !disableCloseOut);
  // on click outside

  return (
    <>
      {trigger && React.cloneElement(trigger, {
        onClick: handleModalToggle,
      })}

      {open && ReactDOM.createPortal(
        <div className={clsx(
          Styles.modal,
          moveOut && Styles.moveOut,
          variant && Styles[variant],
        )}
        >
          <div style={{ width, minWidth }} className={clsx(Styles.modalPaper)} ref={paper}>
            {header && (
              <>
                <div className={clsx(Styles.modalHeader)}>
                  <div>
                    {header}
                  </div>
                  {
                    showCloseButton && (
                      <CloseIcon
                        className={clsx(Styles.closeBtn)}
                        onClick={handleOnclose}
                      />
                    )
                  }
                </div>
                <Spacer height={1} width='100%' style={{ backgroundColor: 'var(--brand-divider-white)' }} />
                <Spacer height={16} />
              </>
            )}
            {children && (
              <div className={clsx(Styles.modalContent)}>
                {children}
              </div>
            )}
            {content && (
              <div className={clsx(Styles.modalContent)}>
                {content}
              </div>
            )}
            {footer && (
              <>
                <Spacer height={1} width='100%' style={{ backgroundColor: 'var(--brand-divider-white)' }} />
                <Spacer height={16} />
                <div className={clsx(Styles.modalFooter)}>
                  {footer}
                </div>
              </>
            )}
          </div>
        </div>,
        document.body,
      )}
    </>
  );
};

Modal.defaultProps = {
  showCloseButton: true,
  variant: 'centerDialog',
  width: 'auto',
};

Modal.propTypes = {
  footer: PropTypes.node,
  trigger: PropTypes.node,
  header: PropTypes.node,
  content: PropTypes.node,
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  disableCloseOut: PropTypes.bool,
  onClose: PropTypes.func,
};

export default Modal;
