import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {dateToQueryFormat} from 'utils/date';
import {getBatchOrders} from 'redux/orders/actionCreators';

import {Card} from 'atoms';
import {ListFilters} from 'components/hoc';
import BatchOrderListFilters from './BatchOrderListFilters';
import BatchOrderListTable from './BatchOrderListTable';

const BatchOrderList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatchOrders());
  });

  const handleFilterChange = filter => {
    const params = {
      search: filter.search,
      status: filter.status,
      created_at: filter.date ? dateToQueryFormat(filter.date) : null
    };
    dispatch(getBatchOrders(params));
  };

  return (
    <Card styles={{margin: '2rem'}}>
      <ListFilters Wrapped={BatchOrderListFilters} onChange={handleFilterChange}/>
      <BatchOrderListTable/>
    </Card>
  );
};

export default BatchOrderList;
