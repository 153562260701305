import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import defineComponentName from 'utils/defineComponentName';
import {Text, Flex, Spacer} from 'atoms';

import textStyles from 'atoms/Text/styles.module.css';
import styles from './styles.module.css';

import { ReactComponent as SuccessIcon } from './icons/successIcon.svg';
import { ReactComponent as WarningIcon } from './icons/warningIcon.svg';
import { ReactComponent as ErrorIcon } from './icons/errorIcon.svg';
import { ReactComponent as ClearIcon } from './icons/clear.svg';

const statusIcons = {
  error: <ErrorIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
};

const TextInput = React.forwardRef(({
  label,
  optionalLabel,
  id,
  as,
  name,
  value,
  onChange,
  disabled,
  className,
  status,
  placeholder,
  statusMessage,
  style,
  inputStyle,
  suffix,
  inputClass,
  endIcon,
  startIcon,
  prefix,
  prefixOptions,
  prefixValueKey,
  prefixLabelKey,
  prefixAttrs,
  isClearable,
  direction,
  optionalLabelOnClick,
  ...rest
}, ref) => {
  const Tag = React.useMemo(() => (['input', 'textarea'].includes(as) ? as : 'input'), [as]);
  const inputStatus = React.useMemo(() => (['warning', 'success', 'error'].includes(status) ? status : ''), [status]);
  const clearable = (!!value && !disabled && isClearable);
  const isText = (Tag === 'input');

  return (
    <div
      className={clsx(
        styles.container,
        className,
      )}
      disabled={disabled}
      style={style}
    >
      {label && (
        <>
          <Flex justifyBetween={!!optionalLabel} justifyStart={!optionalLabel}>
            <Text
              typography="caption12"
              as="span"
              weight="bold"
              color="var(--brand-text-gray-light)"
            >
              {label.toUpperCase()}
            </Text>
            {optionalLabel && (
              <Text
                typography="caption10"
                as="small"
                weight="medium"
                color="var(--brand-text-gray-light)"
                onClick={optionalLabelOnClick && optionalLabelOnClick}
                style={{ userSelect: optionalLabelOnClick && 'none', cursor: optionalLabelOnClick && 'pointer'}}
              >
                {optionalLabel}
              </Text>
            )}
          </Flex>
          <Spacer height="8px" />
        </>
      )}

      <label
        className={clsx(styles.subContainer, styles[inputStatus])}
        htmlFor={id}
      >
        <div
          className={clsx(
            styles.inputBox,
          )}
        >
          {(startIcon && isText) && (
            <div className={clsx(styles.startIconBox)}>
              {startIcon}
            </div>
          )}
          <Tag
            ref={ref}
            placeholder={placeholder}
            className={clsx(
              styles.inputTag,
              styles[inputStatus],
              textStyles.paragraph14,
              inputClass,
              !!endIcon && styles.endIconStyle,
              !!startIcon && styles.startIconStyle,
              clearable && styles.clearableStyle,
            )}
            style={inputStyle}
            disabled={disabled}
            id={id}
            name={name || id}
            value={value}
            autoComplete="off"
            dir={direction}
            onChange={onChange}
            {...rest}
          />
          {((clearable || !!endIcon) && isText) && (
            <Flex itemsCenter className={clsx(styles.endIconBox)}>
              {clearable && (
                <ClearIcon
                  onClick={() => onChange({ target: { value: '' } })}
                  className={clsx(styles.clearIcon)}
                />
              )}
              {(clearable && endIcon) && <div className={clsx(styles.iconsDivider)} />}
              {endIcon && endIcon}
            </Flex>
          )}
        </div>
        {(suffix && isText) && (
          <div
            className={clsx(styles.suffixBox)}
          >
            <Text typography="paragraph14">{suffix}</Text>
          </div>
        )}
      </label>

      {inputStatus && (
        <>
          <Spacer height="8px" />
          <Flex itemsCenter>
            {statusIcons[inputStatus]}
            <Spacer width="8px" />
            <Text
              typography="caption12"
              as="small"
              weight="medium"
              color="var(--color-natural-black-light)"
              style={{ flex: 1 }}
            >
              {statusMessage}
            </Text>
          </Flex>
        </>
      )}
    </div>
  );
});

defineComponentName(TextInput, 'TextInput');

TextInput.defaultProps = {
  disabled: false,
  direction: 'ltr'
};

TextInput.propTypes = {
  isFocusRingVisible: PropTypes.bool,
  as: PropTypes.node,
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.shape(),
  inputStyle: PropTypes.shape(),
  prefixAttrs: PropTypes.shape(),
  label: PropTypes.string,
  inputClass: PropTypes.string,
  optionalLabel: PropTypes.string,
  statusMessage: PropTypes.string,
  suffix: PropTypes.string,
  prefixValueKey: PropTypes.string,
  prefixOptions: PropTypes.arrayOf(PropTypes.shape()),
  prefixLabelKey: PropTypes.string,
  prefix: PropTypes.bool,
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  optionalLabelOnClick: PropTypes.func,
};

export default TextInput;
