import React from 'react';
import i18n from 'translation/i18n';
import {Avatar} from '@mui/material';
import {
  Spacer,
  Flex,
  Text,
} from 'atoms';
import {ModalContent} from 'templates';

import {TextInputField} from 'components/global/final-form';
import {
  composeValidators,
  shouldStartWith,
  maxLength,
  minLength,
  required,
  number,
  email,
} from 'utils/FormValidations';
import {ReactComponent as DefaultLabIcon} from 'assets/icons/login-flow/lab-default.svg';

const LabStep1 = () => {
  return(
    <ModalContent>
      <Spacer height={4} />
      <Flex itemsCenter>
        <Avatar sx={{ width: 50, height: 50 }}>
          <DefaultLabIcon />
        </Avatar>
        <Spacer width={11} />
        <Text typography="caption12" color="var(--brand-text-primary)">
          {i18n.t('change_logo')}
        </Text>
      </Flex>
      <Spacer height={32} />
      <TextInputField
        name="service_provider.name_en"
        placeholder={i18n.t('name')}
        label={i18n.t('name_en').toUpperCase()}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="service_provider.name_ar"
        placeholder={i18n.t('name')}
        label={i18n.t('name_ar').toUpperCase()}
      />
      <Spacer height={20} />
      <TextInputField
        name="service_provider.slug"
        placeholder={i18n.t('slug')}
        label={i18n.t('slug').toUpperCase()}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="user.email"
        placeholder={i18n.t('email')}
        label={i18n.t('email_address').toUpperCase()}
        validate={composeValidators(required, email)}
      />
      <Spacer height={20} />
      <TextInputField
        name="user.mobile"
        placeholder={i18n.t('phone')}
        label={i18n.t('phone_number').toUpperCase()}
        validate={
          composeValidators(required, shouldStartWith(5), number, maxLength(9), minLength(9))
        }
        maxLength={9}
      />
    </ModalContent>
  );
};

export default LabStep1;
