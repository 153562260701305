import React from 'react';
import {ModalContent} from 'templates';
import {PaymentFields} from 'components/public';

const PaymentStep = () => {
  return (
    <ModalContent>
      <PaymentFields isAdmin/>
    </ModalContent>
  );
};

export default PaymentStep;
