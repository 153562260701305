import {handleAPI, API_VERSION_ONE, removeEmptyParams} from 'utils/apiUtils';
import {dateToQueryFormat} from 'utils/date';
import {isClinicUser} from 'utils/labUtils';
import {CLINIC_LABEL, LAB_LABEL} from 'utils/constants';

import * as actions from './actions';

const baseUrl = () => {
  const module = isClinicUser() ? CLINIC_LABEL : LAB_LABEL;
  return `/${API_VERSION_ONE}/${module}`;
};

export function getOverviewStats(startDate, endDate) {
  const url = `${baseUrl()}/statistics`;
  const method = 'GET';
  const params = {
    start_date: dateToQueryFormat(startDate),
    end_date: dateToQueryFormat(endDate)
  };

  return {
    type: actions.OVERVIEW_STATS,
    payload: handleAPI(url, params, method),
  };
}

export function getReservations(params = {}) {
  const url = `${baseUrl()}/patients`;
  const method = 'GET';

  return {
    type: actions.RESERVATION_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getReservationDetails(id) {
  const url = `${baseUrl()}/patients/${id}`;
  const method = 'GET';

  return {
    type: actions.RESERVATION_INFO,
    payload: handleAPI(url, {}, method),
  };
}

export function getUsers(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/users`;
  const method = 'GET';

  return {
    type: actions.USER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getUserOptions() {
  const url = `/${API_VERSION_ONE}/lab/users/options`;
  const method = 'GET';

  return {
    type: actions.USER_OPTIONS,
    payload: handleAPI(url, {}, method),
  };
}

export function createUser(data) {
  const url = `/${API_VERSION_ONE}/lab/users`;
  const method = 'POST';

  return {
    type: actions.CREATE_USER,
    payload: handleAPI(url, {}, method, data)
  };
}

export function getUser(userId) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'GET';

  return {
    type: actions.GET_USER,
    payload: handleAPI(url, {}, method)
  };
}

export function updateUser(userId, data) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'PUT';

  return {
    type: actions.UPDATE_USER,
    payload: handleAPI(url, {}, method, data)
  };
}

export function deleteUser(userId) {
  const url = `/${API_VERSION_ONE}/lab/users/${userId}`;
  const method = 'DELETE';

  return {
    type: actions.DELETE_USER,
    payload: handleAPI(url, {}, method)
  };
}
