import React from 'react';
import PropTypes from 'prop-types';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';

const CurrentStep = ({ activeStep }) => {
  const step = {
    1: <Step1 />,
    2: <Step2 />,
    3: <Step3 />,
    4: <Step4 />,
    5: <Step5 />,
  };

  return step[activeStep];
};

CurrentStep.propTypes = {
  activeStep: PropTypes.number,
};

export default CurrentStep;