import React from 'react';
import {NavLink} from 'react-router-dom';

import i18n from 'translation/i18n';

import {Flex, Text} from 'atoms';
import UserInfo from 'components/global/UserInfo';
import {ReactComponent as Search} from 'assets/icons/common/search.svg';
import {ReactComponent as Notification} from 'assets/icons/common/bell.svg';

import './styles.scss';

const PatientHeader = () => {
  return (
    <Flex className="patient-header" justifyBetween>
      <Flex itemsCenter>
        <NavLink activeClassName="is-active" to="/tests">
          <Text typography="headline" weight="bold">{i18n.t('tests')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/book-test">
          <Text typography="headline" weight="bold">{i18n.t('book_test')}</Text>
        </NavLink>
      </Flex>
      <Flex style={{marginTop: '1rem'}}>
        <div className="page-actions">
          <Search/>
          <Notification/>
        </div>
        <UserInfo hideProfileOption/>
      </Flex>
    </Flex>
  );
};

export default PatientHeader;
