import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {getStatusColor} from 'utils/labUtils';
import {DISPLAY_DATE_TIME_FORMAT, format} from 'utils/date';

import {Flex, Tag} from 'atoms';
import {InfoCard, InfoCards, CardSectionHeader} from 'templates';

const OrderTestInfo = ({orderInfo}) => {
  const result = valueGetter(orderInfo, 'test_detail.overall_result');
  const status = valueGetter(orderInfo, 'status');

  const items = [
    {key: 'reservation_id', value: valueGetter(orderInfo, 'reservation_id')},
    {key: 'priority', value: i18n.t(valueGetter(orderInfo, 'patient_detail.priority'))},
    {key: 'test_name', value: valueGetter(orderInfo, 'service.name')},
    {key: 'reservation_date', value: format(valueGetter(orderInfo, 'appointment.time'), DISPLAY_DATE_TIME_FORMAT)},
    {key: 'result', value: result && i18n.t(`result_${result}`)}
  ];

  return (
    <>
      <CardSectionHeader title={i18n.t('test_information')} uppercased/>
      <Grid container px={3} py={2} spacing={3}>
        <InfoCards items={items}/>
        <Grid item xs={4}>
          <InfoCard titleKey="status" isCustom>
            <Flex itemsCenter>
              <Tag text={i18n.t(`status_${status}`)} color={getStatusColor(status)}/>
            </Flex>
          </InfoCard>
        </Grid>
      </Grid>
    </>
  );
};

OrderTestInfo.propTypes = {
  orderInfo: PropTypes.shape()
};

export default OrderTestInfo;
