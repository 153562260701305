const Tests = {
  // *

  // A
  all_tests: 'All tests',
  activation: 'Activation',
  active: 'Active',
  add_card: 'Add Card',
  apple_pay: 'Apple Pay',
  
  // B
  book_test: 'Book Test',

  // C
  choose_your_test: 'Choose your test',

  // D

  // E

  // F

  // G

  // H

  // I
  inactive: 'Inactive',

  // J

  // K

  // L

  // M
  mada: 'MADA',

  // N

  // O

  // P
  price: 'Price',
  price_per_patient: 'Price per patient',

  // Q

  // R
  request_a_test: 'Request a Test',
  request_test: 'Request Test',
  reference_range: 'Reference range',

  // S
  search_by_test_name: 'Search by test name',
  send_request: 'Send Request',
  sat: 'SAT',
  summary: 'Summary',

  // T
  tests: 'Tests',
  tests_list_subtitle: 'Tests list subtitle',
  test_name: 'Test Name',
  test_type: 'Test Type',
  test_price: 'Test Price',
  test_priority: 'Test Priority',
  type: 'Type',
  test_information: 'Test information',
  test_code: 'Test code',
  type_of_specimen: 'Type of specimen',
  test_description: 'Test description',
  test_status: 'Test status',
  transaction: 'Transaction',
  test_results_has_not_generated_yet: 'The test results has not generated yet!',

  // U
  update_test: 'Update Test',
  update_price: 'Update Price',

  // V

  // W

  // X

  // Y

  // Z

};

export default Tests;
