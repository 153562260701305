import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
// import {useHistory} from 'react-router-dom';
import {Card} from 'atoms';
import {testsLoad, testUpdate} from 'redux/tests/actionCreators';
import {ACTIVE_STATUS} from 'models';
import {TestsTable} from '.';
import TestFilters from '../TestFilters';

const checkValues = {
  input: true,
  span: true,
  svg: true,
};

const TestList = () => {
  const dispatch = useDispatch();
  // const {push} = useHistory();
  const {message, services} = useSelector(state => state.tests);

  const loadServices = () => dispatch(testsLoad());

  const handleRowClick = (values, inputTarget) => {
    const {id} = values.row;

    if(checkValues[inputTarget.target.nodeName.toLowerCase()]){
      if(inputTarget.target.id === 'activeSwitch'){
        const activated = inputTarget.target.checked;
        const status = {status: activated ? ACTIVE_STATUS.ACTIVE : ACTIVE_STATUS.INACTIVE};
        return dispatch(testUpdate(id, {service_priority: status}))
          .then(() => dispatch(testsLoad()));
      }
      if(inputTarget.target.id === 'more-pic'){
        return false;
      }
      return false;
    }
    return null;
    // push(`/tests/${id}`, {service_id, priority: name});
  };

  React.useEffect(() => {
    loadServices();
  }, []);

  return (
    <Card styles={{margin: '2rem'}}>
      <TestFilters/>
      <TestsTable
        list={services} 
        onRowClick={handleRowClick}
        onDone={loadServices}
        noData={message}
      />
    </Card>
  );
};

export default TestList;
