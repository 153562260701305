export const GET_ADMIN_SERVICE_PROVIDERS = 'GET_ADMIN_SERVICE_PROVIDERS';
export const GET_ADMIN_SERVICE_PROVIDERS_PENDING = 'GET_ADMIN_SERVICE_PROVIDERS_PENDING';
export const GET_ADMIN_SERVICE_PROVIDERS_FULFILLED = 'GET_ADMIN_SERVICE_PROVIDERS_FULFILLED';
export const GET_ADMIN_SERVICE_PROVIDERS_REJECTED = 'GET_ADMIN_SERVICE_PROVIDERS_REJECTED';

export const UPDATE_ADMIN_SERVICE_PROVIDERS = 'UPDATE_ADMIN_SERVICE_PROVIDERS';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING = 'UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED = 'UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED';
export const UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED = 'UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED';

export const GET_ADMIN_SERVICE_PROVIDER_INFO = 'GET_ADMIN_SERVICE_PROVIDER_INFO';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING = 'GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED = 'GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED';
export const GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED = 'GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED';

export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS = 'GET_SERVICE_PROVIDER_SUBSCRIPTIONS';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING = 'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED = 'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED';
export const GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED = 'GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED';

export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION = 'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING = 'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED = 'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED';
export const UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED = 'UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED';
