import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {Text} from 'atoms';
import {OverviewStatCard} from 'components/overview';
import {Box} from '@mui/material';

import i18n from 'translation/i18n';

export const OverviewStats = () => {
  const [stats, setStats] = useState({});
  const {statistics} = useSelector(state => state.lab);

  useEffect(() => {
    const value = statistics?.total_status || {};
    setStats(value);
  }, [statistics]);

  return (
    <Box py={2} px={5}>
      <Text typography="subtitle" weight="bold">{i18n.t('overview_stats_title')}</Text>
      <Text typography="caption13" style={{opacity: 0.4}}>
        {i18n.t('overview_stats_subtitle')}
      </Text>
      <Box display="flex" justifyContent="space-between" mt={2}>
        {Object.keys(stats).map(key => (
          <OverviewStatCard key={key} value={stats[key]} title={key}/>
        ))}
      </Box>
    </Box>
  );
};
