import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {useDispatch} from 'react-redux';

import { 
  Button,
  Text,
} from 'atoms';
import { ReactComponent as AddIcon } from 'assets/icons/common/add-square.svg';
import {testUpdate}from 'redux/tests/actionCreators';

import RequestTestModal from './RequestTestModal';

const RequestTest = (props) => {
  const {initialValues, linkButton, onDone} = props;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);
  
  const handleSubmit = (values) => {
    if(initialValues?.id){
      const {service} = values;
      delete service.status;
      
      dispatch(testUpdate(values.id, {service}))
        .then(() => {
          setShowModal(false);
          onDone();
        });
    }
  };
  
  return (
    <>
      {linkButton ? 
        (
          <Text 
            typography="paragraph14" 
            weight='bold' 
            color="var(--brand-text-primary)" 
            textCase="capitalize"
            onClick={() => setShowModal(true)}
          >
            {i18n.t('update_price')}
              
          </Text>
        )
        :
        (
          <Button
            label={i18n.t('request_a_test')}
            startIcon={<AddIcon />}
            onClick={() => setShowModal(true)}
          />
        )}
      <RequestTestModal 
        open={showModal}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        onClose={() => setShowModal(false)}
      />
    </>
  );
};

RequestTest.propTypes = {
  initialValues: PropTypes.shape(),
  linkButton: PropTypes.bool,
  onDone: PropTypes.func,
};

export default RequestTest; 