const Lab = {
  // *

  // A
  all_subscriptions: 'All subscriptions',
  all_labs: 'All Labs',
  app_title: 'Tashkhes',
  active: 'Active',
  activated: 'Activated',
  activation: 'Activation',
  activate_deactivate: 'Activate / Deactivate',
  accept_reject: 'Accept / Reject',
  accepted: 'Accepted',

  // B
  batch: '{{batchNumber}} Batch',
  batch_created: 'Batch Created',
  batch_order_list_title: 'Batches',
  batch_order_list_subtitle: 'Overview of all samples reserved.',
  batch_order_search_input_placeholder: 'Search by batch or created by',
  billing: 'Billing',
  booked_orders_count: 'Booked',

  // C
  change_status: 'Change Status',
  choose_role: 'Choose role',
  clinic_patients: 'Clinic Patients',
  clinics: 'Clinics',
  completed_orders_count: 'Done',
  collected_orders_count: 'Collected',
  collection_time: 'Collection Time',
  confirmed_orders_count: 'Confirmed',
  create_batch: 'Create Batch',
  create_user: 'Create User',
  created_at: 'Created At',
  created_by: 'Created By',
  create_lab_1: 'Create lab (1)',
  create_lab_2: 'Create lab (2)',
  choose_plan: 'Choose plan',
  confirmed_orders: 'Confirmed orders',
  current_subscription: 'Current subscription',

  // D
  deactivated: 'Deactivated',
  delete_user: 'Delete User',
  duration: 'Duration',

  // E
  edit_user: 'Edit User',
  export_csv: 'Export CSV',
  expand_free_trial_days: 'Expand the free trial days',
  expire_date: 'Expire date',

  // F

  // G
  go_to_batches: 'Go to batches',

  // H
  hospital: 'Hospital',

  // I
  inactive: 'Inactive',
  invite_clinic_to_lab: 'Invite clinic to lab',
  invite_patient_to_lab: 'Invite patient to lab',
  invoices: 'Invoices',

  // J

  // K

  // L
  labs: 'Labs',
  lab_name: 'Lab name',
  lab_accountant: 'Lab Accountant',
  lab_accountant_manager: 'Lab Accountant Manager',
  lab_admin: 'Lab Admin',
  lab_doctor: 'Lab Doctor',
  lab_operator: 'Lab Operator',
  lab_patients: 'Lab Patients',
  lab_supervisor: 'Lab Supervisor',
  lab_technician: 'Lab Technician',
  lab_technologist: 'Lab Technologist',
  lab_list_subtitle: 'Lab list subtitle',
  lab_statistics: 'Lab statistics',

  // M

  // N
  nationality: 'Nationality',
  new_user: 'New User',
  normal: 'Normal',
  no_of_patients: 'No. of Patients',
  new_lab: 'New Lab',

  // O
  order_id: 'Order ID',
  order_info_title: 'Order Details',
  patient_order_info_title: 'Test Details',
  order_list_title: 'All Orders',
  order_list_subtitle: 'Overview of all samples reserved.',
  order_search_input_placeholder: 'Search by patient, phone, test or reservation ID',
  order_tracking: 'Order Tracking',
  order_type_single: 'Single',
  order_type_batch: 'Batch',
  orders: 'Orders',
  overdue: 'Overdue',
  overview: 'Overview',
  overview_overdue_title: 'Overdue Tests',
  overview_overdue_subtitle: 'Tests have been overdued in different phases.',
  overview_requests_title: 'New Requests',
  overview_requests_subtitle: 'Latest tests requests and its states.',
  overview_stats_title: 'All Tests',
  overview_stats_subtitle: 'Overview of this month\'s numbers.',

  // P
  password: 'Password',
  patient: 'Patient',
  patient_information: 'Patient Information',
  patient_order_list_title: 'All Tests',
  patient_order_list_subtitle: 'Overview of all samples reserved.',
  patient_order_search_input_placeholder: 'Search by patient, test or reservation ID',
  print_barcode: 'Print Barcode',
  print_invoice: 'Print Invoice',
  printing: 'Printing',
  processing_orders_count: 'Processing',
  plan: 'Plan',

  // Q

  // R
  reason: 'Reason',
  reason_for_cancelled: 'Reason for cancellation',
  reason_for_rejected: 'Reason for rejection',
  reason_placeholder: 'Type...',
  remove_from_batch: 'Remove from batch',
  reservations: 'Reservations',
  reservation_date: 'Reservation Date',
  reservation_id: 'Reservation ID',
  reservation_info_title: 'Reservation Details',
  reservation_list_title: 'Reservation Patients',
  reservation_list_subtitle: 'Overview of all current patients.',
  reservation_search_input_placeholder: 'Search by patient, phone or reservation ID',
  result: 'Result',
  result_negative: 'Negative (-)',
  result_pending: 'Pending',
  result_positive: 'Positive (+)',
  results: 'Results',
  role: 'Role',
  rejected: 'Rejected',
  reserved_orders: 'Reserved orders',
  renew: 'Renew',

  // S
  send_invitation: 'Send Invitation',
  send_result: 'Send Result',
  settings: 'Settings',
  share: 'Share Link',
  super_admin: 'Super Admin',
  summary: 'Summary',
  search_by_lab_name: 'Search by lab name',
  subscriptions: 'Subscriptions',
  subscriptions_list_subtitle: 'Subscriptions list subtitle',

  // T
  test: 'Test',
  tests: 'Tests',
  this_week: 'This Week',
  this_month: 'This Month',
  this_year: 'This Year',
  total_income: 'Total income',

  // U
  update_results: 'Update Results',
  urgent: 'Urgent',
  user: 'User',
  user_activation: 'User Activation',
  user_list_title: 'All Users',
  user_list_subtitle: 'Manage the admins and users.',
  user_name_en: 'User name [en]',
  user_name_ar: 'User name [ar]',
  user_search_input_placeholder: 'Search by name, phone, or email',
  users: 'Users',

  // V
  view_unpaid_only: 'View Unpaid Only',

  // W

  // X

  // Y

  // Z

};

export default Lab;
