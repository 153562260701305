import React from 'react';
import i18n from 'translation/i18n';
import {useSelector, useDispatch} from 'react-redux';
import {Card} from 'atoms';
import {getServiceProviderSubscriptions, updateServiceProviderSubscription} from 'redux/labs/actionCreators';
import {SubscriptionFilters, SubscriptionTable} from '.';

const checkValues = {
  input: true,
  span: true,
  button: true,
  renew_subscription: true,
};

const SubscriptionList = () => {
  const dispatch = useDispatch();
  const {service_provider_subscriptions} = useSelector(state => state.labs);

  const loadSubscriptions = () => {
    dispatch(getServiceProviderSubscriptions());
  };

  const handleRenewSubscription = (id) => {
    dispatch(updateServiceProviderSubscription({status: 'active'}, id))
      .then(() => loadSubscriptions());
  };

  const handleRowClick = (values, inputTarget) => {
    const {id} = values.row;
    const nodeType = inputTarget.target.nodeName.toLowerCase();
    if(checkValues[nodeType] || checkValues[inputTarget.target.id]){
      const isRenew = inputTarget.target.innerHTML === i18n.t('renew');
      if(checkValues[inputTarget.target.id] || isRenew){
        return handleRenewSubscription(id);
      }
      return false;
    }
    return null;
  };

  React.useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <Card styles={{margin: '2rem'}}>
      <SubscriptionFilters/>
      <SubscriptionTable 
        list={service_provider_subscriptions} 
        onRowClick={handleRowClick}
      />
    </Card>
  );
};

export default SubscriptionList;
