import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';
import {get, last, indexOf} from 'lodash';
import clsx from 'clsx';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {orderTrackerStatus} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, DISPLAY_TIME_FORMAT, format} from 'utils/date';

import {Flex, Text} from 'atoms';

const OrderTracking = ({orderInfo}) => {
  const logs = get(orderInfo, 'order_status_logs', []);
  const currentStatus = valueGetter(last(logs), 'status');
  const allStatus = orderTrackerStatus(currentStatus);
  const activeIndex = indexOf(allStatus, currentStatus);

  return (
    <>
      <Text className="section-title" typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t('order_tracking')}
      </Text>
      <Box mx={3} my={2} py={2} style={{backgroundColor: 'var(--brand-card-bg)'}}>
        <Flex justifyEvenly>
          {allStatus.map((status, index) => {
            const current = logs.find(log => log.status === status);
            return (
              <Flex key={index} flexCol itemsCenter flexGrowEqual>
                <Text typography="caption13" weight="bold">{i18n.t(`status_${status}`)}</Text>
                <div className="order-tracking">
                  <div className={clsx(
                    'tracker-circle',
                    index < activeIndex && 'done',
                    index === activeIndex && 'active'
                  )}/>
                  <div className={clsx(
                    'tracker-bar',
                    index < activeIndex && 'done',
                    index === allStatus.length - 1 && 'last',
                  )}/>
                </div>
                <Text typography="caption13" weight="bold">{valueGetter(current, 'updated_by.name_i18n')}</Text>
                <Text typography="caption11" weight="600" color="var(--brand-text-gray)">
                  {format(valueGetter(current, 'updated_at'), DISPLAY_DATE_FORMAT)}
                </Text>
                <Text typography="caption11" weight="600" color="var(--brand-text-gray)">
                  {format(valueGetter(current, 'updated_at'), DISPLAY_TIME_FORMAT)}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </>
  );
};

OrderTracking.propTypes = {
  orderInfo: PropTypes.shape()
};

export default OrderTracking;
