const Clinic = {
  // *

  // A
  add_clinic: 'Add Clinic',

  // B

  // C

  // D

  // E

  // F

  // G

  // H

  // I

  // J

  // K

  // L

  // M

  // N

  // O

  // P

  // Q

  // R
  register_clinic_title: 'Register as a Clinic',
  register_clinic_subtitle: 'Signup as a clinic for Alborg Lab',
  // S

  // T

  // U

  // V

  // W

  // X

  // Y

  // Z

};

export default Clinic;
