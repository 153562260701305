import {get} from 'lodash';

import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  auth: {},
  location: {},
  loggedUser: {}
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.GET_LOCATION_PENDING:
  case actions.REGISTER_LAB_PENDING:
  case actions.ADMIN_LOGIN_PENDING:
  case actions.PATIENT_SIGNUP_PENDING:
  case actions.CLINIC_REGISTER_PENDING:
  case actions.PATIENT_LOGIN_PENDING:
  case actions.LAB_LOGIN_PENDING:
  case actions.VERIFY_LOGIN_PENDING:
  case actions.RESEND_CODE_PENDING:
  case actions.GET_RESET_EMAIL_PENDING:
  case actions.RESET_PASSWORD_PENDING:
  case actions.LOGGED_USER_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.REGISTER_LAB_FULFILLED: {
    Toastr.success(action.payload.data.message);

    return {
      ...state,
      auth: {
        ...state.auth,
        slug: action.payload.data.service_provider.slug,
      },
      fetching: false,
    };
  }

  case actions.ADMIN_LOGIN_FULFILLED:
  case actions.PATIENT_SIGNUP_FULFILLED:
  case actions.CLINIC_REGISTER_FULFILLED:
  case actions.PATIENT_LOGIN_FULFILLED:
  case actions.VERIFY_LOGIN_FULFILLED:
  case actions.RESEND_CODE_FULFILLED:
  case actions.LAB_LOGIN_FULFILLED: {
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      auth: {
        ...state.auth,
        ...action.payload.data,
      },
      fetching: false,
    };
  }

  case actions.GET_RESET_EMAIL_FULFILLED: {
    Toastr.success(action.payload.data.message);

    return {
      ...state,
      fetching: false,
    };
  }

  case actions.RESET_PASSWORD_FULFILLED: {
    Toastr.success(action.payload.data.message);

    return {
      ...state,
      fetching: false,
    };
  }

  case actions.LOGGED_USER_FULFILLED:
    return {
      ...state,
      loggedUser: get(action, 'payload.data.user', {}),
      fetching: false,
    };


  case actions.GET_LOCATION_FULFILLED: {
    return {
      ...state,
      fetching: false,
    };
  }

  case actions.ADMIN_LOGIN_REJECTED:
  case actions.PATIENT_SIGNUP_REJECTED:
  case actions.CLINIC_REGISTER_REJECTED:
  case actions.PATIENT_LOGIN_REJECTED:
  case actions.LAB_LOGIN_REJECTED:
  case actions.VERIFY_LOGIN_REJECTED:
  case actions.RESEND_CODE_REJECTED:
  case actions.REGISTER_LAB_REJECTED:
  case actions.GET_LOCATION_REJECTED:
  case actions.GET_RESET_EMAIL_REJECTED:
  case actions.RESET_PASSWORD_REJECTED:
  case actions.LOGGED_USER_REJECTED:
    Toastr.error(handleResponseErr(action.payload));

    return {
      ...state,
      fetching: false,
    };

  case actions.REMOVE_AUTH_USER:
    return {
      ...state,
      user: null,
      errMsg: null,
      fetching: false,
    };

  default:
    return state;
  }
}
