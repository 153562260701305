import React from 'react';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {getReservations} from 'redux/lab/actionCreators';
import {isClinicUser} from 'utils/labUtils';

import {
  Text,
  Flex,
} from 'atoms';
import NewPatient from 'components/reservation/NewPatient/NewPatient';
import { ReactComponent as Logo } from 'assets/icons/logo/tashkhes-logo.svg';
import { NavItem } from './NavItem';
import './LabSideNav.scss';

export const LabSideNav = () => {
  const dispatch = useDispatch();
  const primaryNavs = ['overview', 'reservations', 'orders', 'billing', 'invoices'];
  const secondaryNavs = ['users', 'clinics', 'tests'];
  const tertiaryNavs = ['settings', 'share'];
  const isClinic = isClinicUser();

  const reloadReservations = () => {
    dispatch(getReservations());
  };

  return (
    <Flex flexCol justifyBetween className="lab-side-nav">
      <div>
        <div className="heading">
          <Logo/>
          <Text typography="subtitle" color="white" as="span" weight="bold">
            {i18n.t('app_title')}
          </Text>
        </div>
        {isClinic && (
          <div className="lab-navs">
            {primaryNavs.slice(0, 3).map(nav => (<NavItem key={nav} nav={nav}/>))}
          </div>
        )}
        {!isClinic && (
          <>
            <div className="lab-navs">
              {primaryNavs.map(nav => (<NavItem key={nav} nav={nav}/>))}
            </div>
            <div className="lab-navs secondary-navs">
              {secondaryNavs.map(nav => (<NavItem key={nav} nav={nav}/>))}
            </div>
            <div className="lab-navs">
              {tertiaryNavs.map(nav => (<NavItem key={nav} nav={nav}/>))}
            </div>
          </>
        )}
      </div>
      <NewPatient onDone={reloadReservations}/>
    </Flex>
  );
};
