import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import {TextInputField} from 'components/global/final-form';
import {Text, TextInput} from 'atoms';
import styles from './templates.module.css';

const FieldInfoCard = (props) => {
  const {
    actionCallback,
    placeholder,
    validate,
    endIcon,
    action,
    label,
    name,
    xs,
    as,
    ...rest
  } = props;

  return (
    <Grid item xs={xs}>
      <div className={styles.fieldInfoContainer}>
        {as === 'field' ?
          <>
            <TextInputField
              name={name}
              validate={validate}
              label={label}
              placeholder={placeholder}
              className={styles.fieldInfoCard}
              endIcon={endIcon}
              {...rest}
            />
            {action.onClick && (
              <Text
                typography="caption12"
                onClick={() => action.onClick(actionCallback)}
                className={styles.fieldInfoAction}
                {...rest}
                link
              >
                {action.text}
              </Text>
            )}
          </>
          :
          <TextInput
            label={label}
            placeholder={placeholder}
            className={styles.fieldInfoCard}
            endIcon={endIcon}
            {...rest}
          />
        }
      </div>
    </Grid>
  );
};

FieldInfoCard.defaultProps = {
  xs: 6,
  as: 'field'
};

FieldInfoCard.propTypes = {
  endIcon: PropTypes.node,
  actionCallback: PropTypes.func,
  validate: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  xs: PropTypes.number,
  as: PropTypes.string,
  action: PropTypes.shape(),
};

export default FieldInfoCard;
