import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {Card} from 'atoms';
import {testsLoad, testUpdate} from 'redux/tests/actionCreators';
import { isPatient } from 'utils/labUtils';
import {TestTable, TestFilters} from './index';

const checkValues = {
  input: true,
  span: true,
};

const TestList = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {message, services} = useSelector(state => state.tests);

  const updateService = (service_id, service) => {
    return dispatch(testUpdate(service_id, { service }));
  };

  const handleSwitch = (service_id, activate, type) => {
    return updateService(service_id, {...type , activate});
  };

  const handleRowClick = (values, inputTarget) => {
    const {service, id, priority} = values.row;

    if(checkValues[inputTarget.target.nodeName.toLowerCase()]){
      if(inputTarget.target.id === 'activeSwitch'){
        return handleSwitch(service.id, inputTarget.target?.checked, {priority})
          .then(() => dispatch(testsLoad()));
      }
      return false;
    }
    return push(`/tests/${service.id}`, {id, priority});
  };

  React.useEffect(() => {
    if(!isPatient()){
      dispatch(testsLoad());
    }
  }, []);

  return (
    <Card styles={{margin: '2rem'}}>
      <TestFilters/>
      <TestTable 
        list={services} 
        onRowClick={handleRowClick}
        noData={message}
      />
    </Card>
  );
};

export default TestList;
