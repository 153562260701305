import React from 'react';
import {Grid} from '@mui/material';
import i18n from 'translation/i18n';
import {Text} from 'atoms';
import {SearchInput} from 'components/global/filters';

// import RequestTest from './RequestTest/RequestTest';

const TestFilters = () => {
  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('all_tests')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('tests_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput placeholder={i18n.t('search_by_test_name')}/>
      </Grid>
      <Grid item xs={3}/>
      <Grid item xs={2}>
        {/* <RequestTest/> */}
        <span />
      </Grid>
    </Grid>
  );
};

export default TestFilters;
