import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Menu} from '@mui/material';
import clsx from 'clsx';

import i18n from 'translation/i18n';

import {DatePicker, Flex, Select} from 'atoms';
import {ReactComponent as FilterIcon} from 'assets/icons/common/filter.svg';

import './style.scss';

export const SelectFilter = ({
  className,
  selectFilterConfigs,
  dateFilterConfigs,
  onApply,
  onCancel
}) => {
  const [filters, setFilters] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleFilterChange = (filter, value) => {
    setFilters({...filters, [filter]: value});
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setFilters({});
    setAnchorEl(null);
    onCancel({});
  };

  const handleApply = () => {
    let appliedFilters = {};
    Object.keys(filters).forEach(key => {
      appliedFilters = {
        ...appliedFilters,
        [key]: filters[key].value || filters[key]
      };
    });
    onApply(appliedFilters);
    setAnchorEl(null);
  };

  return (
    <Flex itemsCenter justifyAround>
      <Button
        id="select-filter"
        startIcon={<FilterIcon/>}
        disableRipple
        aria-haspopup="true"
        aria-controls={open ? 'select-filter-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {i18n.t('filter')}
      </Button>
      <Menu
        id="select-filter-menu"
        className={clsx(className)}
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{'aria-labelledby': 'select-filter'}}
      >
        {selectFilterConfigs.map(({key, options, placeholderKey}) =>
          (<Select
            key={key}
            value={filters[key]}
            options={options}
            placeholder={i18n.t(placeholderKey)}
            onChange={(value) => handleFilterChange(key, value)}
          />)
        )}
        {dateFilterConfigs.map(({key, placeholderKey}) =>
          (<DatePicker
            showIcon
            key={key}
            value={filters[key]}
            dateFormat="dd/MM/yyyy"
            displayFormat="dd MMM yyyy"
            placeholderText={i18n.t(placeholderKey)}
            onChange={(value) => handleFilterChange(key, value)}
          />)
        )}
        <Flex justifyEnd>
          <Button disableRipple onClick={handleClose}>{i18n.t('clear')}</Button>
          <Button disableRipple onClick={handleApply}>{i18n.t('apply')}</Button>
        </Flex>
      </Menu>
    </Flex>
  );
};

SelectFilter.defaultProps = {
  selectFilterConfigs: [],
  dateFilterConfigs: [],
  onApply: () => {},
  onCancel: () => {}
};

SelectFilter.propTypes = {
  className: PropTypes.string,
  selectFilterConfigs: PropTypes.arrayOf(PropTypes.shape()),
  dateFilterConfigs: PropTypes.arrayOf(PropTypes.shape()),
  onApply: PropTypes.func,
  onCancel: PropTypes.func
};
