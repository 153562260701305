import React from 'react';
import {useSelector} from 'react-redux';

import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';

import {CardContainer} from 'templates';
import {ReservationOrderInfoActions} from 'components/labs';
import OrderTestInfo from './OrderTestInfo';
import OrderTracking from './OrderTracking';
import OrderPatientInfo from './OrderPatientInfo';
import '../styles.scss';

const OrderInfo = () => {
  const {orderDetails} = useSelector(state => state.orders);

  return (
    <CardContainer
      className="order-info-container"
      title={i18n.t('order_info_title')}
      showBackButton
      noPadding
      cardActions={
        <ReservationOrderInfoActions
          reservationId={valueGetter(orderDetails, 'patient_detail.id')}
          orderId={valueGetter(orderDetails, 'id')}
          status={valueGetter(orderDetails, 'status')}
        />
      }
    >
      <OrderTestInfo orderInfo={orderDetails}/>
      <OrderTracking orderInfo={orderDetails}/>
      <OrderPatientInfo orderInfo={orderDetails}/>
    </CardContainer>
  );
};

export default OrderInfo;
