import React from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {ORDER_STATUS} from 'models';
import {orderNextStatus} from 'utils/labUtils';
import {PRIORITIES, ORDER_STATUSES, TEST_RESULTS} from 'utils/options';

import {Button, Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';
import OrderStatusTransition from 'components/global/OrderStatusTransition';

const SingleOrderFilters = ({
  selectedStatus,
  onCreateBatch,
  onChangeStatus,
  generateOtherFilters,
  generateSelectFilters
}) => {
  const selectFilterConfigs = [
    {key: 'status', options: ORDER_STATUSES, placeholderKey: 'status'},
    {key: 'priority', options: PRIORITIES, placeholderKey: 'priority'},
    {key: 'result', options: TEST_RESULTS, placeholderKey: 'result'}
  ];
  const dateFilterConfigs = [
    {key: 'date', placeholderKey: 'date'}
  ];

  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('order_list_title')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('order_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t('order_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      {selectedStatus === ORDER_STATUS.PROCESS && (
        <>
          <Grid item xs={1}/>
          <Grid item xs={2}>
            <Button
              variant="secondary"
              label={i18n.t('create_batch')}
              onClick={onCreateBatch}
            />
          </Grid>
        </>
      )}
      {selectedStatus && (
        <>
          {selectedStatus !== ORDER_STATUS.PROCESS && <Grid item xs={3}/>}
          <Grid item xs={2}>
            <OrderStatusTransition
              menuPosition="bottom"
              options={orderNextStatus[selectedStatus]}
              onMenuClick={onChangeStatus}
            >
              <Button label={i18n.t('change_status')}/>
            </OrderStatusTransition>
          </Grid>
        </>
      )}
      {!selectedStatus && (
        <>
          <Grid item xs={4}/>
          <Grid item xs={1}>
            <SelectFilter
              className="ml-8"
              selectFilterConfigs={selectFilterConfigs}
              dateFilterConfigs={dateFilterConfigs}
              onApply={generateSelectFilters}
              onCancel={generateSelectFilters}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

SingleOrderFilters.propTypes = {
  selectedStatus: PropTypes.string,
  onCreateBatch: PropTypes.func,
  onChangeStatus: PropTypes.func,
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

export default SingleOrderFilters;
