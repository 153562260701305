import React from 'react';
import PropTypes from 'prop-types';

import {Card, Text, Spacer, Divider, Flex} from 'atoms';
import BackButton from 'components/global/BackButton';
import ModalContent from './ModalContent';

const CardContainer = ({
  withHeaderDivider,
  customHeader,
  noPadding,
  children,
  width,
  title,
  style,
  className,
  padding,
  showBackButton,
  cardActions
}) => {
  return (
    <Card styles={{width, padding, ...style}} className={className}>
      {(customHeader || title) && (
        <ModalContent padding="0 24px">
          <Spacer height={28}/>
          {customHeader || (
            <Flex itemsCenter justifyBetween>
              <Flex flexCol>
                {showBackButton && <BackButton/>}
                {title && (
                  <Text
                    typography="subtitle"
                    weight="bold"
                    color="var(--brand-text-black)"
                    textCase="capitalize"
                  >
                    {title}
                  </Text>
                )}
              </Flex>
              <Flex>
                {cardActions}
              </Flex>
            </Flex>
          )}
          <Spacer height={28}/>
        </ModalContent>
      )}
      {(withHeaderDivider && title) && <Divider/>}
      {noPadding ?
        children
        :
        <ModalContent>
          {children}
        </ModalContent>
      }
    </Card>
  );
};

CardContainer.defaultProps = {
  withHeaderDivider: true
};

CardContainer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withHeaderDivider: PropTypes.bool,
  noPadding: PropTypes.bool,
  children: PropTypes.node,
  customHeader: PropTypes.node,
  style: PropTypes.shape(),
  title: PropTypes.string,
  className: PropTypes.string,
  padding: PropTypes.string,
  showBackButton: PropTypes.bool,
  cardActions: PropTypes.node
};

export default CardContainer;
