import React from 'react';
import i18n from 'translation/i18n';
import toastr from 'toastr';
import {useForm} from 'react-final-form';
import {TextInput, Spacer} from 'atoms';
import {ModalContent} from 'templates';
import {TextInputField} from 'components/global/final-form';
import {
  composeValidators,
  required,
  maxLength,
  minLength,
  number,
} from 'utils/FormValidations';
import {ReactComponent as GPS} from 'assets/icons/login-flow/gps.svg';

const LabStep2 = () => {
  // const dispatch = useDispatch();
  const {change} = useForm();
  const [location, setLocation] = React.useState({});
  const showPosition = (position) => {
    setLocation({
      Latitude: position.coords.latitude,
      Longitude: position.coords.longitude,
    });

    return position.coords;
  };

  const getCurrentPosition = () => {
    if (navigator.geolocation) {
      return navigator.geolocation.getCurrentPosition(showPosition);
    }
    return toastr.error('Geolocation is not supported by this browser.');
  };

  React.useEffect(() => {
    getCurrentPosition();
  }, []);

  React.useEffect(() => {
    // if (location.Longitude) {
    //  dispatch(getLocation(location.Latitude, location.Longitude ));
    // }
    change('service_provider.longitude', location.Longitude);
    change('service_provider.latitude', location.Latitude);
  }, [location]);

  return (
    <ModalContent>
      <TextInputField
        name="service_provider.vat_number"
        placeholder={i18n.t('vat')}
        label={i18n.t('vat_number').toUpperCase()}
        validate={composeValidators(required, number, maxLength(10), minLength(10))}
        maxLength={10}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.cr_number"
        placeholder={i18n.t('commercial_registration')}
        label={i18n.t('cr_number').toUpperCase()}
        validate={composeValidators(required, number, maxLength(10), minLength(10))}
        maxLength={10}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.bank_name"
        placeholder={i18n.t('bank_name')}
        label={i18n.t('bank_name').toUpperCase()}
        validate={required}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.iban_number"
        placeholder={i18n.t('iban')}
        label={i18n.t('iban').toUpperCase()}
        validate={required}
      />
      <Spacer height={20}/>

      <TextInputField
        name="service_provider.address"
        placeholder={i18n.t('lab_address')}
        label={i18n.t('address').toUpperCase()}
        validate={required}
      />
      <Spacer height={20}/>

      <TextInput
        placeholder={i18n.t('pick_a_location')}
        label={i18n.t('location').toUpperCase()}
        endIcon={<GPS/>}
        value={location}
        disabled
      />
    </ModalContent>
  );
};

export default LabStep2;
