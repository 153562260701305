import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

import {Text} from 'atoms';
import i18n from 'translation/i18n';
import {ReactComponent as OverviewLogo} from 'assets/icons/navs/overview.svg';
import {ReactComponent as ReservationLogo} from 'assets/icons/navs/reservation.svg';
import {ReactComponent as OrdersLogo} from 'assets/icons/navs/orders.svg';
import {ReactComponent as BillingLogo} from 'assets/icons/navs/billing.svg';
import {ReactComponent as InvoicesLogo} from 'assets/icons/navs/invoices.svg';
import {ReactComponent as UsersLogo} from 'assets/icons/navs/users.svg';
import {ReactComponent as ClinicsLogo} from 'assets/icons/navs/clinics.svg';
import {ReactComponent as TestsLogo} from 'assets/icons/navs/tests.svg';
import {ReactComponent as SettingsLogo} from 'assets/icons/navs/settings.svg';
import {ReactComponent as ShareLinkLogo} from 'assets/icons/navs/share.svg';

import './LabSideNav.scss';

export const NavItem = ({nav}) => {
  function getIcon() {
    switch (nav) {
    case 'overview': return <OverviewLogo/>;
    case 'reservations': return <ReservationLogo/>;
    case 'orders': return <OrdersLogo/>;
    case 'billing': return <BillingLogo/>;
    case 'invoices': return <InvoicesLogo/>;
    case 'users': return <UsersLogo/>;
    case 'clinics': return <ClinicsLogo/>;
    case 'tests': return <TestsLogo/>;
    case 'settings': return <SettingsLogo/>;
    case 'share': return <ShareLinkLogo/>;
    default: return <></>;
    }
  }

  return (
    <NavLink activeClassName="is-active" to={`/${nav}`}>
      {getIcon()}
      <Text typography="paragraph14" color="white" as="span" weight="bold">
        {i18n.t(nav)}
      </Text>
    </NavLink>
  );
};

NavItem.propTypes = {
  nav: PropTypes.string
};
