import React from 'react';
import {Grid} from '@mui/material';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {BATCH_ORDER_STATUSES} from 'utils/options';

import {Button, Text} from 'atoms';
import {SearchInput, SelectFilter} from 'components/global/filters';

const BatchOrderListFilters = ({generateOtherFilters, generateSelectFilters}) => {
  const selectFilterConfigs = [
    {key: 'status', options: BATCH_ORDER_STATUSES, placeholderKey: 'status'}
  ];
  const dateFilterConfigs = [
    {key: 'date', placeholderKey: 'created_at'}
  ];

  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <Text typography="subtitle" weight="bold">{i18n.t('batch_order_list_title')}</Text>
        <Text typography="caption13" style={{opacity: 0.4}}>
          {i18n.t('batch_order_list_subtitle')}
        </Text>
      </Grid>
      <Grid item xs={4}>
        <SearchInput
          placeholder={i18n.t('batch_order_search_input_placeholder')}
          onChange={(value) => generateOtherFilters('search', value)}
        />
      </Grid>
      <Grid item xs={2}/>
      <Grid item xs={1}>
        <SelectFilter
          selectFilterConfigs={selectFilterConfigs}
          dateFilterConfigs={dateFilterConfigs}
          onApply={generateSelectFilters}
          onCancel={generateSelectFilters}
        />
      </Grid>
      <Grid item xs={2}>
        <Button variant="secondary" label={i18n.t('export_csv')}/>
      </Grid>
    </Grid>
  );
};

BatchOrderListFilters.propTypes = {
  generateOtherFilters: PropTypes.func,
  generateSelectFilters: PropTypes.func
};

export default BatchOrderListFilters;
