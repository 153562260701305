import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import { isEmpty } from 'lodash';
import {RouteLayoutTemplate} from 'templates';
import {TashkhesLoader} from 'atoms';
import {Grid} from '@mui/material';
import {testShow} from 'redux/tests/actionCreators';
import TestInformationCard from './DetailCards/TestInformationCard';
import TestSummaryCard from './DetailCards/TestSummaryCard';

const TestDetail = () => {
  const dispatch = useDispatch();
  const {fetching, service_show} = useSelector(state => state.tests);
  const {state} = useLocation();

  const loadData = () => dispatch(testShow(state.id));
  
  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <RouteLayoutTemplate>
      {(isEmpty(service_show) || fetching) ? <TashkhesLoader /> : (
        <Grid container spacing={4} p={4}>
          <Grid item xs={8}>
            <TestInformationCard/>
          </Grid>
          <Grid item xs={4}> 
            <TestSummaryCard onDone={loadData}/>
          </Grid>
        </Grid>
      )}
    </RouteLayoutTemplate>
  );
};

export default TestDetail;
