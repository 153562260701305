import React from 'react';
import LabHeader from 'components/global/LabHeader';
import {TestDetail} from 'components/tests';

const TestDetails = () => {
  return (
    <>
      <LabHeader title='tests'/>
      <TestDetail/>
    </>
  );
};

export default TestDetails;
