// Import translations
import Auth from './modules/en/auth';
import Lab from './modules/en/lab';
import Common from './modules/en/common';
import Reservation from './modules/en/reservation';
import Tests from './modules/en/tests';
import Overview from './modules/en/overview';
import Clinic from './modules/en/clinic';

const EN = {
  ...Auth,
  ...Lab,
  ...Common,
  ...Reservation,
  ...Tests,
  ...Overview,
  ...Clinic,
};

export default EN;
