import React from 'react';

import LabSideNav from 'components/global/LabSideNav';
import LabRoutes from 'routers/LabRoutes';

import './styles.scss';

const LabLayout = () => {
  return (
    <div className="lab-layout">
      <LabSideNav/>
      <LabRoutes/>
    </div>
  );
};

export default LabLayout;
