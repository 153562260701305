import {combineReducers} from 'redux';
import patient from './patients/reducer';
import profile from './profile/reducer';
import tests from './tests/reducer';
import auth from './auth/reducer';
import lab from './lab/reducer';
import labs from './labs/reducer';
import orders from './orders/reducer';

const rootReducer = combineReducers({
  patient,
  profile,
  tests,
  auth,
  labs,
  lab,
  orders
});

export default rootReducer;
