import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {ORDER_STATUS} from 'models';
import {printPDF} from 'utils/labUtils';
import {sendOrderResult} from 'redux/orders/actionCreators';
import {getPatientBarcode} from 'redux/patients/actionCreators';

import {Button} from 'atoms';
import {ReactComponent as Share} from 'assets/icons/navs/share.svg';
import {ReactComponent as Printer} from 'assets/icons/common/printer.svg';

const ReservationOrderInfoActions = ({reservationId, orderId, status}) => {
  const dispatch = useDispatch();
  const [showPrintBtn, setShowPrintBtn] = useState(false);

  const handlePrintBarcode = () => {
    dispatch(getPatientBarcode(reservationId))
      .then(response => {
        const blob = new Blob([response.value.data], {type: 'text/html'});
        printPDF(blob);
      });
  };

  const handleShareResult = () => {
    dispatch(sendOrderResult(orderId));
  };

  useEffect(() => {
    const isShowPrint =
      [ORDER_STATUS.CONFIRM, ORDER_STATUS.COLLECT, ORDER_STATUS.PROCESS].includes(status);
    setShowPrintBtn(isShowPrint);
  }, [status]);

  return (
    <>
      {showPrintBtn && (
        <Button
          label={i18n.t('print_barcode')}
          startIcon={<Printer/>}
          onClick={handlePrintBarcode}
        />
      )}
      {status === ORDER_STATUS.READY && (
        <Button
          label={i18n.t('send_result')}
          startIcon={<Share/>}
          onClick={handleShareResult}
        />
      )}
    </>
  );
};

ReservationOrderInfoActions.propTypes = {
  reservationId: PropTypes.string,
  orderId: PropTypes.string,
  status: PropTypes.string
};

export default ReservationOrderInfoActions;
