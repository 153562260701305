import {handleAPI, API_VERSION_ONE} from 'utils/apiUtils';
import * as actions from './actions';

export function profilesShow(data) {
  const url = `/${API_VERSION_ONE}/lab/profiles`;
  const method = 'GET';

  return {
    type: actions.PROFILE_SHOW,
    payload: handleAPI(url, {}, method, data),
  };
}

export function profilesUpdate(profile) {
  const url = `/${API_VERSION_ONE}/lab/profiles`;
  const method = 'PUT';

  return {
    type: actions.PROFILE_UPDATE,
    payload: handleAPI(url, {}, method, {profile}),
  };
}

export function changeEmail(data) {
  const url = `/${API_VERSION_ONE}/lab/setting/emails/change`;
  const method = 'PUT';

  return {
    type: actions.CHANGE_EMAIL,
    payload: handleAPI(url, {}, method, data),
  };
}

export function changeMobiles(data) {
  const url = `/${API_VERSION_ONE}/lab/setting/mobiles/change`;
  const method = 'PUT';

  return {
    type: actions.CHANGE_MOBILE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifyChangeMobiles(data) {
  const url = `/${API_VERSION_ONE}/lab/setting/mobiles/confirm`;
  const method = 'PUT';

  return {
    type: actions.VERIFY_CHANGE_MOBILE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function changePassword(data) {
  const url = `/${API_VERSION_ONE}/lab/setting/passwords`;
  const method = 'PUT';

  return {
    type: actions.CHANGE_PASSWORD,
    payload: handleAPI(url, {}, method, data),
  };
}
