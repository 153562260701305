import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

import i18n from 'translation/i18n';
import {getReservations} from 'redux/lab/actionCreators';
import {cancelOrder, updateOrderStatus} from 'redux/orders/actionCreators';
import {valueGetter} from 'utils/uiHelpers';
import {canCancelOrder, getStatusColor, reservationNextStatus} from 'utils/labUtils';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {ORDER_STATUS} from 'models';

import {DataTable, Tag} from 'atoms';
import OrderStatusTransition from 'components/global/OrderStatusTransition';
import './styles.scss';

export const ReservationTable = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {reservations, fetching} = useSelector(state => state.lab);

  const handleMenuClick = (orderId, {option, reason}) => {
    if (option === ORDER_STATUS.CANCEL) {
      dispatch(cancelOrder(orderId, reason))
        .then(() => dispatch(getReservations()));
    } else {
      dispatch(updateOrderStatus(orderId, option))
        .then(() => dispatch(getReservations()));
    }
  };

  const handleRowClick = ({id}) => {
    push(`/reservations/${id}`);
  };

  const columns = [
    {field: 'name', headerName: i18n.t('patient'), minWidth: 200, flex: 1},
    {
      field: 'user.mobile',
      headerName: i18n.t('phone'),
      minWidth: 150,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'order.reservation_id',
      headerName: i18n.t('reservation_id'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field)
    },
    {
      field: 'priority',
      headerName: i18n.t('priority'),
      minWidth: 120,
      flex: 1,
      renderCell: ({value}) => i18n.t(value)
    },
    {
      field: 'order.status',
      headerName: i18n.t('status'),
      minWidth: 100,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => <Tag text={i18n.t(`status_${value}`)} color={getStatusColor(value)}/>
    },
    {
      field: 'order.appointment.time',
      headerName: i18n.t('date'),
      minWidth: 120,
      flex: 1,
      valueGetter: ({row, field}) => valueGetter(row, field),
      renderCell: ({value}) => format(value, DISPLAY_DATE_FORMAT)
    },
    {
      field: 'actions',
      headerName: ' ',
      align: 'center',
      sortable: false,
      minWidth: 50,
      renderCell: ({row}) => (
        <OrderStatusTransition
          showCancel={canCancelOrder(row.order.status)}
          options={reservationNextStatus[row.order.status]}
          onMenuClick={(payload) => handleMenuClick(row.order.id, payload)}
        />
      )
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={reservations}
      loading={fetching}
      onRowClick={handleRowClick}
    />
  );
};
