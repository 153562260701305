import React from 'react';
import {useHistory} from 'react-router-dom';

import i18n from 'translation/i18n';

import {Flex, Text} from 'atoms';
import {ReactComponent as ArrowLeft} from 'assets/icons/common/arrow-left.svg';

const BackButton = () => {
  const {goBack} = useHistory();

  return (
    <Flex onClick={goBack} style={{cursor: 'pointer'}}>
      <ArrowLeft/>
      <Text typography="caption12" weight="bold" style={{marginLeft: '1rem', marginBottom: '0.5rem'}}>
        {i18n.t('back')}
      </Text>
    </Flex>
  );
};


export default BackButton;
