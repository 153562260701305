import React from 'react';
import {CreateLabModal} from '.';

const CreateNewLab = () => {
  const handleOnSubmit = () => {
    
  };

  return (
    <CreateLabModal initialValues={{}} onSubmit={handleOnSubmit}/>
  );
};

export default CreateNewLab;
