import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';
import {formattedPrice} from 'utils/labUtils';

import {Flex, Spacer, Text} from 'atoms';

const PriceSummary = ({price}) => {
  return (
    <>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('subtotal')}
        </Text>
        <Text typography="paragraph14" color="var(--brand-text-black)" weight="bold">
          {formattedPrice(price?.sub_total, 'saudi_riyal')}
        </Text>
      </Flex>
      <Spacer height={8}/>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('discount')}
        </Text>
        <Text typography="paragraph14" color="var(--brand-ready-text)" weight="bold">
          {formattedPrice(price?.discount, 'saudi_riyal')}
        </Text>
      </Flex>
      <Spacer height={8}/>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('tax')}
        </Text>
        <Text typography="paragraph14" color="var(--brand-text-black)" weight="bold">
          {formattedPrice(price?.tax, 'saudi_riyal')}
        </Text>
      </Flex>
      <Spacer height={12}/>
      <Spacer height={1} width="100%" style={{backgroundColor: 'var(--brand-divider-white)'}}/>
      <Spacer height={10}/>
      <Flex justifyBetween>
        <Text typography="paragraph14" color="var(--brand-text-gray)" weight="bold">
          {i18n.t('total')}
        </Text>
        <Text typography="paragraph14" color="var(--brand-text-black)" weight="bold">
          {formattedPrice(price?.total, 'saudi_riyal')}
        </Text>
      </Flex>
    </>
  );
};

PriceSummary.propTypes = {
  price: PropTypes.shape()
};

export default PriceSummary;
