import React from 'react';
import { ReactComponent as SuccessIcon } from '../TextInput/icons/successIcon.svg';
import { ReactComponent as WarningIcon } from '../TextInput/icons/warningIcon.svg';
import { ReactComponent as ErrorIcon } from '../TextInput/icons/errorIcon.svg';

export const statusIcons = {
  error: <ErrorIcon />,
  success: <SuccessIcon />,
  warning: <WarningIcon />,
};

export const textStyles = {
  fontWeight: 'var(--font-en-medium-paragraph-14-fontWeight)',
  fontSize: 'var(--font-en-medium-paragraph-14-fontSize)',
  fontFamily: 'var(--font-en-medium-paragraph-14-fontFamily)',
  fontStyle: 'var(--font-en-medium-paragraph-14-fontStyle)',
  lineHeight: 'var(--font-en-medium-paragraph-14-lineHeight)',
  letterSpacing: 'var(--font-en-medium-paragraph-14-letterSpacing)',
};

export const statusColors = {
  warning: 'var(--color-feedback-warning-default)',
  success: 'var(--color-feedback-success-default)',
  error: 'var(--color-feedback-error-default)',
};

export const valueContainerStyles = {
  maxWidth: 'calc(100% - 8px)',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
};

const defaultBorderColor = '#e2e6e9';
const brandBorderColor = 'var(--color-brand-primary-default-new)';

const getBorderColor = (isFocused, isHovered, isDisabled, status) => {
  const defaultColor = !isDisabled && (isFocused || isHovered)
    ? brandBorderColor : defaultBorderColor;
  return statusColors[status] || defaultColor;
};

const getOptionBackgroundColor = (isSelected, isFocused) => {
  if (isFocused) return 'var(--color-natural-gray-lightest-2-new)';
  if (isSelected) return 'var(--color-brand-primary-lightest-1-new)';
  return 'none';
};

export const mainSelectStyles = ({ status, maxMenuHeight }) => ({
  control: (base, { isFocused, isDisabled }) => ({
    ...base,
    overflow: 'visible',
    minHeight: 40,
    borderRadius: 8,
    boxShadow: 'none',
    borderColor: getBorderColor(isFocused, false, isDisabled, status),
    background:
      isDisabled && 'linear-gradient(0deg, var(--color-natural-gray-lighter-1), var(--color-natural-gray-lighter-1)), var(--color-natural-gray-default)',
    '&:hover': {
      borderColor: getBorderColor(isFocused, true, isDisabled, status),
    },
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    padding: '0 8px',
    '& > svg': {
      width: 24,
      height: 24,
      '& > path': {
        fill: isDisabled ? 'var(--color-natural-black-lighter-new)' : '#7a828a',
      },
    },
  }),
  clearIndicator: (base, { isDisabled }) => ({
    ...base,
    padding: '0 8px',
    cursor: 'pointer',
    '& > svg': {
      width: 24,
      height: 24,
      '& > path': {
        fill: isDisabled ? 'var(--color-natural-black-lighter-new)' : '#7a828a',
      },
    },
  }),
  singleValue: () => ({
    ...textStyles, ...valueContainerStyles,
  }),
  placeholder: (base, { isDisabled }) => ({
    ...base,
    ...textStyles,
    margin: 0,
    color: !isDisabled ? 'var(--color-natural-black-light)' : 'var(--color-natural-black-lighter-new)',
  }),
  indicatorSeparator: (base, state) => {
    const { selectProps, hasValue } = state;
    if (selectProps.isClearable && hasValue) return base;
    return {};
  },
  menu: (base) => ({
    ...base,
    zIndex: 999,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: maxMenuHeight || 420,
  }),
  input: (base) => ({
    ...base,
    ...textStyles,
    margin: 0,
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: getOptionBackgroundColor(state.isSelected, state.isFocused),
    borderBottom: state.isSelected ? '1px solid var(--color-brand-primary-default-new)' : 'none',
    padding: state.isSelected ? '8px 8px 7px' : '8px',
    margin: 0,
    '&:active': {
      backgroundColor: 'var(--color-brand-primary-lighter-1-new)',
    },
    '&:hover': {
      cursor: 'pointer',
      background: 'var(--color-natural-gray-lightest-2-new)',
    },
  }),
});
