import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  service_providers: [],
  service_provider_subscriptions: [],
  service_provider: {},
  service_provider_subscription: {},
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.GET_ADMIN_SERVICE_PROVIDERS_PENDING:
  case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_PENDING:
  case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_PENDING:
  case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.GET_ADMIN_SERVICE_PROVIDERS_FULFILLED: {
    return {
      ...state,
      service_providers: action.payload.data.service_providers,
      fetching: false,
    };
  }

  case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_FULFILLED: {
    return {
      ...state,
      service_provider_subscriptions: action.payload.data.service_provider_subscriptions,
      fetching: false,
    };
  }

  case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_FULFILLED: {
    return {
      ...state,
      service_provider: action.payload.data.service_provider,
      fetching: false,
    };
  }

  case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_FULFILLED: 
  case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_FULFILLED: {
    return {
      ...state,
      submitting: false,
    };
  }

  case actions.GET_SERVICE_PROVIDER_SUBSCRIPTIONS_REJECTED:
  case actions.GET_ADMIN_SERVICE_PROVIDERS_REJECTED:
  case actions.GET_ADMIN_SERVICE_PROVIDER_INFO_REJECTED:
    Toastr.error(handleResponseErr(action.payload));

    return {
      ...state,
      fetching: false,
    };

  case actions.UPDATE_ADMIN_SERVICE_PROVIDERS_REJECTED:
  case actions.UPDATE_SERVICE_PROVIDER_SUBSCRIPTION_REJECTED:
    Toastr.error(handleResponseErr(action.payload));

    return {
      ...state,
      submitting: false,
    };

  default:
    return state;
  }
}
