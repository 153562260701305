import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ReactComponent as Logo } from 'assets/icons/logo/tashkhes-full-logo.svg';
import {
  Text,
  Flex,
  Spacer,
} from 'atoms';

import styles from './LoginTemplate.module.css';
import 'styles/styleTokens.css';

const LoginTemplate = ({
  subTitle,
  children,
  title,
  logo,
}) => {
  return (
    <Flex flexCol itemsCenter className={clsx(styles.loginTemplateContainer)}>
      {title && (
        <div className={styles.templateHeader}>
          <Flex flexCol justifyCenter itemsCenter>
            <Text typography="display36" color="var(--brand-text-white)">
              {title}
            </Text>
            <Spacer height={10} />
            <Text typography="paragraph16" color="var(--brand-text-white-opacity-40)">
              {subTitle}
            </Text>
          </Flex>
          <Spacer height={80} />
        </div>
      )}
      {
        !title && (
          <>
            <Spacer height={80} />
            {logo || <Logo style={{minWidth: '48px', minHeight: '48px'}}/>}
            <Spacer height={34} />
          </>
        )
      }
      {children}
    </Flex>
  );
};

LoginTemplate.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node,
  logo: PropTypes.node,
};

export default LoginTemplate;
