import { USER_ROLE } from 'models';
import { getAuthUser } from 'redux/auth/actionCreators';
import { handleAPI, API_VERSION_ONE } from 'utils/apiUtils';
import {
  SERVICE_PRIORITIES_LABEL,
  SERVICES_LABEL,
  LAB_LABEL,
  PATIENT_LABEL,
  ADMIN_LABEL,
  CLINIC_LABEL,
  SERVICE_TYPE_LABEL
} from 'utils/constants';

import * as actions from './actions';

// export function addTest(data) {
//   const url = `/${API_VERSION_ONE}/lab/${localStorage.getItem(LAB_ID)}/services?include=true`;
//   const method = 'POST';

//   return {
//     type: actions.ADD_PATIENT,
//     payload: handleAPI(url, {}, method, data),
//   };
// }

const baseUrl = () => {
  const url = `/${API_VERSION_ONE}/`;
  switch (getAuthUser().role_label) {
  case USER_ROLE.SUPER_ADMIN:
    return url + ADMIN_LABEL;
  case USER_ROLE.CLINIC:
    return url + CLINIC_LABEL;
  default:
    return url + LAB_LABEL;
  }
};

export function testsLoad(params = {}) {
  const url = `${baseUrl()}/services`;
  const method = 'GET';

  return {
    type: actions.TESTS_LOAD,
    payload: handleAPI(url, params, method),
  };
}

export function testUpdate(service_id, data = {}) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrlKey = isAdmin ? `${ADMIN_LABEL}/${SERVICE_PRIORITIES_LABEL}/${service_id}` : `${LAB_LABEL}/${SERVICES_LABEL}/${service_id}/update`;
  const url = `/${API_VERSION_ONE}/${apiUrlKey}`;
  const method = 'PUT';

  return {
    type: actions.TESTS_UPDATE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function testShow(id, params = {}) {
  const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
  const apiUrlKey =  isAdmin ? `${ADMIN_LABEL}/${SERVICE_PRIORITIES_LABEL}` : `${LAB_LABEL}/${SERVICE_TYPE_LABEL}`;
  const url = `/${API_VERSION_ONE}/${apiUrlKey}/${id}`;
  const method = 'GET';

  return {
    type: actions.TESTS_SHOW,
    payload: handleAPI(url, params, method, {}),
  };
}

export function testDelete(id) {
  const url = `/${API_VERSION_ONE}/admin/services/${id}`;
  const method = 'DELETE';

  return {
    type: actions.TEST_DELETE,
    payload: handleAPI(url, {}, method),
  };
}

export function printTestResults(id) {
  const user = getAuthUser();
  const userType =  user.role_label === USER_ROLE.PATIENT ? PATIENT_LABEL : LAB_LABEL;
  const url = `/${API_VERSION_ONE}/${userType}/orders/${id}.pdf`;
  const method = 'GET';

  return {
    type: actions.TEST_PRINT_RESULTS,
    payload: handleAPI(url, {}, method),
  };
}
