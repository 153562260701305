import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import i18n from 'translation/i18n';
import {ReactComponent as AddIcon} from 'assets/icons/common/add-square.svg';
import {createUser, getUsers} from 'redux/lab/actionCreators';

import {Button, Modal} from 'atoms';
import {ModalHeader} from 'templates';
import {UserForm} from './UserForm';

export const CreateUser = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState();

  const handleSubmit = (data) => {
    const payload = {
      user: {
        ...data,
        status: 'activated',
      }
    };
    dispatch(createUser(payload))
      .then(() => {
        dispatch(getUsers());
        setShowModal(false);
      });
  };

  return (
    <>
      <Button
        label={i18n.t('new_user')}
        startIcon={<AddIcon/>}
        onClick={() => setShowModal(true)}
      />
      <Modal
        header={<ModalHeader title={i18n.t('create_user')}/>}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        width={350}
      >
        <UserForm onSubmit={handleSubmit}/>
      </Modal>
    </>
  );
};
