import React from 'react';
import i18n from 'translation/i18n';
import {useDispatch, useSelector} from 'react-redux';
import {
  required,
} from 'utils/FormValidations';

import { Spacer } from 'atoms';
import {
  SelectField,
  TextInputField,
  DatePickerField,
} from 'components/global/final-form';
import {getListForSelect, isPatient} from 'utils/labUtils';
import {getLabPricesLoad, getPatientPricesLoad} from 'redux/patients/actionCreators';

const Step2 = () => {
  const dispatch = useDispatch();
  const {id_types, nationalities} = useSelector(state => state.patient);

  const loadPrices = (value) => {
    if(value) {
      if(isPatient()){
        return dispatch(getPatientPricesLoad({code: 'pcr', nationality_id: value}));
      }
      return dispatch(getLabPricesLoad({code: 'pcr', nationality_id: value}));
    }
    return null;
  };

  return (
    <>
      <SelectField
        name="patient.nationality_id"
        label={i18n.t('nationality')}
        placeholder={i18n.t('choose_nationality')}
        options={getListForSelect(nationalities)}
        validate={required}
        maxMenuHeight={320}
        onChange={loadPrices}
      />
      <Spacer height={20} />
      <DatePickerField
        name="patient.date_of_birth"
        label={i18n.t('date_of_birth')}
        displayFormat="dd MMM yyyy"
        placeholder={i18n.t('date')}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="patient.address"
        label={i18n.t('address')}
        placeholder={i18n.t('address')}
        validate={required}
      />
      <Spacer height={20} />
      <SelectField
        name="patient.id_type"
        label={i18n.t('type_of_personal_id')}
        placeholder={i18n.t('choose_type')}
        options={getListForSelect(id_types)}
        validate={required}
      />
      <Spacer height={20} />
      <TextInputField
        name="patient.personal_id"
        label={i18n.t('id_number')}
        placeholder={i18n.t('id_number')}
        validate={required}
      />
    </>
  );
};

export default Step2;
