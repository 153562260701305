import React from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-final-form';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {TextInputField} from 'components/global/final-form';
import {CardContainer, ModalContent, CustomImageUploader} from 'templates';
import {Spacer, Divider, Text, Flex} from 'atoms';
import {required} from 'utils/FormValidations';
import {Avatar} from '@mui/material';
import {ReactComponent as SamplePrinted} from 'assets/icons/common/sample-printed.svg';
import TempLogo from 'assets/icons/logo/tashkhes-logo-blue.svg';
import Signature from 'assets/icons/common/signature.svg';

const AdditionalInfo = (props) => {
  const {editting} = props;
  const {change} = useForm();
  const {profile} = useSelector(state => state.profile);
  const [stampImage, setStampImage] = React.useState(null);
  const [signatureImage, setSignatureImage] = React.useState(null);

  React.useEffect(()=> {
    if(!isEmpty(profile)){
      setStampImage(profile.stamp_url);
      setSignatureImage(profile.signature_url);
    }
  },[profile.signature_url, profile.stamp_url]);

  const onImageChange = (data, type) => {
    if(type==='stamp'){
      change('stamp.data', data.file);
      change('stamp.filename', data.file_file_name);
      change('stamp.content_type', data.type);
      return setStampImage(data.file);
    }
    change('signature.data', data.file);
    change('signature.filename', data.file_file_name);
    change('signature.content_type', data.type);
    return setSignatureImage(data.file);
  };
  
  return (
    <CardContainer className="reservation-info-container" title={i18n.t('additional_info')}>
      <ModalContent padding="32px">
        <TextInputField 
          label={i18n.t('lab_dirictor_name')} 
          name='director_name' 
          validate={required}
          placeholder={i18n.t('name')}
          disabled={!editting}
        />
        <Spacer height={24}/>

        <Flex itemsCenter>
          <CustomImageUploader
            imgSrc={stampImage || Signature}
            altLabel="Stamp"
            onDone={(data) => onImageChange(data, 'stamp')}
            actionLabel={i18n.t('change')}
            disabled={!editting}
            containerStyle={{width: '50%'}}
            uploaderType="image"
          />
          <Spacer width={16}/>
          <CustomImageUploader
            imgSrc={signatureImage || Signature}
            altLabel="Signature"
            onDone={(data) => onImageChange(data, 'signature')}
            actionLabel={i18n.t('upload_signature')}
            disabled={!editting}
            containerStyle={{width: '50%'}}
            uploaderType="image"
          />
        </Flex>
      </ModalContent>

      <Spacer height={16}/>
      <Divider />
      <Spacer height={24}/>

      <ModalContent padding="18px 24px" >
        <Text typography="caption12" textCase="uppercase" center>
          {i18n.t('sample_of_printed_paper')}
        </Text>
        <Spacer height={24}/>
        
        <CardContainer padding="18px 16px" style={{width: 'auto'}}>
          <Flex itemsCenter>
            <Avatar
              sx={{maxWidth: 32, maxHeight: 32}}
              style={{fontSize: 28}}
              src={profile.logo_url || TempLogo}
              alt='Logo'
            />
            <Spacer width={10}/>
            <Text typography="caption10">
              {profile.name_i18n}
            </Text>
          </Flex>
          <Spacer height={14}/>
          <SamplePrinted style={{width: '100%'}}/>
          <Spacer height={14}/>
          <Flex justifyEnd> 
            <img
              style={{fontSize: 28, transform: 'rotate(-6.85deg)', maxWidth: 32, maxHeight: 32}}
              src={signatureImage || Signature}
              alt='Signature'
            />
          </Flex>
        </CardContainer>
      </ModalContent>
    </CardContainer>
  );
};

AdditionalInfo.propTypes = {
  editting: PropTypes.bool,
};

export default AdditionalInfo;
