import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Settings from 'container/labs/Settings';

const SettingRoutes = () => (
  <Switch>
    <Route path="/settings"  component={Settings}/>
  </Switch>
);

export default SettingRoutes;
