import React from 'react';
import PropTypes from 'prop-types';
import styles from './templates.module.css';

const ModalContent = ({
  children,
  padding,
  style,
}) => {
  return (
    <div className={styles.modalTemplateContent} style={{padding, ...style}}>
      {children}
    </div>
  );
};

ModalContent.defaultProps ={
  padding: 16,
};

ModalContent.propTypes = {
  children: PropTypes.node,
  style: PropTypes.shape(),
  padding: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ModalContent;
