import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import ErrorBoundary from 'utils/ErrorBoundary';
import store from './redux/store';
import './index.css';
import App from './App';

ReactDOM.render(
  <ErrorBoundary>
    <Suspense fallback={<h1>Loading</h1>}>
      <Provider store={store}>
        <App/>
      </Provider>
    </Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
