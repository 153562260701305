import React from 'react';
import LabHeader from 'components/global/LabHeader';
import {ProfileForm} from 'components/Profile';

const Profile = () => {
  return (
    <>
      <LabHeader title="lab_profile"/>
      <ProfileForm />
    </>
  );
};

export default Profile;
