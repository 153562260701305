import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Select} from '@mui/material';

import {Option} from 'models';
import {valueGetter} from 'utils/uiHelpers';

import {Text} from 'atoms';
import {ReactComponent as Arrow} from 'assets/icons/common/arrow-filled-down.svg';

import './HeaderFilter.scss';

const HeaderFilter = ({selectedOption, options, onChange}) => {
  const [selected, setSelected] = useState(selectedOption || valueGetter(options, '0.value'));
  const menuProps = {
    classes: {
      paper: 'header-filter-popover',
      list: 'header-filter-options'
    }
  };

  function handleSelect(value) {
    setSelected(value);
    onChange(value);
  }

  return (
    <Select
      className="header-filter"
      value={selected}
      variant="standard"
      IconComponent={Arrow}
      MenuProps={menuProps}
      onChange={(e) => handleSelect(e.target.value)}
    >
      {options.map(option => (
        <MenuItem key={option.value} value={option.value}>
          <Text typography="paragraph14" weight="bold">{option.label}</Text>
        </MenuItem>
      ))}
    </Select>
  );
};

HeaderFilter.propTypes = {
  selectedOption: PropTypes.string,
  options: PropTypes.arrayOf(Option),
  onChange: PropTypes.func
};

export default HeaderFilter;
