import _ from 'lodash';
import qs from 'qs';

import { removeAuthToken } from 'redux/auth/actionCreators';
import i18n from 'translation/i18n';

// const locale = localStorage.getItem('Locale') || 'en';
export function buildQueryString(params = {}) {
  return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
}

export function buildUrlWithQueryString(url, params) {
  const paramsQueryString = buildQueryString(params);
  if (_.isEmpty(paramsQueryString)) {
    return url;
  }

  if (url.indexOf('?') >= 0) {
    return `${url}&${paramsQueryString}`;
  }

  return `${url}?${paramsQueryString}`;
}

export function handleResponseErr(error) {
  const { response } = error;
  let errMessage = '';
  const passwordAgeToken = localStorage.getItem('password-age-token');

  if (!navigator.onLine) {
    return i18n.t('internet_error_message');
  }

  if (response && response.data) {
    const { data } = response;
    // for handling password age reseting
    if (data.password_reset_token && !passwordAgeToken) {
      localStorage.setItem('password-age-token', data.password_reset_token);
      removeAuthToken();
      window.location.reload();
    }
    // for assetmanger API BE returning the error message in diffrent object
    if (data.errors) {
      return data.errors.message;
    }

    errMessage = data.message ? data.message : data.error;

    return errMessage;
  }

  return 'Something went wrong on server!';
}
