import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {useForm} from 'react-final-form';
import {
  composeValidators,
  shouldStartWith,
  maxLength,
  minLength,
  required,
  number,
  email,
} from 'utils/FormValidations';
import {getAuthUser} from 'redux/auth/actionCreators';

import {Spacer} from 'atoms';
import {
  SelectField,
  TextInputField,
} from 'components/global/final-form';
import {getListForSelect, isPatient} from 'utils/labUtils';

const Step1 = () => {
  const {genders} = useSelector(state => state.patient);
  const {change} = useForm();

  React.useEffect (() => {
    if(isPatient()){
      const userInfo = getAuthUser();
      change('patient.mobile', userInfo.mobile);
      change('patient.email', userInfo.email);
    }
  }, []);

  return (
    <>
      <TextInputField
        name="patient.name_en"
        label={i18n.t('patient_name')}
        placeholder={i18n.t('name')}
        validate={required}
      />
      <Spacer height={ 20}/>
      <TextInputField
        name="patient.name_ar"
        label={i18n.t('patient_name')}
        placeholder={i18n.t('name')}
        validate={required}
      />
      <Spacer height={ 20}/>
      <SelectField
        name="patient.gender"
        label={i18n.t('gender')}
        placeholder={i18n.t('choose_gender')}
        options={getListForSelect(genders)}
        validate={required}
      />
     
      {!isPatient() && (
        <>
          <Spacer height={20} />
          <TextInputField
            name="patient.mobile"
            type="number"
            label={i18n.t('phone_number')}
            placeholder={i18n.t('phone')}
            validate={
              composeValidators(required, shouldStartWith(5), number, maxLength(9), minLength(9))
            }
          />
          <Spacer height={20} />
          <TextInputField
            name="patient.email"
            label={i18n.t('email_address')}
            placeholder={i18n.t('email')}
            validate={composeValidators(required, email)}
          />
        </>
      )}
    </>
  );
};

export default Step1;
