import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {getBatchOrder} from 'redux/orders/actionCreators';

import LabHeader from 'components/global/LabHeader';
import {BatchOrder} from 'components/orders';

const BatchOrderDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBatchOrder(id));
  });

  return (
    <>
      <LabHeader title="orders"/>
      <BatchOrder/>
    </>
  );
};

export default BatchOrderDetails;
