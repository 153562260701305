import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import i18n from 'translation/i18n';

import {Button, Text} from 'atoms';
import BackButton from 'components/global/BackButton';
import OrderResultTransition from 'components/global/OrderResultTransition';
import {getBatchOrder, updateBatchOrderResult} from 'redux/orders/actionCreators';

const BatchOrderFilters = ({showRemoveBtn, onRemove}) => {
  const dispatch = useDispatch();
  const {batchDetails} = useSelector(state => state.orders);
  const [batchId, setBatchId] = useState();

  const handleMenuClick = (result) => {
    dispatch(updateBatchOrderResult(batchId, result))
      .then(() => {
        dispatch(getBatchOrder(batchId));
      });
  };

  useEffect(() => {
    setBatchId(batchDetails.id);
  }, [batchDetails]);

  return (
    <Grid container spacing={4} py={2} px={3} alignItems="center">
      <Grid item xs={3}>
        <BackButton/>
        <Text typography="subtitle" weight="bold">
          {i18n.t('batch', {batchNumber: batchDetails.serial_number})}
        </Text>
      </Grid>
      <Grid item xs={5}/>
      <Grid item xs={2}>
        {showRemoveBtn && (
          <Button variant="danger" label={i18n.t('remove_from_batch')} onClick={onRemove}/>
        )}
      </Grid>
      <Grid item xs={2}>
        <OrderResultTransition
          menuPosition="bottom"
          status={batchDetails.status}
          result={batchDetails.result}
          onMenuClick={handleMenuClick}
        >
          <Button variant="primary" label={i18n.t('update_results')}/>
        </OrderResultTransition>
      </Grid>
    </Grid>
  );
};

BatchOrderFilters.propTypes = {
  showRemoveBtn: PropTypes.bool,
  onRemove: PropTypes.func
};

export default BatchOrderFilters;
