import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';
import {sub, startOfMonth, startOfYear} from 'date-fns';

import LabHeader from 'components/global/LabHeader';
import {NewRequests, OverdueTests, OverviewStats} from 'components/overview';

import i18n from 'translation/i18n';
import {getOverviewStats} from 'redux/lab/actionCreators';

export const Overview = () => {
  const dispatch = useDispatch();

  const headerOptions = [
    {value: 'week', label: i18n.t('this_week')},
    {value: 'month', label: i18n.t('this_month')},
    {value: 'year', label: i18n.t('this_year')}];

  function updateDateRange(dateRange) {
    const toDate = new Date();
    let fromDate = sub(toDate, {weeks: 1});
    if (dateRange === 'month') {
      fromDate = startOfMonth(toDate);
    } else if (dateRange === 'year') {
      fromDate = startOfYear(toDate);
    }
    dispatch(getOverviewStats(fromDate, toDate));
  }

  useEffect(() => {
    updateDateRange('week');
  });

  return (
    <>
      <LabHeader
        title="overview"
        filterOptions={headerOptions}
        onFilterChange={(value) => updateDateRange(value)}
      />
      <OverviewStats/>
      <Grid container spacing={3} p={5}>
        <Grid item xs={12} sm={6}><NewRequests/></Grid>
        <Grid item xs={12} sm={6}><OverdueTests/></Grid>
      </Grid>
    </>
  );
};
