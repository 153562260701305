const Common = {
  // *

  // A
  apply: 'Apply',
  accept: 'Accept',
  activate: 'Activate',
  actions: 'Actions',

  // B
  back: 'Back',

  // C
  cancel_order: 'Cancel Order',
  card: 'Card',
  cash: 'نقداً',
  change: 'Change',
  clear: 'Clear',
  clinic: 'Clinic',
  copy_link: 'Copy Link',
  copy_success: 'Copied successfully!',

  // D
  delete: 'Delete',
  deactivate: 'Deactivate',

  // E
  email_msg: 'يجب أن يكون النص بتنسيق بريد إلكتروني',

  // F
  female: 'انثى',
  filter: 'Filter',

  // G
  gender: 'الجنس',

  // H

  // I
  internet_error_message: 'يبدو ان لديك مشكلة في الاتصال بالانترنت!',

  // J

  // K

  // L
  logout: 'تسجيل خروج',

  // M
  male: 'ذكر',
  mark_as: 'Mark As',
  min_length: 'أقل طول {{min}}',
  max_length: 'أقصى طول {{max}}',
  must_be_more_than: 'يجب أن يكون أكثر من {{min}}',
  must_be_less_than: 'يجب أن يكون أقل من {{max}}',
  mobile: 'Mobile',

  // N
  negative: 'Negative',
  no_further_changes: 'No Further Changes',
  number_msg: 'يجب أن يحتوي على أرقام فقط',
  number: 'Number',

  // O

  // P
  paid: 'Paid',
  payment_mode_text: 'Paid by {{mode}}',
  pending: 'Pending',
  pick_date: 'Pick a date',
  positive: 'Positive',
  priority: 'Priority',
  print_results: 'Print Results',

  // Q

  // R
  required: 'اجباري',
  reject: 'Reject',

  // S
  sar_month: 'ريال / شهر',
  saudi_riyal: 'ريال سعودي',
  save: 'Save',
  sort: 'Sort',
  status: 'الحالة',
  status_cancelled: 'Cancelled',
  status_collected: 'Collected',
  status_confirmed: 'Confirmed',
  status_created: 'Unpaid',
  status_done: 'Done',
  status_paid: 'Paid',
  status_processing: 'Processing',
  status_ready: 'Ready',
  status_rejected: 'Rejected',
  select_date: 'اختر التاريخ',
  should_start_with: 'Should start with {{start}}',
  submit: 'Submit',

  // T
  no_active_tests_tootip_title: 'لا توجد أي فحوصات نشطة !، يرجى تعيين فحص واحد على الأقل ليكون نشطًا للسماح لك بإضافة مريض.',

  // U

  // V
  view_all: 'عرض الجميع',
  view_details: 'View details',

  // W

  // X

  // Y

  // Z

};

export default Common;
