import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import i18n from 'translation/i18n';

import {Button, Card, Text} from 'atoms';

const ShareLink = ({titleKey, link}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setShowSuccess(true);
    setTimeout(() => {
      setShowSuccess(false);
    }, 1000);
  };

  return (
    <Card styles={{margin: '2rem', padding: '1rem 1.5rem'}}>
      <Text typography="subtitle" weight="bold">{i18n.t(titleKey)}</Text>
      <Grid container mt={2}>
        <Grid item xs={6} px={2} display="flex" alignItems="center" style={{backgroundColor: 'var(--brand-card-bg)'}}>
          <Text as="span" typography="caption" color="var(--brand-text-gray)">{link}</Text>
        </Grid>
        <Grid item xs={2} mx={2}>
          <Button variant="secondary" label={i18n.t('copy_link')} onClick={handleCopy}/>
        </Grid>
        <Grid item xs={2} display="flex" alignItems="center">
          {showSuccess && (
            <Text as="span" typography="caption" color="var(--brand-success)">
              {i18n.t('copy_success')}
            </Text>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

ShareLink.propTypes = {
  titleKey: PropTypes.string,
  link: PropTypes.string
};

export default ShareLink;
