import React from 'react';

import i18n from 'translation/i18n';
import {isClinicUser} from 'utils/labUtils';

import LabHeader from 'components/global/LabHeader';
import {ReservationList} from 'components/reservation';

export const Reservations = () => {
  const headerOptions = [
    {value: 'lab', label: i18n.t('lab_patients')},
    {value: 'clinic', label: i18n.t('clinic_patients')}
  ];

  const handleFilterChange = () => {};

  return (
    <>
      <LabHeader
        title="reservations"
        filterOptions={isClinicUser() ? [] : headerOptions}
        onFilterChange={handleFilterChange}
      />
      <ReservationList/>
    </>
  );
};
