import i18n from 'translation/i18n';
import {
  ORDER_STATUS,
  BATCH_ORDER_STATUS,
  ORDER_TYPE,
  PRIORITY,
  TEST_RESULT,
  USER_STATUS
} from 'models';

export const ORDER_TYPES = [
  {value: ORDER_TYPE.SINGLE, label: i18n.t(`order_type_${ORDER_TYPE.SINGLE}`)},
  {value: ORDER_TYPE.BATCH, label: i18n.t(`order_type_${ORDER_TYPE.BATCH}`)}
];

export const ORDER_STATUSES = [
  {value: ORDER_STATUS.CREATED, label: i18n.t(`status_${ORDER_STATUS.CREATED}`)},
  {value: ORDER_STATUS.PAID, label: i18n.t(`status_${ORDER_STATUS.PAID}`)},
  {value: ORDER_STATUS.CONFIRM, label: i18n.t(`status_${ORDER_STATUS.CONFIRM}`)},
  {value: ORDER_STATUS.COLLECT, label: i18n.t(`status_${ORDER_STATUS.COLLECT}`)},
  {value: ORDER_STATUS.PROCESS, label: i18n.t(`status_${ORDER_STATUS.PROCESS}`)},
  {value: ORDER_STATUS.REJECT, label: i18n.t(`status_${ORDER_STATUS.REJECT}`)},
  {value: ORDER_STATUS.READY, label: i18n.t(`status_${ORDER_STATUS.READY}`)},
  {value: ORDER_STATUS.CANCEL, label: i18n.t(`status_${ORDER_STATUS.CANCEL}`)}
];

export const BATCH_ORDER_STATUSES = [
  {value: BATCH_ORDER_STATUS.PROCESS, label: i18n.t(`status_${BATCH_ORDER_STATUS.PROCESS}`)},
  {value: BATCH_ORDER_STATUS.DONE, label: i18n.t(`status_${BATCH_ORDER_STATUS.DONE}`)}
];

export const USER_STATUSES = [
  {value: USER_STATUS.ACTIVATED, label: i18n.t(USER_STATUS.ACTIVATED)},
  {value: USER_STATUS.DEACTIVATED, label: i18n.t(USER_STATUS.DEACTIVATED)}
];

export const PRIORITIES = [
  {value: PRIORITY.NORMAL, label: i18n.t(PRIORITY.NORMAL)},
  {value: PRIORITY.SAT, label: i18n.t(PRIORITY.SAT)},
  {value: PRIORITY.URGENT, label: i18n.t(PRIORITY.URGENT)}
];

export const SYMPTOMS = [
  {value: 'signs', label: i18n.t('signs')},
  {value: 'no_signs', label: i18n.t('no_signs')}
];

export const TEST_RESULTS = [
  {value: TEST_RESULT.NEGATIVE, label: i18n.t(TEST_RESULT.NEGATIVE)},
  {value: TEST_RESULT.PENDING, label: i18n.t(TEST_RESULT.PENDING)},
  {value: TEST_RESULT.POSITIVE, label: i18n.t(TEST_RESULT.POSITIVE)}
];
