import React from 'react';
import {Redirect, Switch} from 'react-router-dom';

import routes from 'utils/routeHelpers/admin';
import PrivateRoute from './PrivateRoutes';

const AdminRoutes = () => (
  <div className="lab-page">
    <Switch>
      {routes.map(({
        path,
        component,
      }, index) => (
        <PrivateRoute
          key={index}
          path={path}
          component={component}
        />
      ))}
      <Redirect to='/labs'/>
    </Switch>
  </div>
);

export default AdminRoutes;
