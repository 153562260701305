import { Toastr } from 'utils/Toastr';
import { handleResponseErr } from 'utils/apiHelperUtils';
import { USER_ROLE } from 'models';
import { getAuthUser } from 'redux/auth/actionCreators';
import * as actions from './actions';

export const initialState = {
  fetching: false,
  submitting: false,
  services: [],
  service_show: {},
  pagination: {},
  message: '',
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.TESTS_LOAD_PENDING:
  case actions.TESTS_SHOW_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.TEST_DELETE_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.TESTS_UPDATE_PENDING:
  case actions.TEST_PRINT_RESULTS_PENDING:
    return {
      ...state,
      submitting: true,
    };

  case actions.TESTS_UPDATE_FULFILLED:
  case actions.TEST_DELETE_FULFILLED:
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      submitting: false,
    };

  case actions.TEST_PRINT_RESULTS_FULFILLED:
    return {
      ...state,
      submitting: false,
    };

  case actions.TESTS_LOAD_FULFILLED: {
    let allList = [];
    const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
    action.payload.data.services.map(service => {
      (isAdmin ? service.service_priorities : service.types).map(type => {
        const types = {types: undefined, service_priorities: undefined};
        allList = [...allList, {service: { ...service, ...types}, ...type }];
        return true;
      });
      return true;
    });

    return {
      ...state,
      ...action.payload.data,
      services: allList,
      fetching: false,
    };
  }

  case actions.TESTS_SHOW_FULFILLED: {
    const isAdmin = getAuthUser().role_label === USER_ROLE.SUPER_ADMIN;
    const loadData = action.payload.data;

    return {
      ...state,
      service_show: isAdmin 
        ? loadData.service_priority : loadData.service_provider_services_mapping,
      fetching: false,
    };
  }

  case actions.TESTS_UPDATE_REJECTED:
  case actions.TEST_PRINT_RESULTS_REJECTED:
  case actions.TEST_DELETE_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    
    return {
      ...state,
      submitting: false,
    };

  case actions.TESTS_LOAD_REJECTED:
  case actions.TESTS_SHOW_REJECTED:
    Toastr.error(handleResponseErr(action.payload));

    return {
      ...state,
      fetching: false,
    };

  default:
    return state;
  }
}
