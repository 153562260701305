import {handleAPI, API_VERSION_ONE} from 'utils/apiUtils';
import {ACCESS_TOKEN, LOCALE, SLUG, USER, LAB_ID, MODULE} from 'utils/constants';

import * as actions from './actions';

// export function getLocation(lat, lng) {
//   const url = `https://us1.locationiq.com/v1/reverse.php?key=YOUR_PRIVATE_TOKEN&lat=${lat}&lon=${lng}&format=json`;
//   const method = 'GET';

//   return {
//     type: actions.REGISTER_LAB,
//     payload: handleFullAPI(url, {}, method),
//   };
// }

// Local Storage Auth : ---------------
export function setAuthSlug(slug) {
  localStorage.setItem(SLUG, slug);
}

export function setAuthLabId(id) {
  localStorage.setItem(LAB_ID, id);
}

export function setAuthToken(accessToken) {
  return localStorage.setItem(ACCESS_TOKEN, accessToken);
}

export function setAuthUser(user) {
  localStorage.setItem(USER, JSON.stringify(user));
}

export function getAuthUser() {
  const user = localStorage.getItem(USER);
  return JSON.parse(user);
}

export function removeAuthToken() {
  localStorage.removeItem(ACCESS_TOKEN);
}

export function removeAuthUser() {
  localStorage.removeItem(USER);
  removeAuthToken();
  return {
    type: actions.REMOVE_AUTH_USER,
  };
}

export function removeAuthSlug() {
  localStorage.removeItem(SLUG);
}

export function setLocale(locale = 'en') {
  localStorage.setItem(LOCALE, locale);
}

export function setModule(module) {
  localStorage.setItem(MODULE, module);
}

export function getModule() {
  return localStorage.getItem(MODULE);
}

// registration: ----------------------------------------
export function registerLab(data) {
  const url = `/${API_VERSION_ONE}/auth/registrations/lab`;
  const method = 'POST';

  return {
    type: actions.REGISTER_LAB,
    payload: handleAPI(url, {}, method, data),
  };
}

export function patientSignup(data) {
  const url = `/${API_VERSION_ONE}/auth/registrations/patient`;
  const method = 'POST';

  return {
    type: actions.PATIENT_SIGNUP,
    payload: handleAPI(url, {}, method, data),
  };
}

export function clinicRegister(data) {
  const url = `/${API_VERSION_ONE}/auth/registrations/clinic`;
  const method = 'POST';

  return {
    type: actions.CLINIC_REGISTER,
    payload: handleAPI(url, {}, method, data),
  };
}

// login flow ----------------------------------------

export function adminLogin(data) {
  const url = `/${API_VERSION_ONE}/auth/login/admin`;
  const method = 'POST';

  return {
    type: actions.ADMIN_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function patientLogin(data) {
  const url = `/${API_VERSION_ONE}/auth/login/patient`;
  const method = 'POST';

  return {
    type: actions.PATIENT_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function labLogin(module, data) {
  const url = `/${API_VERSION_ONE}/auth/login/${module}`;
  const method = 'POST';

  return {
    type: actions.LAB_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function verifyLogin(otp, mobile) {
  const url = `/${API_VERSION_ONE}/auth/verify`;
  const method = 'POST';
  const data = {
    otp,
    mobile,
  };

  return {
    type: actions.VERIFY_LOGIN,
    payload: handleAPI(url, {}, method, data),
  };
}

export function resendCode(mobile) {
  const url = `/${API_VERSION_ONE}/auth/verify`;
  const method = 'PUT';
  const data = {mobile};

  return {
    type: actions.RESEND_CODE,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getResetEmail(email) {
  const url = `/${API_VERSION_ONE}/auth/passwords`;
  const method = 'POST';
  const data = {key: email};

  return {
    type: actions.GET_RESET_EMAIL,
    payload: handleAPI(url, {}, method, data),
  };
}

export function resetPassword(data) {
  const url = `/${API_VERSION_ONE}/auth/passwords`;
  const method = 'PUT';

  return {
    type: actions.RESET_PASSWORD,
    payload: handleAPI(url, {}, method, data),
  };
}
