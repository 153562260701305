import Overview from 'routers/authorized/OverviewRoutes';
import Reservation from 'routers/authorized/ReservationRoutes';
import Orders from 'routers/authorized/OrdersRoutes';
import Billing from 'routers/authorized/BillingRoutes';
import Invoices from 'routers/authorized/InvoicesRoutes';
import Users from 'routers/authorized/UsersRoutes';
import Clinics from 'routers/authorized/ClinicsRoutes';
import Tests from 'routers/authorized/TestRoutes';
import Settings from 'routers/authorized/SettingRoutes';
import Share from 'routers/authorized/ShareRoutes';
import ProfileRoutes from 'routers/authorized/ProfileRoutes';

const routes = [
  {
    path: '/overview',
    component: Overview,
  },
  {
    path: '/lab-profile',
    component: ProfileRoutes,
  },
  {
    path: '/reservations',
    component: Reservation,
  },
  {
    path: '/orders',
    component: Orders,
  },
  {
    path: '/billing',
    component: Billing,
  },
  {
    path: '/invoices',
    component: Invoices,
  },
  {
    path: '/users',
    component: Users,
  },
  {
    path: '/clinics',
    component: Clinics,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/settings',
    component: Settings,
  },
  {
    path: '/share',
    component: Share,
  },
];

export default routes;
