import axios from 'axios';
import Qs from 'qs';
import {cacheAdapterEnhancer, Cache} from 'axios-extensions';
import {API_ROOT} from 'env';
import {ACCESS_TOKEN, APPLICATION_JSON, LOCALE, SLUG} from 'utils/constants';
import errorResponseHandler from 'utils/errorHandler';
import {isClinicUser, isPatient} from 'utils/labUtils';

const API_VERSION_ONE = 'v1';

const maxAge = 20 * 1000;

const http = axios.create({
  adapter: cacheAdapterEnhancer(
    axios.defaults.adapter,
    {enabledByDefault: false, defaultCache: new Cache({maxAge})},
  ),
});

if (http.interceptors) {
  http.interceptors.response.use(null, errorResponseHandler);
}

export const serializerConfig = {
  arrayFormat: 'brackets',
  encode: false,
};

function removeEmptyParams(params) {
  Object.keys(params).forEach(key => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
}

function handleAPI(
  path, params, method, data, cacheConfig = {},
  options = {}, isThirdPartyUrl = false,
) {
  const token = localStorage.getItem(ACCESS_TOKEN);
  const locale = localStorage.getItem(LOCALE) || 'en';

  let headers = {
    'Content-Type': APPLICATION_JSON,
    Accept: APPLICATION_JSON,
    Authorization: `Bearer ${token}`,
    locale,
  };
  let url = API_ROOT + path;

  if (isThirdPartyUrl) {
    url = path;
    headers = {
      'Content-Type': APPLICATION_JSON,
    };
  }

  const {cache} = cacheConfig;

  return http({
    method,
    url,
    params: {
      ...params,
      ...((isPatient() || isClinicUser()) && {slug: localStorage.getItem(SLUG)}),
    },
    paramsSerializer: paramObject => Qs.stringify(paramObject, serializerConfig),
    data,
    headers,
    cache,
    ...options,
  });
}

export {handleAPI, removeEmptyParams, API_VERSION_ONE};
