import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Card} from 'atoms';
import {getAdminServiceProviders, updateAdminServiceProviders} from 'redux/labs/actionCreators';
import {LAB_ACTIONS, LAB_STATUS} from 'models';
import {LabFilters} from '.';
import LabTable from './LabTable';

const LabList = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {service_providers} = useSelector(state => state.labs);
  
  const loadServiceProviders = () => dispatch(getAdminServiceProviders());

  const handleRowClick = (values, inputTarget) => {
    const targ = inputTarget.target;
    if(targ.id === 'more-pic' || ['path', 'svg'].includes(targ.tagName)){
      return null;
    }
    return push(`/labs/${values.id}`);
  };
  
  const handleActivationRequest = (data, id) => {
    dispatch(updateAdminServiceProviders(data, id))
      .then(() => loadServiceProviders());
  };
  
  const handleAcceptanceRequest = (id) => { 
    dispatch(updateAdminServiceProviders({status: LAB_STATUS.ACTIVATED}, id))
      .then(() => loadServiceProviders());
  };
  
  const handleRejectionRequest = (id) => { 
    dispatch(updateAdminServiceProviders({status: LAB_STATUS.REJECTED}, id))
      .then(() => loadServiceProviders());
  };
  
  const handleDeletionRequest = () => { };

  const onActionClick = (data, type) => {
    switch (type) {
    case LAB_ACTIONS.ACTIVATION: {
      const status = data.activated ? LAB_STATUS.ACTIVATED : LAB_STATUS.DEACTIVATED;
      return handleActivationRequest({status}, data.id);
    }
    case LAB_ACTIONS.ACCEPT:
      return handleAcceptanceRequest(data.id);
    case LAB_ACTIONS.REJECT:
      return handleRejectionRequest(data.id);
    case LAB_ACTIONS.DELETE:
      return handleDeletionRequest(data.id);
    
    default:
      return null;
    }
  };
  
  React.useEffect(() => { 
    loadServiceProviders();
  }, []);

  return (
    <Card styles={{margin: '2rem'}}>
      <LabFilters/>
      <LabTable 
        list={service_providers} 
        onRowClick={handleRowClick}
        onActionClick={onActionClick}
        noData="No service providers added yet"
      />
    </Card>
  );
};

export default LabList;
