export const APPLICATION_JSON = 'application/json';
export const ACCESS_TOKEN = 'AccessToken';
export const LOCALE = 'Locale';
export const MODULE = 'Module';
export const SLUG = 'Slug';
export const USER = 'User';
export const LAB_ID = 'LabId';
export const PLAN_VALUE = 'PlanValue';
export const PATIENT_SIGNUP_BOOKING = 'IsPatientSignup';
export const PATIENT_LABEL = 'patient';
export const LAB_LABEL = 'lab';
export const CLINIC_LABEL = 'clinic';
export const ADMIN_LABEL = 'admin';
export const SERVICES_LABEL = 'services';
export const SERVICE_PROVIDER_LABEL = 'service_providers';
export const SERVICE_TYPE_LABEL = 'service_types';
export const SERVICE_PRIORITIES_LABEL = 'service_priorities';
