export const ORDER_TYPE = {
  SINGLE: 'single',
  BATCH: 'batch'
};

export const ORDER_STATUS = {
  CREATED: 'created',
  PAID: 'paid',
  CONFIRM: 'confirmed',
  COLLECT: 'collected',
  PROCESS: 'processing',
  REJECT: 'rejected',
  READY: 'ready',
  CANCEL: 'cancelled'
};

export const BATCH_ORDER_STATUS = {
  PROCESS: 'processing',
  DONE: 'done'
};

export const PRIORITY = {
  NORMAL: 'normal',
  SAT: 'sat',
  URGENT: 'urgent'
};

export const TEST_RESULT = {
  NEGATIVE: 'negative',
  PENDING: 'pending',
  POSITIVE: 'positive'
};

export const LAB_STATUS = {
  PENDING: 'pending',
  REJECTED: 'rejected',
  ACCEPTED: 'accepted',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated'
};

export const LAB_ACTIONS = {
  ACTIVATION: 'activation',
  ACCEPT: 'accept',
  REJECT: 'reject',
  DELETE: 'delete',
};

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated'
};

export const ACTIVE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated'
};

export const SUBSCRIPTION_STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const USER_ROLE = {
  ADMIN: 'admin',
  SUPER_ADMIN: 'super_admin',
  LAB_ADMIN: 'lab_admin',
  PATIENT: 'patient',
  CLINIC: 'clinic'
};

export const SOLLUTION = {
  PCR_SOLUTION: 'PCR_SOLUTION',
  FULL_SOLUTION: 'FULL_SOLUTION',
  ROUTINE_LAB_TESTING: 'ROUTINE_LAB_TESTING',
};
