import {get} from 'lodash';

import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  patientOrders: [],
  singleOrders: [],
  batchOrders: [],
  orderDetails: {},
  batchDetails: {
    id: '',
    orders: []
  }
};

export default function reducer(
  state = initialState,
  action
) {
  switch (action.type) {
  case actions.PATIENT_ORDER_LIST_PENDING:
  case actions.CREATE_BATCH_ORDER_PENDING:
  case actions.BATCH_ORDER_LIST_PENDING:
  case actions.GET_ORDER_PENDING:
  case actions.GET_BATCH_ORDER_PENDING:
  case actions.REMOVE_ORDER_FROM_BATCH_PENDING:
  case actions.UPDATE_ORDER_PENDING:
    return {
      ...state,
      fetching: true
    };

  case actions.SINGLE_ORDER_LIST_PENDING:
    return {
      ...state,
      singleOrders: [],
      fetching: true
    };

  case actions.PATIENT_ORDER_LIST_FULFILLED:
    return {
      ...state,
      patientOrders: get(action, 'payload.data.orders', []),
      fetching: false
    };

  case actions.SINGLE_ORDER_LIST_FULFILLED:
    return {
      ...state,
      singleOrders: get(action, 'payload.data.orders', []),
      fetching: false
    };

  case actions.BATCH_ORDER_LIST_FULFILLED:
    return {
      ...state,
      batchOrders: get(action, 'payload.data.batches', []),
      fetching: false
    };

  case actions.GET_ORDER_FULFILLED:
    return {
      ...state,
      orderDetails: get(action, 'payload.data.order', {}),
      fetching: false
    };

  case actions.GET_BATCH_ORDER_FULFILLED:
    return {
      ...state,
      batchDetails: get(action, 'payload.data.batch', {}),
      fetching: false
    };

  case actions.REMOVE_ORDER_FROM_BATCH_FULFILLED:
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      batchDetails: get(action, 'payload.data.batch', {}),
      fetching: false
    };

  case actions.CREATE_BATCH_ORDER_FULFILLED:
    return {
      ...state,
      fetching: false
    };

  case actions.UPDATE_ORDER_FULFILLED:
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      fetching: false
    };

  case actions.PATIENT_ORDER_LIST_REJECTED:
  case actions.SINGLE_ORDER_LIST_REJECTED:
  case actions.CREATE_BATCH_ORDER_REJECTED:
  case actions.BATCH_ORDER_LIST_REJECTED:
  case actions.GET_ORDER_REJECTED:
  case actions.GET_BATCH_ORDER_REJECTED:
  case actions.REMOVE_ORDER_FROM_BATCH_REJECTED:
  case actions.UPDATE_ORDER_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      fetching: false
    };

  default:
    return state;
  }
}
