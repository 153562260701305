import React from 'react';
import i18n from 'translation/i18n';
import { useForm, useFormState } from 'react-final-form';
import {
  required,
} from 'utils/FormValidations';

import {
  RadioBox,
  Spacer,
  Text,
} from 'atoms';
import { ReactComponent as HospitalLabSelected } from 'assets/icons/reservations/hospital-lab-selected.svg';
import { ReactComponent as HospitalLab } from 'assets/icons/reservations/hospital-lab.svg';
import { ReactComponent as PatientHouseSelected } from 'assets/icons/reservations/patient-house-selected.svg';
import { ReactComponent as PatientHouse } from 'assets/icons/reservations/patient-house.svg';
import { DatePickerField } from 'components/global/final-form';

const Step5 = () => {
  const { change } = useForm();
  const {values} = useFormState();

  const [collectionMethod, setCollectionMethod] = React.useState('lab_collection');

  React.useEffect(() => {
    change('appointment.collection_method', collectionMethod);
  }, [collectionMethod]);
  
  React.useEffect(() => {
    if(values.appointment?.collection_method && values.appointment.collection_method === 'home_collection'){
      setCollectionMethod('home_collection');
    }else{
      setCollectionMethod('lab_collection');
    }
  }, []);

  const handleChngeDate = (data) => {
    change('appointment.time', data);
  };

  return (
    <>
      <Spacer height={8} />
      <Text typography="paragraph14" color="var(--brand-text-gray-light)" weight="bold">
        {i18n.t('collection_method').toUpperCase()}
      </Text>
      <Spacer height={6} />

      <RadioBox
        name="appointment.collection_method"
        label={i18n.t('at_the_lab')}
        validate={required}
        variant="large"
        checked={collectionMethod === 'lab_collection'}
        startIcon={collectionMethod === 'lab_collection' ? <HospitalLabSelected /> : <HospitalLab />}
        onChange={() => setCollectionMethod('lab_collection')}
      />
      <Spacer height={20} />
      <RadioBox
        name="appointment.collection_method"
        label={i18n.t('home')}
        variant="large"
        validate={required}
        checked={collectionMethod === 'home_collection'}
        startIcon={collectionMethod === 'home_collection' ? <PatientHouseSelected /> : <PatientHouse />}
        onChange={() => setCollectionMethod('home_collection')}
      />
      <Spacer height={32} />

      <Text typography="paragraph14" color="var(--brand-text-gray-light)" weight="bold">
        {i18n.t('pick_a_date_and_time').toUpperCase()}
      </Text>
      <Spacer height={6} />
      <DatePickerField
        name="appointment.time"
        variant="large"
        onChange={handleChngeDate}
        validate={required}
        displayFormat="dd / MM / yyyy  hh:mm a"
        dateFormat="dd / MM / yyyy  hh:mm a"
        showTimeSelect
      />

      <Spacer height={140} />
    </>
  );
};

export default Step5;
