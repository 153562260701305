import React from 'react';
import {Box} from '@mui/material';
import {NavLink} from 'react-router-dom';
import i18n from 'translation/i18n';

import {Text, Flex} from 'atoms';
import UserInfo from 'components/global/UserInfo';
import {ReactComponent as Search} from 'assets/icons/common/search.svg';
import {ReactComponent as Notification} from 'assets/icons/common/bell.svg';

import './styles.scss';

const AdminHeader = () => {
  return (
    <Box className="admin-header" display="flex" justifyContent="space-between">
      <Flex itemsCenter>
        <NavLink activeClassName="is-active" to="/labs">
          <Text typography="headline" weight="bold">{i18n.t('labs')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/tests">
          <Text typography="headline" weight="bold">{i18n.t('tests')}</Text>
        </NavLink>
        <NavLink activeClassName="is-active" to="/subscriptions">
          <Text typography="headline" weight="bold">{i18n.t('subscriptions')}</Text>
        </NavLink>
      </Flex>
      <Box display="flex" marginTop={1}>
        <div className="page-actions">
          <Search/>
          <Notification/>
        </div>
        <UserInfo hideProfileOption/>
      </Box>
    </Box>
  );
};

export default AdminHeader;
