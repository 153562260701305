import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import {usePathname} from 'hooks';
import {getAuthUser} from 'redux/auth/actionCreators';
import {ACCESS_TOKEN, CLINIC_LABEL} from 'utils/constants';
import {retryImport} from 'utils/retry';
import {USER_ROLE} from 'models';

const LabLogin = retryImport(() => import('container/public/Lab/Login'));
const PatientLogin = retryImport(() => import('container/public/Patient/Login'));
const AdminLogin = retryImport(() => import('container/public/Admin/Login'));
const Signup = retryImport(() => import('container/public/Patient/Signup'));
const ClinicRegister = retryImport(() => import('container/public/Clinic/Register'));
const Subscription = retryImport(() => import('container/public/Lab/Subscription'));
const VerifyLogin = retryImport(() => import('container/public/VerifyLogin'));
const ForgotPassword = retryImport(() => import('container/public/ForgotPassword'));
const ResetPassword = retryImport(() => import('container/public/ResetPassword'));

const PublicRoutes = () => {
  const pathname = usePathname();
  const token = !!localStorage.getItem(ACCESS_TOKEN);
  const userRole = getAuthUser()?.role_label || USER_ROLE.LAB_ADMIN;
  const modulePath = pathname.includes(CLINIC_LABEL) ? '/clinic' : '';

  const redirectPage = {
    lab_admin: <Redirect to='/overview'/>,
    clinic: <Redirect to='/overview'/>,
    patient: <Redirect to='/tests'/>
  };

  return (
    <Switch>
      <Route path={`${modulePath}/login`} exact component={LabLogin}/>
      <Route path="/admin/login" component={AdminLogin}/>
      <Route path="/patient/login" component={PatientLogin}/>
      <Route path="/patient/signup" component={Signup}/>
      <Route path="/clinic/register" component={ClinicRegister}/>
      <Route path="/subscription" component={Subscription}/>
      <Route path="/verify-login" component={VerifyLogin}/>
      <Route path="/forgot-password" component={ForgotPassword}/>
      <Route path="/reset-password" component={ResetPassword}/>
      {token ? redirectPage[userRole] : <Redirect to="login"/>}
    </Switch>
  );
};

export default PublicRoutes;
