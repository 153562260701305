import {get} from 'lodash';

import {Toastr} from 'utils/Toastr';
import {handleResponseErr} from 'utils/apiHelperUtils';

import * as actions from './actions';

export const initialState = {
  fetching: false,
  reservations: [],
  reservationInfo: {},
  userList: [],
  userRoles: [],
  userStatuses: [],
};

export default function reducer(
  state = initialState,
  action,
) {
  switch (action.type) {
  case actions.OVERVIEW_STATS_PENDING:
  case actions.RESERVATION_LIST_PENDING:
  case actions.RESERVATION_INFO_PENDING:
  case actions.USER_LIST_PENDING:
  case actions.USER_OPTIONS_PENDING:
  case actions.CREATE_USER_PENDING:
  case actions.GET_USER_PENDING:
  case actions.UPDATE_USER_PENDING:
  case actions.DELETE_USER_PENDING:
    return {
      ...state,
      fetching: true,
    };

  case actions.OVERVIEW_STATS_FULFILLED:
    return {
      ...state,
      statistics: get(action, 'payload.data.statistics', {}),
      fetching: false
    };

  case actions.RESERVATION_LIST_FULFILLED:
    return {
      ...state,
      reservations: get(action, 'payload.data.patient_details', []),
      fetching: false
    };

  case actions.RESERVATION_INFO_FULFILLED:
    return {
      ...state,
      reservationInfo: get(action, 'payload.data.patient_detail', {}),
      fetching: false
    };

  case actions.USER_LIST_FULFILLED:
    return {
      ...state,
      userList: get(action, 'payload.data.users', []),
      fetching: false
    };

  case actions.USER_OPTIONS_FULFILLED:
    return {
      ...state,
      userRoles: get(action, 'payload.data.roles', []),
      userStatuses: get(action, 'payload.data.statuses', []),
      fetching: false
    };

  case actions.CREATE_USER_FULFILLED:
  case actions.UPDATE_USER_FULFILLED:
  case actions.DELETE_USER_FULFILLED:
    Toastr.success(action.payload.data.message);
    return {
      ...state,
      fetching: false,
    };

  case actions.GET_USER_FULFILLED:
    return {
      ...state,
      fetching: false
    };

  case actions.OVERVIEW_STATS_REJECTED:
  case actions.RESERVATION_LIST_REJECTED:
  case actions.RESERVATION_INFO_REJECTED:
  case actions.USER_LIST_REJECTED:
  case actions.USER_OPTIONS_REJECTED:
  case actions.CREATE_USER_REJECTED:
  case actions.GET_USER_REJECTED:
  case actions.UPDATE_USER_REJECTED:
  case actions.DELETE_USER_REJECTED:
    Toastr.error(handleResponseErr(action.payload));
    return {
      ...state,
      fetching: false,
    };

  default:
    return state;
  }
}
