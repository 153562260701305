const Reservation = {
  // *

  // A
  add_patient: 'Add patient',
  at_the_lab: 'At the lab',
  all_fields_are_required: 'All fields are required!',

  // B
  bill_and_collection: 'Bill and collection',
  border_no: 'Border No.',

  // C
  confirm_reservation: 'Confirm reservation',
  choose_gender: 'Choose gender',
  choose_type: 'Choose type',
  coming_from_outside_ksa_within_last_2_weeks: 'Coming from outside KSA within last 2 weeks',
  credit_card: 'Credit card',
  collection_method: 'Collection method',
  choose_nationality: 'Choose nationality',

  // D
  date_of_birth: 'Date of birth',
  date: 'Date',
  diagnosis: 'Diagnosis',
  discount: 'Discount',

  // E
  employee_government_sector: 'Employee government sector',
  employee_private_sector: 'Employee private sector',

  // F

  // G
  gulf_id: 'Gulf id',

  // H
  hajj: 'Hajj',
  healthcare_worker: 'Healthcare worker',
  home: 'Home',

  // I
  iqama_no: 'Iqama No.',
  id_number: 'Id number',

  // J

  // K

  // L

  // M

  // N
  national_id: 'National id',
  no_signs: 'No Signs',

  // O
  occupation: 'Occupation',

  // P
  patient_name: 'Patient name',
  payment_method: 'Payment method',
  pick_a_date_and_time: 'Pick a date & time',

  // Q

  // R
  reason_of_testing: 'Reason of testing',

  // S
  signs: 'Signs',
  signs_and_symptoms: 'Signs & Symptoms',
  subtotal: 'Subtotal',

  // T
  test_details: 'Test Details',
  type_of_personal_id: 'Type of personal id',
  travelling_to_outside_ksa: 'Travelling to outside KSA',
  tax: 'Tax',
  total: 'Total',

  // U
  umrah_from_outside: 'Umrah from outside',
  unemployed: 'Unemployed',

  // V

  // W
  worker_government_sector: 'Worker government sector',
  worker_private_sector: 'worker private sector',
  worker_individuals: 'worker individuals',

  // X

  // Y

  // Z

};

export default Reservation;
