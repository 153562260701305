import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Grid} from '@mui/material';

import {getReservationDetails} from 'redux/lab/actionCreators';

import LabHeader from 'components/global/LabHeader';
import {ReservationInfo, ReservationSummary} from 'components/reservation';

export const ReservationDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getReservationDetails(id));
  });

  return (
    <>
      <LabHeader title="reservations"/>
      <Grid container spacing={4} p={4}>
        <Grid item xs={8}><ReservationInfo/></Grid>
        <Grid item xs={4}><ReservationSummary/></Grid>
      </Grid>
    </>
  );
};
