import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@mui/material';

import i18n from 'translation/i18n';
import {ReactComponent as Search} from 'assets/icons/common/search.svg';
import {ReactComponent as Notification} from 'assets/icons/common/bell.svg';

import {Text} from 'atoms';
import {Option} from 'models';
import HeaderFilter from 'components/global/HeaderFilter';
import UserInfo from 'components/global/UserInfo';

import './styles.scss';

const LabHeader = ({title, selectedOption, filterOptions, onFilterChange}) => {
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    setShowFilter(!!(filterOptions && filterOptions.length));
  }, [filterOptions]);

  return (
    <Box className="lab-header" display="flex" justifyContent="space-between">
      <Box display="flex" alignItems="center">
        <Text className="page-title" as="span" weight="bold" textCase="capitalize">{i18n.t(title)}</Text>
        {showFilter && (
          <HeaderFilter
            selectedOption={selectedOption}
            options={filterOptions}
            onChange={onFilterChange}
          />
        )}
      </Box>
      <Box display="flex" marginTop={1}>
        <div className="page-actions">
          <Search/>
          <Notification/>
        </div>
        <UserInfo/>
      </Box>
    </Box>
  );
};

LabHeader.propTypes = {
  title: PropTypes.string,
  selectedOption: PropTypes.string,
  filterOptions: PropTypes.arrayOf(Option),
  onFilterChange: PropTypes.func
};

export default LabHeader;
