import React from 'react';
import PropTypes from 'prop-types';

import i18n from 'translation/i18n';

import {Card, Text} from 'atoms';
import './OrderSummaryTemplate/styles.scss';

const InfoCard = ({isCustom, titleKey, value, children}) => {
  return (
    <Card className="info-card">
      <Text className="info-card-title" typography="caption12" weight="600" color="var(--brand-text-gray)" textCase="capitalize">
        {i18n.t(titleKey)}
      </Text>
      {isCustom ? children : <Text typography="paragraph16" weight="bold">{value || '—'}</Text>}
    </Card>
  );
};

InfoCard.defaultProps = {
  isCustom: false
};

InfoCard.propTypes = {
  isCustom: PropTypes.bool,
  titleKey: PropTypes.string,
  value: PropTypes.string,
  children: PropTypes.node
};

export default InfoCard;
