import {handleAPI, API_VERSION_ONE, removeEmptyParams} from 'utils/apiUtils';
import {isClinicUser} from 'utils/labUtils';
import {CLINIC_LABEL, LAB_LABEL} from 'utils/constants';

import * as actions from './actions';

const baseUrl = () => {
  const module = isClinicUser() ? CLINIC_LABEL : LAB_LABEL;
  return `/${API_VERSION_ONE}/${module}`;
};

export function getPatientOrders(params = {}) {
  const url = `/${API_VERSION_ONE}/patient/orders`;
  const method = 'GET';

  return {
    type: actions.PATIENT_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getSingleOrders(params = {}) {
  const url = `${baseUrl()}/orders`;
  const method = 'GET';

  return {
    type: actions.SINGLE_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function createBatchOrder(orderIds) {
  const url = `/${API_VERSION_ONE}/lab/batches`;
  const method = 'POST';
  const payload = {
    batch: {
      order_ids: orderIds
    }
  };

  return {
    type: actions.CREATE_BATCH_ORDER,
    payload: handleAPI(url, {}, method, payload),
  };
}

export function getBatchOrders(params = {}) {
  const url = `/${API_VERSION_ONE}/lab/batches`;
  const method = 'GET';

  return {
    type: actions.BATCH_ORDER_LIST,
    payload: handleAPI(url, removeEmptyParams(params), method),
  };
}

export function getBatchOrder(batchId) {
  const url = `/${API_VERSION_ONE}/lab/batches/${batchId}`;
  const method = 'GET';

  return {
    type: actions.GET_BATCH_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function removeOrdersFromBatch(batchId, orderIds) {
  const url = `/${API_VERSION_ONE}/lab/batches/${batchId}`;
  const method = 'PUT';
  const data = {
    order_ids: {
      removed: orderIds
    }
  };

  return {
    type: actions.REMOVE_ORDER_FROM_BATCH,
    payload: handleAPI(url, {}, method, data),
  };
}

export function updateBatchOrderResult(batchId, result) {
  const url = `/${API_VERSION_ONE}/lab/batches/${batchId}`;
  const method = 'PUT';
  const data = {
    result
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function getPatientOrder(orderId) {
  const url = `/${API_VERSION_ONE}/patient/orders/${orderId}`;
  const method = 'GET';

  return {
    type: actions.GET_ORDER,
    payload: handleAPI(url, {}, method)
  };
}

export function getOrder(orderId) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}`;
  const method = 'GET';

  return {
    type: actions.GET_ORDER,
    payload: handleAPI(url, {}, method)
  };
}

export function updateOrderResult(orderId, result) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}/send_result`;
  const method = 'PUT';
  const data = {
    order: {
      result
    }
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function sendOrderResult(orderId) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}/send_pdf_result`;
  const method = 'GET';

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method),
  };
}

export function updateOrderStatus(orderId, status) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}`;
  const method = 'PUT';
  const data = {
    order: {
      status
    }
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function rejectOrder(orderId, reason) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}/reject`;
  const method = 'PUT';
  const data = {
    reason
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}

export function cancelOrder(orderId, reason) {
  const url = `/${API_VERSION_ONE}/lab/orders/${orderId}/cancel`;
  const method = 'PUT';
  const data = {
    reason
  };

  return {
    type: actions.UPDATE_ORDER,
    payload: handleAPI(url, {}, method, data),
  };
}
