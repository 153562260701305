import React from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import PropTypes from 'prop-types';
import {get} from 'lodash';
import {Grid} from '@mui/material';

import {ORDER_STATUS} from 'models';
import i18n from 'translation/i18n';
import {valueGetter} from 'utils/uiHelpers';
import {DISPLAY_DATE_TIME_FORMAT, format} from 'utils/date';
import {canCancelOrder, getStatusColor, reservationNextStatus} from 'utils/labUtils';
import {getReservationDetails} from 'redux/lab/actionCreators';
import {cancelOrder, updateOrderStatus} from 'redux/orders/actionCreators';

import {Flex, Tag, Text} from 'atoms';
import {InfoCard, InfoCards} from 'templates';
import OrderStatusTransition from 'components/global/OrderStatusTransition';

export const ReservationTestInfo = ({reservationInfo}) => {
  const dispatch = useDispatch();
  const orderId = get(reservationInfo, 'order.id');
  const status = get(reservationInfo, 'order.status', '');

  const items = [{
    key: 'reservation_id',
    value: valueGetter(reservationInfo, 'order.reservation_id')
  }, {
    key: 'reservation_date',
    value: format(valueGetter(reservationInfo, 'order.appointment.time'), DISPLAY_DATE_TIME_FORMAT)
  }];

  const fetchReservation = () => {
    const {id} = useParams();
    dispatch(getReservationDetails(id));
  };

  const handleMenuClick = ({option, reason}) => {
    if (option === ORDER_STATUS.CANCEL) {
      dispatch(cancelOrder(orderId, reason))
        .then(() => fetchReservation());
    } else {
      dispatch(updateOrderStatus(orderId, option))
        .then(() => fetchReservation());
    }
  };

  return (
    <>
      <Text className="section-title" typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t('test_information')}
      </Text>
      <Grid container px={3} py={2} spacing={3}>
        <InfoCards items={items}/>
        <Grid item xs={4}>
          <InfoCard titleKey="status" isCustom>
            <Flex itemsCenter>
              <Tag text={i18n.t(`status_${status}`)} color={getStatusColor(status)}/>
              <OrderStatusTransition
                showCancel={canCancelOrder(status)}
                options={reservationNextStatus[status]}
                menuPosition="right"
                onMenuClick={handleMenuClick}
              >
                <Text
                  style={{marginLeft: '1.5rem'}}
                  typography="caption13"
                  weight="bold"
                  color="var(--brand-text-primary)"
                >
                  {i18n.t('change')}
                </Text>
              </OrderStatusTransition>
            </Flex>
          </InfoCard>
        </Grid>
      </Grid>
    </>
  );
};

ReservationTestInfo.propTypes = {
  reservationInfo: PropTypes.shape()
};
