import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';

import i18n from 'translation/i18n';
import {DISPLAY_DATE_FORMAT, format} from 'utils/date';
import {valueGetter} from 'utils/uiHelpers';

import {Text} from 'atoms';
import {InfoCards} from 'templates';

export const ReservationPatientInfo = ({reservationInfo}) => {
  const items = [
    {key: 'patient_name', value: valueGetter(reservationInfo, 'name')},
    {key: 'phone', value: valueGetter(reservationInfo, 'user.mobile')},
    {key: 'date_of_birth', value: format(valueGetter(reservationInfo, 'date_of_birth'), DISPLAY_DATE_FORMAT)},
    {key: 'nationality', value: valueGetter(reservationInfo, 'nationality.name_i18n')},
    {key: 'address', value: valueGetter(reservationInfo, 'order.patient_detail.address')}
  ];

  return (
    <>
      <Text className="section-title" typography="caption12" weight="bold" textCase="uppercase">
        {i18n.t('patient_information')}
      </Text>
      <Grid container px={3} py={2} spacing={3}>
        <InfoCards items={items}/>
      </Grid>
    </>
  );
};

ReservationPatientInfo.propTypes = {
  reservationInfo: PropTypes.shape()
};
