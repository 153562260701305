import React from 'react';
import RootRouter from 'routers/RootRouter';

import './App.css';
import 'styles/styleTokens.css';
import {BrowserRouter} from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <RootRouter/>
    </BrowserRouter>
  );
};

export default App;
