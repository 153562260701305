import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Form} from 'react-final-form';
import {Grid} from '@mui/material';
import {profilesShow, profilesUpdate } from 'redux/profile/actionCreators';
import {Spacer} from 'atoms';
import {ProfileInfo, AdditionalInfo} from '.';

const ProfileForm = () => {
  const dispatch = useDispatch();
  const {profile} = useSelector(state => state.profile);
  
  const [editting, setEditting] = React.useState(false);

  const reloadData = () => {
    dispatch(profilesShow());
    setEditting(false);
  };

  const onSubmit = (values) => {
    delete values.admin_service_provider;
    delete values.name_i18n;
    delete values.logo_url;
    delete values.signature_url;
    delete values.stamp_url;
    dispatch(profilesUpdate(values))
      .then(() => reloadData());
  };

  React.useEffect(()=>{
    dispatch(profilesShow());
  }, []);

  return (
    <Form onSubmit={onSubmit} initialValues={profile}>
      {({handleSubmit}) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={4} sx={{margin: 0}}>
            <Grid item xs={8}>
              <ProfileInfo
                editting={editting} 
                onEditClick={() => setEditting(true)}
                onEditDone={reloadData}
              />
            </Grid>
            <Grid item xs={3.5}>
              <AdditionalInfo editting={editting}/>
            </Grid>
          </Grid>
          <Spacer height={40}/>
        </form>
      )}
    </Form>
  );
};

export default ProfileForm;
