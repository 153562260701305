import React from 'react';
import i18n from 'translation/i18n';
import {useSelector} from 'react-redux';
import {Avatar} from '@mui/material';
import {isEmpty} from 'lodash';
import {CardContainer, ModalContent, CustomImageUploader} from 'templates';
import {Spacer, Divider, Text, Flex} from 'atoms';
import {labStatusTextColor, labStatusTagColor} from 'utils/labUtils';
import {ReactComponent as SamplePrinted} from 'assets/icons/common/sample-printed.svg';
import TempLogo from 'assets/icons/logo/tashkhes-logo-blue.svg';
import Signature from 'assets/icons/common/signature.svg';

const LabAdditionInfo = () => {
  const {service_provider} = useSelector(state => state.labs);
  const [stampImage, setStampImage] = React.useState(null);
  const [signatureImage, setSignatureImage] = React.useState(null);

  React.useEffect(()=> {
    if(!isEmpty(service_provider)){
      setStampImage(service_provider.stamp_url);
      setSignatureImage(service_provider.signature_url);
    }
  },[service_provider.signature_url, service_provider.stamp_url]);
  
  return (
    <CardContainer 
      className="reservation-info-container" 
      customHeader={(
        <Flex justifyBetween>
          <Text
            typography="subtitle"
            weight="bold"
            color="var(--brand-text-black)"
            textCase="capitalize"
          >
            {i18n.t('additional_info')}
          </Text>
          <Text 
            typography="caption11" 
            textCase="uppercase" 
            color={labStatusTextColor[service_provider.status]} 
            style={{padding: '8px 12px', height: 'fit-content', borderRadius: '8px', backgroundColor: labStatusTagColor[service_provider.status]}}
            center
          >
            {i18n.t(service_provider.status)}
          </Text>
        </Flex>
      )}
    >

      <Divider />
      <Spacer height={24}/>

      <ModalContent padding="32px">
        {/* <TextInput
          label={i18n.t('lab_dirictor_name')} 
          name='director_name' 
          placeholder={i18n.t('name')}
          disabled
        />
        <Spacer height={24}/> */}

        <Flex itemsCenter>
          <CustomImageUploader
            imgSrc={stampImage || Signature}
            altLabel="Stamp"
            disabled
            containerStyle={{width: '50%'}}
            uploaderType="image"
          />
          <Spacer width={16}/>
          <CustomImageUploader
            imgSrc={signatureImage || Signature}
            altLabel="Signature"
            disabled
            containerStyle={{width: '50%'}}
            uploaderType="image"
          />
        </Flex>
      </ModalContent>

      <Spacer height={16}/>
      <Divider />
      <Spacer height={24}/>

      <ModalContent padding="18px 24px" >
        <Text typography="caption12" textCase="uppercase" center>
          {i18n.t('sample_of_printed_paper')}
        </Text>
        <Spacer height={24}/>
        
        <CardContainer padding="18px 16px" style={{width: 'auto'}}>
          <Flex itemsCenter>
            <Avatar
              sx={{maxWidth: 32, maxHeight: 32}}
              style={{fontSize: 28}}
              src={service_provider.logo_url || TempLogo}
              alt='Logo'
            />
            <Spacer width={10}/>
            <Text typography="caption10">
              {service_provider.name_i18n}
            </Text>
          </Flex>
          <Spacer height={14}/>
          <SamplePrinted style={{width: '100%'}}/>
          <Spacer height={14}/>
          <Flex justifyEnd> 
            <img
              style={{fontSize: 28, transform: 'rotate(-6.85deg)', maxWidth: 32, maxHeight: 32}}
              src={signatureImage || Signature}
              alt='Signature'
            />
          </Flex>
        </CardContainer>
      </ModalContent>
    </CardContainer>
  );
};

export default LabAdditionInfo;
