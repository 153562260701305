import {Tests, TestDetails} from 'container/admin/Tests';
import {Labs, LabDetails} from 'container/admin/Labs';
import {Subscriptions, SubscriptionDetails} from 'container/admin/Subscriptions';

const routes = [
  {
    path: '/tests/:id',
    component: TestDetails,
  },
  {
    path: '/tests',
    component: Tests,
  },
  {
    path: '/labs/:id',
    component: LabDetails,
  },
  {
    path: '/labs',
    component: Labs,
  },
  {
    path: '/subscriptions/:id',
    component: SubscriptionDetails,
  },
  {
    path: '/subscriptions',
    component: Subscriptions,
  },
];

export default routes;
