import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'translation/i18n';
import {Modal, Button } from 'atoms';
import { ModalHeader } from 'templates';
import {newLabStepTitles} from 'utils/labUtils';
import {SOLLUTION} from 'models';
import CreateLabForm from './CreateLabForm';

const CreateLabModal = (props) => {
  const {onSubmit, initialValues} = props;
  const [showModal, setShowModal] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNewLabClick = () => {
    setShowModal(!showModal);
  };

  const handleClose = () => {
    setActiveStep(0);
    setShowModal(false);
  };

  const onBackClicked = () => {
    setActiveStep(activeStep - 1);
  };

  const onPlanChoosed = (plan) => {
    if(plan.value !== SOLLUTION.FULL_SOLUTION){
      setActiveStep(activeStep + 1);
    }
  };

  const handleOnSubmit = (values, step) => {
    setActiveStep(activeStep + 1);
    if(step === 4){
      onSubmit(values);
      setShowModal(false);
    }
  };

  return (
    <>
      <Button label={i18n.t('new_lab')} onClick={handleNewLabClick}/>
      <Modal
        minWidth={420}
        isOpen={showModal}
        header={<ModalHeader 
          title={i18n.t(newLabStepTitles[activeStep])} 
          onBack={activeStep > 0 ? () => onBackClicked() : null }
        />}
        onClose={handleClose}
      >
        <CreateLabForm 
          onSubmit={handleOnSubmit} 
          initialValues={initialValues}
          activeStep={activeStep}
          onPlanChoosed={onPlanChoosed}
        />
      </Modal>
    </>
  );
};

CreateLabModal.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.shape(),
};

export default CreateLabModal;
