import I18n from 'translation/i18n';

export const required = value => (value ? undefined : I18n.t('required'));
export const requiredWithNoMessage = value => (value ? undefined : ' ');

export const maxLength = max => value => (
  value && value.length > max ? I18n.t('max_length', { max }) : undefined
);

export const shouldStartWith = start => value => (
  value && value.charAt(0) !== String(start) ? I18n.t('should_start_with', { start }) : undefined
);

export const maxValue = max => value => (
  value && value > max ? I18n.t('must_be_less_than', { max }) : undefined
);

export const minLength = min => value => (
  value && value.length < min ? I18n.t('min_length', { min }) : undefined
);

export const minValue = min => value => (
  value && value < min ? I18n.t('must_be_more_than', { min }) : undefined
);

export const number = value => (
  value && (Number.isNaN(Number(value)) || /\D/.test(value))
    ? I18n.t('number_msg')
    : undefined
);

export const email = value => (
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z0-9-]{2,63}$/i.test(value)
    ? I18n.t('email_msg')
    : undefined
);

export const composeValidators = (...validators) => value =>
  validators.reduce(
    (error, validator) => error || validator(value),
    undefined,
  );
  
