import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';

import {getOrder} from 'redux/orders/actionCreators';

import LabHeader from 'components/global/LabHeader';
import {OrderInfo, OrderSummary} from 'components/orders';

const SingleOrderDetails = () => {
  const {id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrder(id));
  });

  return (
    <>
      <LabHeader title="orders"/>
      <Grid container spacing={4} p={4}>
        <Grid item xs={8}><OrderInfo/></Grid>
        <Grid item xs={4}><OrderSummary/></Grid>
      </Grid>
    </>
  );
};

export default SingleOrderDetails;
